import React, { Component } from 'react';
import { message, Table, Input, Radio, Switch, Popconfirm, Modal, Button, Drawer, } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { putInvite } from '../../services/inviteSign';
class InviteDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      type: '',
      data: {
        area: [],
      },
    };
  }
  render() {
    const { visible, data, type } = this.state;
    return (
      <Drawer
        visible={visible}
        onClose={this.onCancel}
        width={700}
        footer={
          <div className='row-l'>
            <Button type='primary' onClick={this.onCancel}>关闭</Button>
          </div>
        }
      >
        <div>
          <div className='row-l margin-b10'>
            <div className='w80'>师傅：</div>
            <div>{data.name}</div>
          </div>
          <div className='row-l margin-b10'>
            <div className='w80'>电话：</div>
            <div>{data.uid}</div>
          </div>
          <div className='row-l vertical-lt margin-b10'>
            <div className='w80'>服务区域：</div>
            <div className='col-t'>
              {
                data.area.map((item, index) => {
                  return (
                    <div key={index}>{item.province},{item.city},{item.district}</div>
                  );
                })
              }
            </div>
          </div>
          <div className='row-l margin-b10'>
            <div className='w80'>师傅备注：</div>
            <div className='w500'>{data.worker_remark}</div>
          </div>
          <div className='row-l margin-b10'>
            <div className='w80'>签约金：</div>
            <div>{data.price}</div>
          </div>
          <div className='row-l vertical-lt margin-b10'>
            <div className='w80'>平台备注：</div>
            <div className='w500'>{data.plat_remark}</div>
          </div>
          <div className='row-l margin-b10'>
            <div className='w80'>邀约时间：</div>
            <div>{moment(data.add_time).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
          <div className='row-l margin-b10'>
            <div className='w80'>师傅确认时间：</div>
            {
              data.confirm_time !== '0001-01-01T00:00:00' &&
              <div className='w500'>{moment(data.confirm_time).format('YYYY-MM-DD HH:mm:ss')}</div>
            }
          </div>
          {
            data.check_time !== '0001-01-01T00:00:00' &&
            <div>
              <div className='row-l'>
                <div className='w80'>审核人员：</div>
                <div>{data.checkName}</div>
              </div>
              <div className='row-l'>
                <div className='w80'>审核时间：</div>
                <div>{moment(data.check_time).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>

            </div>
          }
          {
            type === 'check' &&
            <div className='row-l margin-t20'>
              <Button onClick={this.toCheck.bind(this, '已拒绝')} type='danger' className='margin-r20'>拒绝</Button>
              <Button onClick={this.toCheck.bind(this, '已通过')} type='primary'>通过</Button>
            </div>
          }
        </div>
      </Drawer>
    );
  }
  onShow = (e, type) => {
    let data = _.cloneDeep(e);
    data.area = JSON.parse(e.area);
    if (this.props.serviceList) {
      let exitIndex = this.props.serviceList.findIndex(r => r.id === data.checker);
      if (exitIndex > -1) {
        data.checkName = this.props.serviceList[exitIndex].name;
      }
    }
    this.setState({
      visible: true,
      data, type,
    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
      data: {
        area: [],
      },
    });
  }
  toCheck = async (type) => {
    const { data } = this.state;
    let newDat = {
      id: data.id,
      state: type,
      price: data.price,
      area: JSON.stringify(data.area),
      worker_remark: data.worker_remark,
      plat_remark: data.plat_remark,
    };
    let res = await putInvite(newDat);
    if (res.status === 204) {
      this.onCancel();
      this.props.update()
    }
  }
}
export default InviteDrawer;

import React, { Component } from 'react';
import { message, Table, Input, Button, DatePicker, Popconfirm, Checkbox, Radio } from 'antd';
import { putFeedbackList, getFeeds, delFeedback } from '../../../services/feed';
import PageTurning from '../../../components/pageTurning';
import IndexModal from '../tableList/indexModal';
import OperateModal from './operateModal';
import FeedbackModal from './feedbackModal';
import FeedModal from '../tableList/feedModal';
import { getDepartment_Data } from '../../../config/storage';
import styles from '../tableList/index.module.css';
import Link from '../../../resource/png/masterManagement-link.png';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';

const dateFormat = 'YYYY-MM-DD';
const today = moment().add(1, 'day').format(dateFormat);
const monthAgo = moment().subtract(1, 'month').format(dateFormat);

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        info: '',
        start: monthAgo,
        end: today,
        reason: 0,
        myArea: true,
        skip: 0,
        take: 4,
      },
      feeds: [],
      dataSource: [],
    };
    this.selfId = getDepartment_Data().id;
    this.role = getDepartment_Data().roles[0];
    this.indexModal = React.createRef();
    this.operateModal = React.createRef();
    this.feedbackModal = React.createRef();
    this.feedModal = React.createRef();
    this.colums = [
      {
        title: '订单号',
        align: 'center',
        width: 90,
        dataIndex: 'order',
        render: (text, record, index) => {
          return (
            <div>
              <div>编号 - {record.id}</div>
              <div>{record.service} - {record.category}</div>
              <div>{record.order}</div>
            </div>
          );
        }
      },
      {
        title: '客户信息',
        align: 'center',
        width: 200,
        dataIndex: 'worker_name',
        render: (text, record, index) => {
          return (
            <div className='col-t vertical-lt w100'>
              <div>商户：{record.shop_name}</div>
              <div>客户：{record.name}</div>
              <div>
                电话：{record.phone}
                <img src={Link} onClick={this.toCopy.bind(this, record)} className='cursor-pointer' />
              </div>
              <div className='tl w100'>地址：{record.province}{record.city}{record.district}{record.address}</div>
            </div>
          );
        }
      },
      {
        title: '申请信息',
        align: 'center',
        width: 120,
        dataIndex: 'worker_name',
        render: (text, record, index) => {
          return (
            <div className='col-t'>
              <div>{record.sub_name}</div>
              <div>{moment(record.submit_time).format('YYYY-MM-DD HH:mm:ss')}</div>
            </div>
          );
        }
      },
      {
        title: '问题类型',
        align: 'center',
        width: 110,
        dataIndex: 'feed_str',
      },
      {
        title: '子问题',
        align: 'center',
        width: 140,
        dataIndex: 'detail_str',
      },
      {
        title: '问题描述',
        align: 'center',
        width: 220,
        dataIndex: 'remark',
        render: (text, record, index) => {
          return (
            <div>
              {/* <div className='mh160 over mw224'> */}
              {record.remark}
            </div>
          );
        }
      },
      {
        title: '处理人员',
        align: 'center',
        width: 180,
        dataIndex: 'worker_name',
        render: (text, record, index) => {
          return (
            <div className='col-t vertical-lt w100'>
              {
                record.checkers.map((citem, cindex) => {
                  return (
                    <div key={cindex} className={`${styles['feedLine']} w100`}>
                      <div>{citem.name} - {citem.state}</div>
                      <div>
                        {citem.check_time === '0001-01-01T00:00:00' ? '' : moment(citem.check_time).format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                    </div>
                  );
                })
              }
            </div>
          );
        }
      },
      {
        title: '处理结果',
        align: 'center',
        width: 180,
        dataIndex: 'content',
        render: (text, record, index) => {
          return (
            <div>
              <div>{record.state}</div>
              <div>{record.content}</div>
            </div>
          );
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 180,
        dataIndex: 'worker_name',
        render: (text, record, index) => {
          return (
            <div className='row-w '>
              <div className={styles['style-button2']} onClick={this.onOpenModal.bind(this, record, '修改订单')}>
                修改原订单
              </div>
              <div className={styles['style-button4']} onClick={this.onOperationClick.bind(this, record)}>
                终止原订单
              </div>
              {
                ([9, 11].includes(this.role) || (this.selfId === record.submitter)) && record.state === '待处理' &&
                <div className={styles['style-button2']} onClick={this.toEdit.bind(this, record)}>
                  编辑反馈
                </div>
              }
              <div className={styles['style-button1']} onClick={this.toDetail.bind(this, record)}>
                反馈详情
              </div>
              {
                ([9, 11].includes(this.role) || (this.selfId === record.submitter)) && record.state === '待处理' &&
                <Popconfirm
                  title="您确定要终止反馈吗?"
                  onConfirm={this.todelFeedback.bind(this, record)}
                  okText="确认"
                  cancelText="取消"
                >
                  <div className={styles['style-button4']}>
                    终止反馈
                  </div>
                </Popconfirm>
              }

            </div>
          );
        }
      },

    ];
  }
  componentDidMount() {
    this.getFeeds();
    this.getList();
  }
  render() {
    const { info, feeds, dataSource, } = this.state;
    return (
      <div>
        <div className='bar w100'>
          <div className='row-l margin-b10 w100'>
            <div className='w90'>问题类型：</div>
            <div className='w100 row-l'>
              <Radio.Group value={info.reason} onChange={this.changeInfo.bind(this, 'reason')}>
                {
                  feeds.map((item, index) => {
                    return (
                      <Radio value={item.id} key={index}>{item.name}</Radio>
                    );
                  })
                }
              </Radio.Group>
            </div>
          </div>
          <div className='row-l'>
            <div>时间范围：</div>
            <DatePicker placeholder='开始时间' className='w198' value={moment(info.start, dateFormat)}
              onChange={this.changeInfo.bind(this, 'start')} locale={locale} clearIcon={false} />
            <div className='margin-l5 margin-r5'>至</div>
            <DatePicker placeholder='结束时间' className='w198' value={moment(info.end, dateFormat)}
              onChange={this.changeInfo.bind(this, 'end')} locale={locale} clearIcon={false} />
            <div className='margin-l20'>搜索：</div>
            <Input value={info.info} onChange={this.changeInfo.bind(this, 'info')} className='w200' />
            <Button onClick={this.getList} type='primary'>搜索</Button>
            <Checkbox checked={info.myArea} onChange={this.changeInfo.bind(this, 'myArea')} className='margin-l20'>
              与我相关
            </Checkbox>
          </div>
        </div>
        <div className='bar'>
          <Table
            columns={this.colums}
            dataSource={dataSource}
            size={'small'}
            className='w100'
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} pageSize={4} />
        </div>
        <IndexModal
          ref={this.indexModal}
        />
        <OperateModal
          ref={this.operateModal}
        />
        <FeedbackModal
          ref={this.feedbackModal}
          update={this.getList}
        />
        <FeedModal
          ref={this.feedModal}
          update={this.getList}
        />
      </div>
    );
  }
  getFeeds = async () => {
    let res = await getFeeds();
    if (res.status === 200) {
      let feeds = [].concat([{ id: 0, name: '全部' }], res.data);
      this.setState({
        feeds,
      });
    }
  }
  changeInfo = (type, e) => {
    let info = { ...this.state.info };
    switch (type) {
      case 'reason':
        info[type] = e.target.value;
        break;
      case 'myArea':
        info[type] = e.target.checked;
        break;
      case 'info':
        info[type] = e.target.value;
        break;
      case 'start':
      case 'end':
        info[type] = moment(e).format(dateFormat);
        break;
    }
    this.setState({
      info,
    });
  }
  getList = async () => {
    const { info } = this.state;
    let res = await putFeedbackList(info);
    if (res.status === 200) {
      this.setState({
        dataSource: res.data
      });
    }
  }
  pageMaps = ({ skip, take }) => {
    let info = { ...this.state.info };
    info.skip = skip;
    this.setState({
      info,
    }, async () => this.getList())
  }
  onOpenModal = (data, type) => {
    let orderInfo = {
      ...data,
      id: data.order,
    };
    this.indexModal.current.onShowModal(orderInfo, type);
  }
  onOperationClick = async (e) => {
    this.operateModal.current.onShow(e.order, '终止');
  }
  toDetail = (e) => {
    this.feedbackModal.current.onShow(e);
  }
  toEdit = (e) => {
    this.feedModal.current.onShow(e, 'edit');
  }
  toCopy = (e) => {
    var aux = document.createElement("textarea");
    aux.value = `${'商家:' + e.shop_name + '\n' + '客户:' + e.name + '\n' + '手机号:' + e.phone
      + '\n' + '地址:' + e.province + e.city + e.district + e.address + '\n' + '订单问题:' + e.feed_str
      + '\n' + '子问题:' + e.detail_str + '\n' + '问题详情:' + e.remark
      }`;
    // 商家、客户名字/电话/地址、订单问题和子问题、问题详情）
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }
  todelFeedback = async (e) => {
    let res = await delFeedback(e.id, '');
    if (res.status === 204) {
      this.getList();
    }
  }
}
export default Feedback;
import { Button, message, Checkbox } from 'antd';
import React, { Component } from 'react';
import TableList from '../tableList';
import styles from './index.module.css';
import { putUrgecontact, getOrderList, getMasterManager, getCustomManager } from '../../../services/orderCore';
import PageTurning from '../../../components/pageTurning';
import { jsToExcel2 } from '../../../utils/exportExcel';

let endDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');
let startDate = new Date(new Date() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDates: startDate,
      endDates: endDate,
      data: [],
      selectedRowKeys: [],
      skip: 0,
      take: 20,
      myCheckbox: true,
    }
  }
  componentDidMount() {
    this.getDataList();
  }
  render() {
    const { data, selectedRowKeys, myCheckbox } = this.state;
    return (
      <div className='w100'>
        <div className={`${styles['fixtop']} row-sb back-color0 padding10`}>
          <div className='row-l'>
            <div className={styles['reminder_styles']} onClick={this.onReminder}>{`批量催单(${selectedRowKeys.length})`}</div>
            <div className='margin-l20'>
              <span className={styles['title_style']}>我的区域:</span>
              <Checkbox checked={myCheckbox} onChange={this.onCheckboxChange} />
            </div>
          </div>
          <Button onClick={this.toExcel} type='primary'>表格导出</Button>
        </div>
        <div className='padding30 w100 back-color0'></div>
        <div className='w100 margin-t10 back-color0 '>
          <TableList setDisplay={this.setDisplay}
            rowSelection={true}
            data={data}
            onType={'催单'}
            selectedRowKeys={selectedRowKeys}
            onSelectChange={this.onSelectChange}
            updateTable={this.updateTables}
            delLabelData={this.delLabelData}
            updateTipData={this.updateTipData}
            onHoverSet={this.onHoverSet}
            getDataList={this.getDataList}
          />
          <PageTurning pageMaps={this.pageMaps} pageSize={20} />
        </div>
      </div>
    );
  }
  setDisplay = (index) => {
    let data = _.cloneDeep(this.state.data);
    data[index].workerDisplay = !data[index].workerDisplay;
    this.setState({
      data: data
    })
  }
  //我的区域
  onCheckboxChange = (e) => {
    this.setState({
      myCheckbox: e.target.checked
    }, async () => this.getDataList());
  }
  updateTipData = () => {
    this.getDataList();
  }
  delLabelData = (index, indexs) => {
    let data = _.cloneDeep(this.state.data);
    data[index].serviceLabels.splice(indexs, 1);
    this.setState({
      data: data
    })
  }
  //修改订单
  updateTables = (row) => {
    let data = [...this.state.data];
    let index = data.findIndex(r => r.id === row.id);
    data[index] = row;
    this.setState({
      data: data
    })
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys: selectedRowKeys
    });
  }
  onReminder = async () => {
    let selectedRowKeys = this.state.selectedRowKeys;
    let res = await putUrgecontact(selectedRowKeys)
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
    this.setState({
      selectedRowKeys: []
    })
    this.getDataList()
  }
  getDataList = async () => {
    let { startDates, endDates, skip, take, myCheckbox } = this.state;
    let res = await getOrderList({
      search: '未预约',
      addition: '全部',
      info: '',
      category: '全部',
      service: '测量',
      type: '全部',
      state: '全部',
      start: startDates,
      end: endDates,
      worker: 0,
      shop: 0,
      user: 0,
      mode: '下单时间',
      myarea: myCheckbox,
      trans: '全部',
      skip,
      take
    })
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }

    let data = res.data;
    data.map((item, index) => {
      data[index].workerDisplay = false;
    })
    this.setState({
      data: data,
    })
  };
  onHoverSet = (index, props, value) => {
    let data = [...this.state.data];
    data[index][props] = value;
    this.setState({
      data
    });
  }

  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getDataList())
  }
  toExcel = async () => {
    // isexport
    let { startDates, endDates, skip, take, myCheckbox } = this.state;
    let res = await getOrderList({
      search: '未预约',
      addition: '全部',
      info: '',
      category: '全部',
      service: '测量',
      type: '全部',
      state: '全部',
      start: startDates,
      end: endDates,
      worker: 0,
      shop: 0,
      user: 0,
      mode: '下单时间',
      myarea: myCheckbox,
      trans: '全部',
      skip,
      take,
      isexport: true,
    })
    const excelTit = [
      {
        title: '姓名[username]',
        dataIndex: 'name',
      },
      {
        title: '电话[mobile]',
        dataIndex: 'phone',
      },
      {
        title: '性别[sex]',
        dataIndex: 'sex',
      },
      {
        title: '公司[company]',
        dataIndex: 'company',
      },
      // 姓名[username]	号码[mobile]	性别[sex]	公司名称[company]

    ];
    if (res.status === 200) {
      jsToExcel2(excelTit, res.data, '6小时未预约订单列表', '');
    }
  }
}

export default Index;
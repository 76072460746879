import React, { Component } from 'react';
import TableList from '../tableList';
import { getOrderList, getMasterManager, getCustomManager, delReminder } from '../../../services/orderCore';
import PageTurning from '../../../components/pageTurning';
import styles from './index.module.css';
import { Button, message, Checkbox } from 'antd';

let endDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');
let startDate = new Date(new Date() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDates: startDate,
      endDates: endDate,
      data: [],
      skip: 0,
      take: 20,
      myCheckbox: true,
      selectedRowKeys: [],
    }
  }
  componentDidMount() {
    this.getDataList();
  }
  render() {
    const { data, myCheckbox, selectedRowKeys } = this.state;
    return (
      <div className='w100'>
        <div className={`${styles['fixtop']} row-l back-color0 padding10`}>
          <div className={styles['reminder_styles']} onClick={this.onReminder}>{`批量结束催单(${selectedRowKeys.length})`}</div>
          <div className='margin-l20'>
            <span className={styles['title_style']}>我的区域:</span>
            <Checkbox checked={myCheckbox} onChange={this.onCheckboxChange} />
          </div>
        </div>
        <div className='padding30 w100 back-color0'></div>
        <div className='w100 margin-t10 back-color0 '>
          <TableList
            setDisplay={this.setDisplay}
            data={data}
            rowSelection={true}
            onType={'催单处理'}
            selectedRowKeys={selectedRowKeys}
            onSelectChange={this.onSelectChange}
            updateTable={this.updateTables}
            delLabelData={this.delLabelData}
            updateTipData={this.updateTipData}
            onHoverSet={this.onHoverSet}
            getDataList={this.getDataList}
          />
          <PageTurning pageMaps={this.pageMaps} pageSize={20} />
        </div>
      </div>
    );
  }
  //我的区域
  onCheckboxChange = (e) => {
    this.setState({
      myCheckbox: e.target.checked
    }, async () => this.getDataList());
  }
  setDisplay = (index) => {
    let data = _.cloneDeep(this.state.data);
    data[index].workerDisplay = !data[index].workerDisplay;
    this.setState({
      data: data
    })
  }
  updateTipData = () => {
    this.getDataList();
  }
  delLabelData = (index, indexs) => {
    let data = _.cloneDeep(this.state.data);
    data[index].serviceLabels.splice(indexs, 1);
    this.setState({
      data: data
    })
  }
  //修改订单
  updateTables = (row) => {
    let data = [...this.state.data];
    let index = data.findIndex(r => r.id === row.id);
    data[index] = row;
    this.setState({
      data: data
    })
  }
  getDataList = async () => {
    let { startDates, endDates, skip, take, myCheckbox } = this.state;
    let res = await getOrderList({
      search: '催联系',
      addition: '全部',
      info: '',
      category: '全部',
      service: '测量',
      type: '全部',
      state: '全部',
      start: startDates,
      end: endDates,
      worker: 0,
      shop: 0,
      user: 0,
      mode: '下单时间',
      myarea: myCheckbox,
      trans: '全部',
      skip,
      take,
    })
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let data = res.data;
    data.map((item, index) => {
      data[index].workerDisplay = false;
    })
    this.setState({
      data: data,
    })
  };
  onHoverSet = (index, props, value) => {
    let data = [...this.state.data];
    data[index][props] = value;
    this.setState({
      data
    });
  }
  //完成催单
  onSelectChange = (selectedRowKeys) => {
    this.setState({
      selectedRowKeys: selectedRowKeys
    });
  }
  onReminder = async () => {
    let data = {
      ids: this.state.selectedRowKeys,
      type: '任务',
    };
    let res = await delReminder(data)
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
    this.setState({
      selectedRowKeys: []
    })
    this.getDataList()
  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getDataList())
  }
}

export default Index;
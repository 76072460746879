import request from '../utils/request';

//平台订单
export async function putPlatOrderList(start, end, extra, type, user, data) {
  return await request(`analysis/platorder?start=${start}&end=${end}&extra=${extra}&type=${type}&user=${user}`, 'put', data);
}
//平台各区域数据对比
export async function getAreaKpi(start, end) {
  return await request(`analysis/areakpi?start=${start}&end=${end}`, 'get');
}

//客服商户联动选项
export async function getServicerShopList() {
  return await request(`analysis/shoplist`, 'get');
}
//商户订单分析概览
export async function getShopSummary(user, start, end) {
  return await request(`analysis/userorder?user=${user}&start=${start}&end=${end}`, 'get');
}
//商户订单详情分析
export async function getShopOrder(shop, start, end) {
  return await request(`analysis/shoporder?shop=${shop}&start=${start}&end=${end}`, 'get');
}

//师傅订单分析概览
export async function getMasterSummary(start, end) {
  return await request(`analysis/workerorderbase?start=${start}&end=${end}`, 'get');
}
//师傅订单详情分析
export async function getMasterOrder(worker, start, end) {
  return await request(`analysis/workerorder?worker=${worker}&start=${start}&end=${end}`, 'get');
}
//师傅好评返现
export async function getMasterBonus(type, start, end) {
  return await request(`analysis/workerbonus?type=${type}&start=${start}&end=${end}`, 'get');
}


//财务分析
export async function getFinanceList(start, end, data) {
  return await request(`analysis/finance?start=${start}&end=${end}`, 'put', data);
}
//客服区域选项
export async function getServicerList() {
  return await request(`analysis/stafflist`, 'get');
}

//售后分析
export async function getAfterSaleList(start, end) {
  return await request(`analysis/aftersale?start=${start}&end=${end}`, 'get');
}

//业务开发分析
export async function getBusinessDevelopList(start, end) {
  return await request(`analysis/develop?start=${start}&end=${end}`, 'get');
}

//客服产能分析
export async function getServicerCapacityList(start, end, data) {
  return await request(`analysis/kffinance?start=${start}&end=${end}`, 'put', data);
}

//商户分析流水
export async function getShopBill(type, exports, start, end, skip, take) {
  return await request(`analysis/financelist?type=${type}&export=${exports}&start=${start}&end=${end}&skip=${skip}&take=${take}`, 'get');
}

//单日结算订单分析
// export async function getSingleOrder(day, skip, take) {
//   return await request(`analysis/singleday?day=${day}&skip=${skip}&take=${take}`, 'get');
// }
export async function getSingleOrder(start, end, skip, take) {
  return await request(`analysis/singleday?start=${start}&end=${end}&skip=${skip}&take=${take}`, 'get');
}


//数据客服整理数据数量
export async function getSettleOrder(staff, start, end) {
  return await request(`analysis/staffmeasure?staff=${staff}&start=${start}&end=${end}`, 'get');
}

//单日财务流水导出
export async function getSingleFlow(type, start, end) {
  return await request(`analysis/finaceflow?type=${type}&start=${start}&end=${end}`, 'get');
}


import React, { Component, } from 'react';
import { Modal, Input, message, InputNumber, Radio, Image, Button, Select, Checkbox, Drawer, DatePicker, Table, } from 'antd';

class DetailDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      current: {
        id: 0,
        feed: 0,
        name: '',
      },
    };
  }
  render() {
    const { visible, current, } = this.state;
    return (
      <Modal
        title='子问题名称'
        visible={visible}
        width={500}
        onCancel={this.onCancel}
        okText='确认'
        cancelText='取消'
        onOk={this.onOk}
      >
        <div className='row-l vertical-lt'>
          <div>名称：</div>
          <Input.TextArea value={current.name} onChange={this.changeName} className='w400' rows={3} />
        </div>
      </Modal>
    );
  }
  changeName = (e) => {
    let current = { ...this.state.current };
    current.name = e.target.value;
    this.setState({
      current,
    });
  }
  onShow = (e) => {
    let current = {
      id: 0,
      feed: 0,
      name: '',
      ...e,
    };
    this.setState({
      visible: true,
      current,
    })
  }
  onCancel = () => {
    this.setState({
      visible: false,
      current: {
        id: 0,
        feed: 0,
        name: '',
      },
    })
  }
  onOk = () => {
    const { current } = this.state;
    this.props.upload(current);
    this.onCancel();
  }
}
export default DetailDrawer;
import React, { Component } from 'react';
import { message, Table, Input, Radio, Switch, Popconfirm, Modal, Button, Drawer, } from 'antd';
import { postFeeds, putFeeds, postDetail, delDetail, putDetail } from '../../services/feed';
import DetailDrawer from './detailModal';

class FeedDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      type: 'mon',
      current: {
        id: 0,
        name: '',
        cash: '否',
        details: [],
      },
    };
    this.detailDrawer = React.createRef();
    this.colums = [
      {
        title: '名称',
        align: 'center',
        width: 600,
        dataIndex: 'name',
      },
      {
        title: '操作',
        align: 'center',
        width: 600,
        dataIndex: 'operate',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div onClick={this.showModal.bind(this, record)} className='margin-r20 underline cursor-pointer color3'>编辑</div>
              <Popconfirm
                title='您确定要删除该分类吗?'
                onConfirm={this.delDetail.bind(this, record)}
                okText='确认'
                cancelText='取消'
              >
                <div className='underline cursor-pointer color2'>删除</div>
              </Popconfirm>
            </div>
          );
        }
      },
    ];
  }
  render() {
    const { visible, current, type, } = this.state;
    return (
      <Drawer
        visible={visible}
        onClose={this.onCancel}
        width={700}
        footer={
          <div className='row-l'>
            <Button type='primary' onClick={this.onCancel}>关闭</Button>
          </div>
        }
      >
        <div className='col-t vertical-lt'>
          <div className='row-l margin-b10'>
            <div className='w140'>问题类型：</div>
            <Input disabled={type === 'son'} value={current.name} className='w200' onChange={this.changeSum.bind(this, 'name')} />
          </div>
          <div className='row-l'>
            <div className='w140'>是否与金额相关：</div>
            <Switch disabled={type === 'son'} checkedChildren="是" unCheckedChildren="否" checked={current.cash === '是' ? true : false}
              onChange={this.changeSum.bind(this, 'cash')} />
          </div>
          {
            type === 'mon' &&
            <div className='row-l margin-t20'>
              <Button type='primary' onClick={this.onOk} className='margin-r20'>确认</Button>
              <Button type='primary' onClick={this.onCancel}>取消</Button>
            </div>
          }
        </div>
        {
          type === 'son' &&
          <div className='col-t vertical-lt w100 margin-t30'>
            <div className='solid2 w100 margin-b10'></div>
            <Button type='primary' onClick={this.showModal.bind(this, {})}>新增</Button>
            <Table
              columns={this.colums}
              dataSource={current.details}
              size={'small'}
              rowKey={record => record.id}
              bordered
            />
          </div>
        }
        <DetailDrawer ref={this.detailDrawer} upload={this.newDetail} />
      </Drawer>
    );
  }
  changeSum = (type, e) => {
    let current = _.cloneDeep(this.state.current);
    current[type] = type === 'name' ? e.target.value : e ? '是' : '否';
    this.setState({
      current,
    });
  }
  onOk = async () => {
    const { current } = this.state;
    let res
    if (current.id) {
      res = await putFeeds(current.id, current.cash, current.name);
    } else {
      res = await postFeeds(current);
    }

    if (res.status === 200 || res.status === 204) {
      this.onCancel()
      this.props.update();
    }
  }
  onShow = (e, type) => {
    let current = {
      id: 0,
      name: '',
      cash: '否',
      ...e,
    }
    this.setState({
      visible: true,
      type, current,
    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
    });
  }
  showModal = (e) => {
    this.detailDrawer.current.onShow(e);
  }
  newDetail = async (e) => {

    let current = _.cloneDeep(this.state.current);
    e.feed = current.id;
    if (e.id === 0) {
      let res = await postDetail(e);
      if (res.status === 200) {
        current.details.push(res.data);
        this.setState({
          current,
        });
      }
    } else {
      let res = await putDetail(e.id, e.name);
      if (res.status === 204) {
        let index = current.details.findIndex(r => r.id === e.id);
        current.details[index].name = e.name;
        this.setState({
          current,
        });
      }
    }
  }
  delDetail = async (e) => {
    let current = _.cloneDeep(this.state.current);
    let res = await delDetail(e.id);
    if (res.status === 204) {
      current.details = current.details.filter(r => r.id !== e.id);
      this.setState({
        current,
      });
    }
  }
}
export default FeedDrawer;
import React, { Component, } from 'react';
import styles from './modal.module.css';
import locate from '../../resource/png/masterManagement-locate.png';
import del from '../../resource/png/masterManagement-del.png';
import redmark from '../../resource/png/all-customer.png';
import { HomeTwoTone, UploadOutlined } from '@ant-design/icons';
import { Modal, Input, message, InputNumber, Radio, Image, Button, Select, Checkbox, Drawer, DatePicker, Table, } from 'antd';
import Options from '../../components/option';
import Regin from '../../components/regioRegister';
import UploadPic from '../../components/UploadPic';
import WorkerModal from '../orderCore/allOrder/workerModal';
import TransferInfoModal from '../shopManagement/transferInfoModal';
import {
  getMaster, masterInfo, editMasterInfo, editMasterService, editMasterAccount, getMasterAmount,
  getMasterBill, getWithdrawalList, editMasterBalance, editMasterWithdrawal, addMasterWithdrawal,
  getMasterFollow, addMasterFollow, getMasterOperate, addMasterInfo, getMasterKpi, getMasterTransfer,
} from '../../services/masterManagement';
import { getServiceList } from '../../services/serviceManagement';
import { addMasterTemplate, delPriceTemplate, getMasterTemplate } from '../../services/templateManagement';
import { toTitude, getMyOss, getService } from '../../services/general';
import { getDepartment_Data, setOssBaseAuth, getOssBaseAuth } from '../../config/storage';
import { getMasterLabel } from '../../services/labelManagement';
import { ossUpload, } from '../../utils/ossUpload.js';
import { jsToExcel } from '../../utils/exportExcel';
import PageTurning from '../../components/pageTurning';
import { Map, Marker, InfoWindow } from 'react-amap';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const title = ['基本信息', '服务信息', '提现账户', '财务流水', '订单转化', '服务记录', '操作记录'];
const enableList = ['启用', '禁用'];
const checkList = ['已认证', '未认证'];
const statusList = ['接单中', '不接单'];
const signingList = ['已签约', '未签约'];
const signList2 = [
  {
    title: '已签约',
    value: '是',
  },
  {
    title: '未签约',
    value: '否',
  },
  {
    title: '邀请',
    value: '邀请',
  },
];
const classList = ['A', 'B', 'C', 'D'];
const typeList = ['回访', '投诉', '培训', '数据', '理赔'];
const costs = ['全部', '返利', '调整', '提现', '理赔', '好评', '收入'];
const mode = {
  id: 0,
  name: '',
  uid: '',
  id_number: '',
  an_phone: '',
  qq: '',
  wechat: '',
  emergency_contact: '',
  emergency_phone: '',
  current_address: '',
  province: '',
  city: '',
  district: '',
  address: '',
  latitude: 0,
  longitude: 0,
  remark: ['', '', '', '', '', '', ''],
  remarkt: ['', '', '', '', '', '', '', '', '', '', ''],
  enable: '是',
  checked: '否',
  order_status: '不接单',
  remote: '一类',
  id_card_pic: [],
  deposit: 0,
  work_type: [],
  signed: '否',
  manager: 0,
  manager2: 0,
  mname: '',
  areas: [],
  avator: '',
  miniProgram: '',
  offiaccount: '',
  labels: [],
};
const dateFormat = 'YYYY-MM-DD'
const today = moment().format('YYYY-MM-DD');
const monthAge = moment().subtract(3, 'month').format('YYYY-MM-DD');
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base: '',
      baseUrl: '',
      myOss: {
        host: '',
        expiration: 0,
        key: '',
        policy: '',
        prefix: '',
        signature: '',
      },
      type: '回访',
      skip: 0,
      take: 10,
      drawerShow: false,
      currentMaster: { ...mode },
      navigation: '基本信息',
      serviceList: [],
      provinces: ['', '', ''],
      address: '',
      rank: 'D',
      template: {
        name: '',
        id: 0,
      },
      areaModal: false,
      provincesModal: false,
      balanceModal: false,
      withdrawModal: false,
      followModal: false,
      mapModal: false,
      priceTemplate: [],
      currentAccount: {
        type: '',
        card_number: '',
        name: '',
        city: '',
        remark: '',
        pic: '',
      },
      newAccount: {
        type: '',
        card_number: '',
        name: '',
        city: '',
        remark: '',
        pic: '',
      },
      amount: {
        available: 0,
        balance: 0,
        withdraw: 0,
      },
      billsearch: {
        workerid: 0,
        info: '',
        type: '全部',
        start: '',
        end: '',
        timeVisible: true
      },
      skip: 0,
      skips: 0,
      skipF: 0,
      skipo: 0,
      take: 10,
      bill: [],
      withdrawal: [],
      balanceAdjust: {
        price: null,
        remark: '',
        orderid: '',
      },
      withDrawAdjust: {
        price: null,
        remark: '',
      },
      followAjust: '',
      follow: [],
      operate: [],
      newMaster: { ...mode },
      manager: '',
      role: 0,
      center: [0, 0],
      centerName: {
        content: '',
        direction: 'right'
      },
      labelList: [],
      kpiSearch: {
        start: monthAge,
        end: today,
      },
      kpiData: {
        orderNum: 0,//单量
        ap3Rate: 0,//3小时预约率
        appealNum: 0,//投诉量
        appealRate: 0,//投诉率

        bounsNum: 0,//好评数量
        onsign: 0,//准时签到率
        rp3hour: 0,//3小时数据回传率
        ins24hour: 0,//24小时安装订单完成率
        // 、、
        // 、、、、
        // 测量订单12上门时效ms12hour、24小时上门时效ms24hour、安装订单48小时上门时效ins48hour、
        // 利润profit、利润率profitRate、
        // 理赔金额aftersalePrice、理赔系数afterSalePriceRate、二次上门单量second_num、售后单量after_num
      },
      transferSearch: {
        state: '已转化',
        type: '下单',
        start: moment().subtract(1, 'year'),
        end: moment(),
        skip: 0,
        take: 10,
        total: 0,
        trans: 0,
        rate: 0,
      },
      transfetData: [],
      waiters: [],
    };
    this.workerModal = React.createRef();
    this.optionRef = React.createRef();
    this.operatePage = React.createRef();//操作记录
    this.followPage = React.createRef();//服务记录
    this.withdrawalPage = React.createRef();//提现记录
    this.billPage = React.createRef();//财务记录
    this.pageModalTrans = React.createRef();//订单转化翻页
    this.transferInfoModal = React.createRef();//订单转化
    this.columnsTransfer = [
      {
        title: '测量单编号',
        align: 'center',
        width: 120,
        dataIndex: 'id',
      },
      {
        title: '下单时间',
        align: 'center',
        width: 120,
        dataIndex: 'create_time',
        render: (text, record, index) => {
          return (
            <div>{moment(record.create_time).format('YYYY/MM/DD-HH:mm:ss')}</div>
          );
        }
      },
      {
        title: '客户信息',
        align: 'center',
        width: 250,
        dataIndex: 'info',
        key: 'info',
        render: (text, record, index) => {
          return (
            <div>
              <div>{record.name}，{record.phone}，
                {record.province}{record.city}{record.district},{record.address}
              </div>
            </div>
          );
        }
      },
      {
        title: '转化时间',
        align: 'center',
        width: 120,
        dataIndex: 'trans_time',
        render: (text, record, index) => {
          return (
            <div>{record.trans_time && record.trans_time !== '1970-01-01T00:00:00' ? moment(record.trans_time).format('YYYY/MM/DD-HH:mm:ss') : ''}</div>
          );
        }
      },
      {
        title: '转化安装单编号',
        align: 'center',
        width: 80,
        dataIndex: 'order',
        key: 'order',
        render: (text, record, index) => {
          return (
            <div>{record.order ? record.order : ''}</div>
          );
        }
      },


    ];
  }
  componentDidMount() {
    let res = getDepartment_Data();
    let myOss = getOssBaseAuth();
    this.setState({
      base: res.resource,
      baseUrl: `${res.service}/upload/`,
      myOss,
      role: res.roles[0],
    })
    this.getMyService();
    this.getMasterLabel();
    this.getServicer();
  }
  render() {
    const { base, baseUrl, myOss, drawerShow, navigation, currentMaster, provinces, address, rank,
      areaModal, priceTemplate, serviceList, currentAccount, newAccount, bill, amount, withdrawal, billsearch,
      balanceModal, balanceAdjust, withdrawModal, withDrawAdjust, followAjust, followModal, follow,
      operate, provincesModal, modalType, newMaster, template, manager, role, mapModal, center, centerName,
      labelList, type, kpiSearch, kpiData, transferSearch, transfetData, waiters,
    } = this.state;
    const columnsBill = [
      {
        title: '流水号',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 90,
      },
      {
        title: '订单编号',
        dataIndex: 'order',
        key: 'order',
        align: 'center',
        width: 90,
      },
      {
        title: '订单类型',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        width: 90,
      },
      {
        title: '费用类型',
        dataIndex: 'service_type',
        key: 'service_type',
        align: 'center',
        width: 90,
      },
      {
        title: '客户名称',
        dataIndex: 'order_info',
        key: 'order_info',
        align: 'center',
        width: 90,
      },
      {
        title: '发生金额',
        dataIndex: 'price',
        key: 'price',
        align: 'center',
        width: 90,
      },
      {
        title: '备注信息',
        dataIndex: 'remark',
        key: 'remark',
        align: 'center',
        width: 180,
      },
      {
        title: '账户余额',
        dataIndex: 'balance',
        key: 'balance',
        align: 'center',
        width: 90,
      },
      {
        title: '结算时间',
        dataIndex: 'check_time',
        key: 'check_time',
        align: 'center',
        width: 130,
        render: (text, record, index) => {
          return (<div >{moment(record.check_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
    ];
    const columsWithdrawal = [
      {
        title: '提交时间',
        dataIndex: 'submit_time',
        key: 'submit_time',
        align: 'center',
        width: 130,
        render: (text, record, index) => {
          return (<div >{moment(record.submit_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '金额',
        dataIndex: 'price',
        key: 'price',
        align: 'center',
        width: 80,
      },
      {
        title: '支付状态',
        dataIndex: 'state',
        key: 'state',
        align: 'center',
        width: 120,
        render: (text, record, index) => {
          return (
            record.state === '待支付' && [8, 9].includes(role) ?
              <div className='col'>
                <div onClick={this.editWithdrawal.bind(this, index, '已支付')} className={`${styles['btn-withdrawal']} cursor-pointer margin-b5 color0 back-color9 radius5`}>支付</div>
                <div onClick={this.editWithdrawal.bind(this, index, '已作废')} className={`${styles['btn-withdrawal']} cursor-pointer color0 back-color11 radius5`}>作废</div>
              </div>
              : record.state
          )
        }
      },
    ];
    const columsFollow = [
      {
        title: '编号',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return index + 1;
        }
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        width: 100,
      },
      {
        title: '客服',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: 150,
      },
      {
        title: '时间',
        dataIndex: 'submit_time',
        key: 'submit_time',
        align: 'center',
        width: 150,
        render: (text, record, index) => {
          return (<div >{moment(record.submit_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '内容',
        dataIndex: 'content',
        key: 'content',
        align: 'center',
        width: 500,
      },
      {
        title: '关联订单',
        dataIndex: 'order',
        key: 'order',
        align: 'center',
        width: 150,
        render: (text, record, index) => {
          return (<div>{record.order ? record.order : '/'}</div>)
        }
      },
    ];
    const columsOperate = [
      {
        title: '操作详情',
        dataIndex: 'content',
        key: 'content',
        align: 'center',
        width: 800,
      },
      {
        title: '操作时间',
        dataIndex: 'submit_time',
        key: 'submit_time',
        align: 'center',
        width: 200,
        render: (text, record, index) => {
          return (<div >{moment(record.submit_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '操作人',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: 150,
      },
    ];
    return (
      <Drawer
        title='    师傅信息'
        style={{ position: 'fixed', right: 0 }}
        visible={drawerShow}
        onClose={this.hideDrawer}
        width={1400}
        footer={
          <div className='w100 row-r'>
            <div onClick={this.hideDrawer} className={`${styles['btn']} border color6 fz17 margin-r20 cursor-pointer`} key='return'>返回</div>
            {
              navigation === '服务记录' && modalType === 'edit' ?
                <div onClick={this.showFollowModal} className={`${styles['btn']} back-color9 color0 fz17 margin-r40 cursor-pointer`} key='save'>新增记录</div> : ''
            }
            {
              navigation === '基本信息' && modalType === 'edit' || navigation === '服务信息' && modalType === 'edit' ?
                <div onClick={this.editMyInfo} className={`${styles['btn']} back-color9 color0 fz17 margin-r40 cursor-pointer`} key='save'>保存</div> : ''
            }
            {
              modalType === 'add' ?
                <div onClick={this.addMaster} className={`${styles['btn']} back-color9 color0 fz17 margin-r40 cursor-pointer`} key='savead'>保存</div> : ''
            }
          </div>
        }
      >
        {
          modalType === 'edit' ?
            <div>
              <Options title={title} top={0} changeMyTop={this.changeSummary.bind(this, 'top')} ref={this.optionRef} style={2} number={true} />
              {
                navigation === '基本信息' ?
                  (
                    <div className='row-l vertical-lt w100' key='baseInfo'>
                      <div className='col-t vertical-lt margin-r20'>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>师傅姓名：</div>
                          <Input value={currentMaster.name} onChange={this.changeSummary.bind(this, 'name')} className={`${styles['input']} tl`} />
                          <div className={`${styles['property']} tr`}>手机账号：</div>
                          <Input value={currentMaster.uid} onChange={this.changeSummary.bind(this, 'uid')} className={`${styles['input']} tl`} />
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>负责人：</div>
                          <div className='w300'>
                            <Input disabled className='w198' value={manager} />
                            <Button type='primary' className='margin-l10' onClick={this.openModal.bind(this, '客服')}>选择</Button>
                          </div>
                          <div className={`${styles['property']} tr`}>负责人2：</div>
                          <div className='w300'>
                            <Select value={currentMaster.manager2 ? currentMaster.manager2 : null}
                              onChange={this.changeSummary.bind(this, 'manager2')} className='w300' >
                              {
                                waiters.map((item, index) => {
                                  return (
                                    <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                  );
                                })
                              }
                            </Select>
                          </div>
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>加入时间：</div>
                          <div>{moment(currentMaster.join_time).format('YYYY-MM-DD')}</div>
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>身份证号：</div>
                          <Input value={currentMaster.id_number} onChange={this.changeSummary.bind(this, 'id_number')} className={`${styles['input']} tl`} />
                          <div className={`${styles['property']} tr`}>备用电话：</div>
                          <Input value={currentMaster.an_phone} onChange={this.changeSummary.bind(this, 'an_phone')} className={`${styles['input']} tl`} />
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>微信号码：</div>
                          <Input value={currentMaster.wechat} onChange={this.changeSummary.bind(this, 'wechat')} className={`${styles['input']} tl`} />
                          <div className={`${styles['property']} tr`}>QQ号码：</div>
                          <Input value={currentMaster.qq} onChange={this.changeSummary.bind(this, 'qq')} className={`${styles['input']} tl`} />
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>紧急联系人：</div>
                          <Input value={currentMaster.emergency_contact} onChange={this.changeSummary.bind(this, 'emergency_contact')} className={`${styles['input']} tl`} />
                          <div className={`${styles['property']} tr`}>紧急电话：</div>
                          <Input value={currentMaster.emergency_phone} onChange={this.changeSummary.bind(this, 'emergency_phone')} className={`${styles['input']} tl`} />
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>当前住址：</div>
                          <div className={`${styles['current-address']} tl row-sb`}>
                            <div className={`${styles['address-detail']} oneline`}>{currentMaster.current_address}</div>
                            <div onClick={this.showProvincesModal} className='padding-a510 cursor-pointer'><HomeTwoTone /></div>
                          </div>
                          <div onClick={this.showMapModal.bind(this, currentMaster)} className={styles['btn-map']}>查看地图</div>
                        </div>
                        <div className='row-l vertical-lt margin-t20'>
                          <div className='w70 tc margin-l10 margin-r5'>区域合作计划备注：</div>
                          {/* <TextArea value={currentMaster.remark} onChange={this.changeSummary.bind(this, 'remark')} className={`${styles['remark']} tl`} /> */}
                          <div className='col-t vertical-lt '>
                            <div className='row-l margin-b5'>
                              <div className='w120 row-l'>
                                <div className='fz18 margin-b5'>①</div>
                                <div>第一合作师傅：</div>
                              </div>
                              <Input value={currentMaster.remark[0]} onChange={this.changeRe.bind(this, 'remark', '0')} className={`${styles['input']} tl`} />
                            </div>
                            <div className='row-l margin-b5'>
                              <div className='w120 row-l'>
                                <div className='fz18 margin-b5 opacity0 none-select'>①</div>
                                <div>第二合作师傅：</div>
                              </div>
                              <Input value={currentMaster.remark[1]} onChange={this.changeRe.bind(this, 'remark', '1')} className={`${styles['input']} tl`} />
                            </div>
                            <div className='row-l margin-b5'>
                              <div className='w120 row-l'>
                                <div className='fz18 margin-b5 opacity0 none-select'>①</div>
                                <div className='opacity0 none-select'>第二</div>
                                <div>备用师傅：</div>
                              </div>
                              <Input value={currentMaster.remark[2]} onChange={this.changeRe.bind(this, 'remark', '2')} className={`${styles['input']} tl`} />
                            </div>
                            <div className='row-l margin-b5'>
                              <div className='w120 row-l'>
                                <div className='fz18 margin-b5'>②</div>
                                <div>师傅服务区域：</div>
                              </div>
                              <Input value={currentMaster.remark[3]} onChange={this.changeRe.bind(this, 'remark', '3')} className={`${styles['input']} tl`} />
                            </div>
                            <div className='row-l margin-b5'>
                              <div className='w120 row-l'>
                                <div className='fz18 margin-b5'>③</div>
                                <div>师傅费用情况：</div>
                              </div>
                              <Input value={currentMaster.remark[4]} onChange={this.changeRe.bind(this, 'remark', '4')} className={`${styles['input']} tl`} />
                            </div>
                            <div className='row-l margin-b5'>
                              <div className='w120 row-l'>
                                <div className='fz18 margin-b5'>④</div>
                                <div>师傅安装手艺 / </div>
                              </div>
                              测量数据是否清晰：
                              <Select value={currentMaster.remark[5] ? currentMaster.remark[5] : ''}
                                onChange={this.changeRe.bind(this, 'remark', '5')} className='w90'>
                                <Select.Option value=''>未选择</Select.Option>
                                <Select.Option value='是'>是</Select.Option>
                                <Select.Option value='否'>否</Select.Option>
                              </Select>
                            </div>
                            <div className='row-l vertical-lt margin-b5'>
                              <div className='w120 row-l'>
                                <div className='fz18 margin-b5'>⑤</div>
                                <div>其他备注：</div>
                              </div>
                              <Input.TextArea value={currentMaster.remark[6]} onChange={this.changeRe.bind(this, 'remark', '6')}
                                rows={5} className='w550' />
                            </div>
                          </div>
                          {/* ①②③④⑤ */}
                        </div>
                        <div className='row-l margin-t20 margin-b10'>
                          <DatePicker value={moment(kpiSearch.start)} onChange={this.changeKpi.bind(this, 'start')} allowClear={false} locale={locale} />
                          <DatePicker value={moment(kpiSearch.end)} onChange={this.changeKpi.bind(this, 'end')} allowClear={false} locale={locale} />
                          <Button onClick={this.getWorkkpi} type='primary'>搜索</Button>
                          {/* // 订单量、3小时预约率、投诉量、投诉率、好评数量、利润（对平台）、利润率（对平台）、理赔金额、理赔系数 */}
                        </div>
                        <table>
                          <tbody className={styles['cell']}>
                            <tr className={styles['trd']}>
                              <td className={styles['trd']}>单量</td>
                              <td className={styles['trd']}>{kpiData.orderNum}</td>
                              <td className={styles['trd']}>3小时预约率</td>
                              <td className={styles['trd']}>{Number(Math.abs(kpiData.ap3Rate * 100)).toFixed(1) + '%'}</td>
                              <td className={styles['trd']}>好评数量</td>
                              <td className={styles['trd']}>{kpiData.bounsNum}</td>
                            </tr>
                            <tr className={styles['trd']}>
                              <td className={styles['trd']}>投诉量</td>
                              <td className={styles['trd']}>{kpiData.appealNum}</td>
                              <td className={styles['trd']}>投诉率</td>
                              <td className={styles['trd']}>{Number(kpiData.appealRate * 100).toFixed(1) + '%'}</td>

                            </tr>
                            <tr className={styles['trd']}>
                              <td className={styles['trd']}>准时签到率</td>
                              <td className={styles['trd']}>{Number(Math.abs(kpiData.onsign * 100)).toFixed(1) + '%'}</td>
                              <td className={styles['trd']}>3h数据回传率</td>
                              <td className={styles['trd']}>{Number(Math.abs(kpiData.rp3hour * 100)).toFixed(1) + '%'}</td>
                              <td className={styles['trd']}>24h安装订单完成率</td>
                              <td className={styles['trd']}>{Number(Math.abs(kpiData.ins24hour * 100)).toFixed(1) + '%'}</td>
                            </tr>
                            <tr className={styles['trd']}>
                              <td className={styles['trd']}>测量订单12h上门时效</td>
                              <td className={styles['trd']}>{kpiData.ms12hour ? Number(Math.abs(kpiData.ms12hour * 100)).toFixed(1) : 0}%</td>
                              <td className={styles['trd']}>24h上门时效</td>
                              <td className={styles['trd']}>{kpiData.ms24hour ? Number(Math.abs(kpiData.ms24hour * 100)).toFixed(1) : 0}%</td>
                              <td className={styles['trd']}>安装订单48h上门时效</td>
                              <td className={styles['trd']}>{kpiData.ins48hour ? Number(Math.abs(kpiData.ins48hour * 100)).toFixed(1) : 0}%</td>
                            </tr>
                            <tr className={styles['trd']}>
                              <td className={styles['trd']}>利润</td>
                              <td className={styles['trd']}>{kpiData.profit}</td>
                              <td className={styles['trd']}>利润率</td>
                              <td className={styles['trd']}>{kpiData.profitRate ? Number(kpiData.profitRate * 100).toFixed(1) : 0}%</td>
                            </tr>
                            <tr className={styles['trd']}>
                              <td className={styles['trd']}>理赔金额</td>
                              <td className={styles['trd']}>{kpiData.aftersalePrice}</td>
                              <td className={styles['trd']}>理赔系数</td>
                              <td className={styles['trd']}>{kpiData.afterSalePriceRate ? Number(Math.abs(kpiData.afterSalePriceRate * 100)).toFixed(1) : 0}%</td>
                            </tr>
                            <tr className={styles['trd']}>
                              <td className={styles['trd']}>二次上门单量</td>
                              <td className={styles['trd']}>{kpiData.second_num}</td>
                              <td className={styles['trd']}>售后单量</td>
                              <td className={styles['trd']}>{kpiData.after_num}</td>
                            </tr>
                          </tbody>
                        </table>

                      </div>
                      <div className='col-t vertical-lt'>
                        <div className='row-l margin-t25'>
                          <div className={`${styles['property']} tr color6`}>账号状态：</div>
                          <Radio.Group onChange={this.changeSummary.bind(this, 'enable')} value={currentMaster.enable === '是' ? '启用' : '禁用'} size='large'>
                            {
                              enableList.map((item, index) => {
                                return <Radio value={item} key={index} checked={currentMaster.enable === '是' ? true : false} className='fz16'>{item}</Radio>;
                              })
                            }
                          </Radio.Group>
                        </div>
                        <div className='row-l margin-t25'>
                          <div className={`${styles['property']} tr color6`}>认证状态：</div>
                          <Radio.Group onChange={this.changeSummary.bind(this, 'checked')} value={currentMaster.checked === '是' ? '已认证' : '未认证'} size='large'>
                            {
                              checkList.map((item, index) => {
                                return <Radio value={item} key={index} checked={currentMaster.check === '是' ? true : false} className='fz16'>{item}</Radio>;
                              })
                            }
                          </Radio.Group>
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr col-r`}>
                            <div>身份证：</div>
                            <div className='color5'>（人像面）</div>
                          </div>
                          <UploadPic
                            oss={myOss.host}
                            src={currentMaster.id_card_pic && currentMaster.id_card_pic[0] ? currentMaster.id_card_pic[0] : ''}
                            upLoad={this.upLoadIdPic.bind(this, 0)}
                            deletePic={this.delIdPic.bind(this, 0)}
                            size={'small'}
                            className={styles['box']}
                          />
                          <div className={`${styles['property']} tr col-r`}>
                            <div>身份证：</div>
                            <div className='color5'>（国徽面）</div>
                          </div>
                          <UploadPic
                            oss={myOss.host}
                            src={currentMaster.id_card_pic && currentMaster.id_card_pic[1] ? currentMaster.id_card_pic[1] : ''}
                            upLoad={this.upLoadIdPic.bind(this, 1)}
                            deletePic={this.delIdPic.bind(this, 1)}
                            size={'small'}
                            className={styles['box']}
                          />
                        </div>
                        <div className='col-t vertical-lt margin-t10'>
                          <div>师傅特殊备注：</div>
                          <div className='row-l margin-b5 margin-l20'>
                            <div className='w140 row-l'>
                              <div className='fz18 margin-b5'>①</div>
                              <div>维护师傅来源：</div>
                            </div>
                            <Input value={currentMaster.remarkt[0]} onChange={this.changeRe.bind(this, 'remarkt', '0')} className={`${styles['input']} tl`} />
                          </div>
                          <div className='row-l margin-b5 margin-l20'>
                            <div className='w140 row-l'>
                              <div className='fz18 margin-b5'>②</div>
                              <div>是否是窗帘店：</div>
                            </div>
                            <Select value={currentMaster.remarkt[1] ? currentMaster.remarkt[1] : ''}
                              onChange={this.changeRe.bind(this, 'remarkt', '1')} className='w90'>
                              <Select.Option value=''>未选择</Select.Option>
                              <Select.Option value='是'>是</Select.Option>
                              <Select.Option value='否'>否</Select.Option>
                            </Select>
                          </div>
                          <div className='row-l margin-b5 margin-l20'>
                            <div className='w140 row-l'>
                              <div className='fz18 margin-b5'>③</div>
                              <div>是否是团队师傅：</div>
                            </div>
                            <Select value={currentMaster.remarkt[2] ? currentMaster.remarkt[2] : ''}
                              onChange={this.changeRe.bind(this, 'remarkt', '2')} className='w90'>
                              <Select.Option value=''>未选择</Select.Option>
                              <Select.Option value='是'>是</Select.Option>
                              <Select.Option value='否'>否</Select.Option>
                            </Select>
                          </div>
                          <div className='row-l margin-b5 margin-l20'>
                            <div className='w140 row-l'>
                              <div className='fz18 margin-b5'>④</div>
                              <div>与那些平台合作：</div>
                            </div>
                            <Input value={currentMaster.remarkt[3]} onChange={this.changeRe.bind(this, 'remarkt', '3')} className={`${styles['input']} tl`} />
                          </div>
                          <div className='row-l margin-b5 margin-l20'>
                            <div className=' row-l'>
                              <div className='fz18 margin-b5'>⑤</div>
                              <div>在会安装的产品后填写安装费用：</div>
                            </div>
                          </div>
                          <div className='row-l margin-b5 margin-l20'>
                            <div className='w140 row-l'>
                              <div className='fz18 margin-b5 opacity0'>④</div>
                              <div>窗帘盒费用：</div>
                            </div>
                            <Input value={currentMaster.remarkt[4]} onChange={this.changeRe.bind(this, 'remarkt', '4')}
                              className={`${styles['input']} w120 tl`} />
                          </div>
                          <div className='row-l margin-b5 margin-l20'>
                            <div className='w140 row-l'>
                              <div className='fz18 margin-b5 opacity0'>④</div>
                              <div>电动直轨费用：</div>
                            </div>
                            <Input value={currentMaster.remarkt[5]} onChange={this.changeRe.bind(this, 'remarkt', '5')}
                              className={`${styles['input']} w120 tl`} />
                          </div>
                          <div className='row-l margin-b5 margin-l20'>
                            <div className='w140 row-l'>
                              <div className='fz18 margin-b5 opacity0'>④</div>
                              <div>电动弯轨费用：</div>
                            </div>
                            <Input value={currentMaster.remarkt[6]} onChange={this.changeRe.bind(this, 'remarkt', '6')}
                              className={`${styles['input']} w120 tl`} />
                          </div>
                          <div className='row-l margin-b5 margin-l20'>
                            <div className='w140 row-l'>
                              <div className='fz18 margin-b5 opacity0'>④</div>
                              <div>手动梦幻帘费用：</div>
                            </div>
                            <Input value={currentMaster.remarkt[7]} onChange={this.changeRe.bind(this, 'remarkt', '7')}
                              className={`${styles['input']} w120 tl`} />
                          </div>
                          <div className='row-l margin-b5 margin-l20'>
                            <div className='w140 row-l'>
                              <div className='fz18 margin-b5 opacity0'>④</div>
                              <div>电动梦幻帘费用：</div>
                            </div>
                            <Input value={currentMaster.remarkt[8]} onChange={this.changeRe.bind(this, 'remarkt', '8')}
                              className={`${styles['input']} w120 tl`} />
                          </div>
                          <div className='row-l margin-b5 margin-l20'>
                            <div className='w140 row-l'>
                              <div className='fz18 margin-b5'>⑥</div>
                              <div>费用支付方式：</div>
                            </div>
                            <Select value={currentMaster.remarkt[9] ? currentMaster.remarkt[9] : ''}
                              onChange={this.changeRe.bind(this, 'remarkt', '9')} className='w90'>
                              <Select.Option value=''>未选择</Select.Option>
                              <Select.Option value='微信'>微信</Select.Option>
                              <Select.Option value='支付宝'>支付宝</Select.Option>
                              <Select.Option value='其他'>其他</Select.Option>
                            </Select>
                          </div>
                          <div className='row-l vertical-lt margin-b5 margin-l20'>
                            <div className='w140 row-l'>
                              <div className='fz18 margin-b5'>⑦</div>
                              <div>其他备注：</div>
                            </div>
                            <Input.TextArea value={currentMaster.remarkt[10]} onChange={this.changeRe.bind(this, 'remarkt', '10')}
                              rows={5} className='w330' />
                          </div>
                          {/* <div className='w70 tc margin-l10 margin-r5'>师傅个人特殊备注：</div>
                          <TextArea value={currentMaster.remarkt} onChange={this.changeSummary.bind(this, 'remarkt')} className={`${styles['remark']} tl`} /> */}

                        </div>
                      </div>
                    </div>
                  )
                  : ''
              }
              {
                navigation === '服务信息' ?
                  (
                    <div className='col-t w100 vertical-lt' key='serviceInfo'>
                      <div className='row-l margin-t20'>
                        <div className='col-t'>
                          <div className='row-l'>
                            <div className={`${styles['property']} tr`}>价格模板：</div>
                            <Input disabled={true} className={styles['select']} value={template.name} />
                            {
                              template.name ?
                                <div onClick={this.delTemplate} className={`${styles['btn-price']} back-color11 color0 tc margin-l25 fz16 cursor-pointer`}>删除价格模板</div>
                                : <div onClick={this.openTemplate} className={`${styles['btn-price']} back-color5 color0 tc margin-l25 fz16 cursor-pointer`}>选择价格模板</div>
                            }
                          </div>
                          <div className='row-l  margin-t20'>
                            <div className={`${styles['property']} tr`}>服务类型：</div>
                            <div className={`${styles['service-box']} row-w padding10`}>
                              <Checkbox.Group options={serviceList} value={currentMaster.work_type} onChange={this.changeSummary.bind(this, 'service')} />
                            </div>
                          </div>
                        </div>
                        <div className='col-t vertical-lt'>
                          <div className='row-l margin-t10'>
                            <div className={`${styles['property']} tr color6`}>接单状态：</div>
                            <Radio.Group onChange={this.changeSummary.bind(this, 'order_status')} value={currentMaster.order_status === '接单中' ? '接单中' : '不接单'} size='large'>
                              {
                                statusList.map((item, index) => {
                                  return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                                })
                              }
                            </Radio.Group>
                          </div>
                          <div className='row-l margin-t20'>
                            <div className={`${styles['property']} tr color6`}>是否签约：</div>
                            <Radio.Group onChange={this.changeSummary.bind(this, 'signed')} value={currentMaster.signed} size='large'>
                              {
                                signList2.map((item, index) => {
                                  return <Radio value={item.value} key={index} className='fz16'>{item.title}</Radio>;
                                })
                              }
                            </Radio.Group>
                          </div>
                          <div className='row-l margin-t20'>
                            <div className={`${styles['property']} tr color6`}>服务分类：</div>
                            <Radio.Group onChange={this.changeSummary.bind(this, 'remote')} value={currentMaster.remote ? currentMaster.remote : '一类'} size='large'>
                              {
                                ['一类', '二类'].map((item, index) => {
                                  return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                                })
                              }
                            </Radio.Group>
                          </div>
                          <div className={`${styles['disable']} row-l margin-t20 back-color2`}>
                            <div className={`${styles['property']} tr`}>质保金额：</div>
                            <InputNumber disabled={role === 5} onChange={this.changeSummary.bind(this, 'deposit')} min={0} value={currentMaster.deposit} className={`${styles['bail']} tl padding-l20 back-color10`} />
                            <div className='margin-l10'>元</div>
                          </div>
                        </div>
                      </div>
                      <div className='row-l margin-t25'>
                        <div className={`${styles['property']} tr`}>服务区域：</div>
                        <div className={`${styles['area-box']} row-w padding10 `}>
                          {currentMaster.areas ?
                            currentMaster.areas.map((item, index) => {
                              return (
                                <div className={`${styles['circle']} col margin-r10 margin-b10 position`} key={index}>
                                  <div className={`${styles['area']} row-l `}>
                                    <Image src={locate} className={`${styles['img-locate']} margin-l5 margin-r5`} preview={false} />
                                    <div className='col-l'>
                                      <div>{item.district}</div>
                                      <div>{item.province + item.city}</div>
                                    </div>
                                  </div>
                                  <Image onClick={this.delArea.bind(this, index)} src={del} className={styles['img-delarea']} preview={false} />
                                  <Select disabled={role === 5} onSelect={this.changeSummary.bind(this, 'select')}
                                    value={'当前服务等级：' + item.rank} className={`${styles['class-line']} w100`}
                                    bordered={false}
                                  >
                                    {/* remote */}
                                    {
                                      classList.map((item, indexs) => {
                                        return (
                                          <Option key={indexs} value={item + index}>{item}</Option>
                                        );
                                      })
                                    }
                                  </Select>
                                  {/* 111 */}
                                </div>
                              );
                            }) : ''
                          }
                        </div>
                        <div className='col-t margin-l10'>
                          <div onClick={this.showAreaModal} className={`${styles['btn-service']} tc color0 back-color5 margin-b20 cursor-pointer`}>新增服务区域</div>
                          <div onClick={this.emptyArea} className={`${styles['btn-service']} tc color0 back-color8 cursor-pointer`}>清空服务区域</div>
                        </div>
                      </div>
                      <div>
                        {/* labelList */}
                      </div>
                      <div className='row-l  margin-t20'>
                        <div className={`${styles['property']} tr`}>师傅标签：</div>
                        <div className={`${styles['service-box']} row-w padding10 w850`}>
                          <Checkbox.Group options={labelList} value={currentMaster.labels} onChange={this.changeSummary.bind(this, 'labels')} />
                        </div>
                      </div>
                    </div>
                  ) : ''
              }
              {
                navigation === '提现账户' ?
                  (
                    <div className='col-t w100 vertical-lt h100' key='amount'>
                      <div className='row-l margin-t25 padding-b25'>
                        <div className={`${styles['propertys']} tr`}>已绑定商户类型：</div>
                        <div className='col-t vertical-lt margin-r10'>
                          <div className={`${styles['select-drawer']} back-color10 row-l fz16 padding-lr20`}>
                            <div>账户类型：</div>
                            <div className='fz20 bold'>{currentAccount.type ? currentAccount.type : '无'}</div>
                            <div className='margin-l10'>提现账户：</div>
                            <div className='fz20 bold'>{currentAccount.card_number ? currentAccount.card_number : '无'}</div>
                            <div className='margin-l10'>实名认证：</div>
                            <div className='fz20 bold'>{currentAccount.name ? currentAccount.name : '无'}</div>
                          </div>
                          <div className='margin-l20 color2'>* 用户只能提现至当前绑定的提现账户</div>
                        </div>
                        <UploadPic
                          oss={myOss.host}
                          src={currentAccount.pic}
                          upload={false}
                          picType={true}
                          size={'small'}
                          className={styles['box']}
                        />
                      </div>
                      <div className={`${styles['class-line']} row-l padding-t25 w100 h100`}>
                        <div className='h100 col-t'>
                          <div className={`${styles['propertys']} tr`}>其他提现账户：</div>
                          <div className={`${styles['propertys']} tr color2`}>待处理（{currentMaster.accounts ? currentMaster.accounts.length : 0}）</div>
                        </div>
                        <div className={`${styles['account']} col-t vertical-lt   h100`}>
                          {currentMaster.accounts ?
                            currentMaster.accounts.map((item, index) => {
                              return (
                                <div className='col-t margin-b20 back-color2 padding20' key={index}>
                                  <div className='row-l'>
                                    <div className={`${styles['property']} tc`}>账户类型：</div>

                                    <Select value={item.type} className='tl margin-r10 w300' onChange={this.changeAccount.bind(this, index, 'type')}>
                                      {
                                        ['微信', '支付宝', '银行卡', '其他'].map((sitem, sindex) => {
                                          return (
                                            <Select.Option value={sitem} key={sindex}>{sitem}</Select.Option>
                                          );
                                        })
                                      }
                                    </Select>
                                    {/* <Input onChange={this.changeAccount.bind(this, index, 'type')} value={item.type} className={`${styles['input']} tl margin-r10`} /> */}
                                    <div className={`${styles['property']} tc`}>提现账户：</div>
                                    <Input onChange={this.changeAccount.bind(this, index, 'card_number')} value={item.card_number} className={`${styles['input']} tl margin-r10`} />
                                    <div className={`${styles['property']} tc`}>实名认证：</div>
                                    <Input onChange={this.changeAccount.bind(this, index, 'name')} value={item.name} className={`${styles['input']} tl margin-r20`} />
                                  </div>
                                  <div className='row-sb w100'>
                                    <div>
                                      <div className='w100 margin-t10 color2 tl '>* 当前为师傅上传的修改账户信息，点击"应用"后，将替换已绑定提现账户。</div>
                                      <div className='row-l w100 margin-t10'>
                                        <div onClick={this.replaceAccount.bind(this, index)}
                                          className={`${styles['btn-price']} back-color5 color0 tc fz16`}
                                        >应用</div>
                                      </div>
                                    </div>
                                    <UploadPic
                                      oss={myOss.host}
                                      src={item.pic}
                                      size={'small'}
                                      upLoad={this.upLoadAccount.bind(this, index)}
                                      deletePic={this.delAccountPic.bind(this, index)}
                                      className={`${styles['box']} margin-r20`}
                                    />
                                  </div>
                                </div>
                              );
                            }) : ''
                          }
                          <div className='col-t margin-b20 back-color2 padding20'>
                            <div className='row-l'>
                              <div className={`${styles['property']} tc`}>账户类型：</div>
                              <Select value={newAccount.type} className='tl margin-r10 w300' onChange={this.changeNewAccount.bind(this, 'type')}>
                                {
                                  ['微信', '支付宝', '银行卡', '其他'].map((sitem, sindex) => {
                                    return (
                                      <Select.Option value={sitem} key={sindex}>{sitem}</Select.Option>
                                    );
                                  })
                                }
                              </Select>
                              {/* <Input onChange={this.changeNewAccount.bind(this, 'type')} value={newAccount.type} className={`${styles['input']} tl margin-r10`} /> */}
                              <div className={`${styles['property']} tc`}>提现账户：</div>
                              <Input onChange={this.changeNewAccount.bind(this, 'card_number')} value={newAccount.card_number} className={`${styles['input']} tl margin-r10`} />
                              <div className={`${styles['property']} tc`}>实名认证：</div>
                              <Input onChange={this.changeNewAccount.bind(this, 'name')} value={newAccount.name} className={`${styles['input']} tl margin-r20`} />
                            </div>
                            <div className='row-sb w100'>
                              <div>
                                <div className='w100 margin-t10 color2 tl '>* 点击"应用"后，将替换已绑定提现账户。</div>
                                <div className='row-l w100 margin-t10'>
                                  <div onClick={this.addAccount} className={`${styles['btn-price']} back-color5 color0 tc fz16`}>应用</div>
                                </div>
                              </div>
                              <UploadPic
                                oss={myOss.host}
                                src={newAccount.pic}
                                size={'small'}
                                upLoad={this.upLoadNewAccount}
                                deletePic={this.delNewAccountPic}
                                className={`${styles['box']} margin-r20`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : ''
              }
              {
                navigation === '财务流水' ?
                  (
                    <div className='col-t w100 vertical-lt w100 h100' key='bill'>
                      <div className='row w100 margin-t25 bold'>
                        <div className={`${styles['amount']} ${styles['border-right']} col tc`}>
                          <div className='margin-b5 fz20 bold'>{amount.balance}</div>
                          <div>师傅余额（元）</div>
                        </div>
                        <div className={`${styles['amount']}  ${styles['border-right']} col tc`}>
                          <div className='margin-b5 fz20 bold'>{amount.withdraw}</div>
                          <div>提现中余额（元）</div>
                        </div>
                        <div className={`${styles['amount']} col tc`}>
                          <div className='margin-b5 fz20 bold'>{amount.available}</div>
                          <div>可提现余额（元）</div>
                        </div>
                      </div>
                      <div className='row-l vertical-lt margin-t25'>
                        <div className={`${styles['property']} tr`}>客户信息：</div>
                        <Select value={billsearch.type} onChange={this.changeSummary.bind(this, 'type')} >
                          {
                            costs.map((item, index) => {
                              return (
                                <Select.Option value={item} key={index}>{item}</Select.Option>
                              );
                            })
                          }
                        </Select>
                        <Input onChange={this.changeSummary.bind(this, 'billInfo')} value={billsearch.info}
                          className={`${styles['input']} tl`} placeholder='请输入客户相关信息' />
                        <div onClick={this.searchBill} className={`${styles['btn-finance']} margin-l20 tc back-color5 color0 fz17 cursor-pointer`}>搜索</div>
                        <div onClick={this.empty} className={`${styles['btn-finance']} margin-l20 tc margin-r40 fz17 cursor-pointer`}>重置</div>
                        <Checkbox onChange={this.changeSummary.bind(this, 'timeVisible')} checked={billsearch.timeVisible} className={`${styles['propertys']}`}>发生时间：</Checkbox>
                        <RangePicker onChange={this.changeSummary.bind(this, 'date')} value={[billsearch.start, billsearch.end]}
                          disabled={!billsearch.timeVisible} placeholder={['开始日期', '结束日期']} allowClear={false} locale={locale} />
                      </div>
                      <div className='w100 row-sb margin-t20'>
                        {
                          role === 5 ?
                            <div></div>
                            :
                            <div onClick={this.showBalanceModal} className={`${styles['btn-price']} back-color5 color0 tc fz16 cursor-pointer`}>调整修正</div>
                        }
                        <div onClick={this.showWithdrawModal} className={`${styles['btn-price']} back-color5 color0 tc fz16 cursor-pointer`}>添加提现</div>
                      </div>
                      <div className='row-l vertical-lt margin-t25 h100'>
                        <div className='col-t h100'>
                          <Table
                            rowKey={record => record.id}
                            dataSource={bill}
                            columns={columnsBill}
                            pagination={false}
                            bordered />
                          <div className='back-color0 w100'>
                            <PageTurning pageMaps={this.pageMaps.bind(this)} ref={this.billPage} />
                          </div>
                        </div>
                        <div className='col-t h100 margin-l20 w360'>
                          <div className='w100 margin-b20 row-sa'>
                            <div>提现记录</div>
                            <div className='color5'>—————————————————</div>
                          </div>
                          <Table
                            rowKey={record => record.submit_time}
                            dataSource={withdrawal}
                            columns={columsWithdrawal}
                            pagination={false}
                            bordered />
                          <div className='back-color0 w100'>
                            <PageTurning pageMaps={this.pageMap.bind(this)} ref={this.withdrawalPage} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : ''
              }
              {
                navigation === '订单转化' ? (
                  <div className='col-t w100 vertical-lt w100'>
                    <div className='row-l margin-t10'>
                      <div>转化状态：</div>
                      <Radio.Group value={transferSearch.state} onChange={this.changeTransInfo.bind(this, 'state')}>
                        {
                          ['已转化', '未转化'].map((item, index) => {
                            return (
                              <Radio value={item} key={index}>{item}</Radio>
                            );
                          })
                        }
                      </Radio.Group>
                      <div className='margin-l20'>时间类型：</div>
                      <Radio.Group value={transferSearch.type} onChange={this.changeTransInfo.bind(this, 'type')}>
                        {
                          ['下单', '完成', '转化'].map((item, index) => {
                            return (
                              <Radio value={item} key={index}>{item}</Radio>
                            );
                          })
                        }
                      </Radio.Group>
                    </div>
                    <div className='row-l margin-t10'>
                      <div className='row-l margin-t10'>
                        <div>时间范围：</div>
                        <DatePicker locale={locale} onChange={this.changeTransInfo.bind(this, 'start')} allowClear={false}
                          value={transferSearch.start} format={dateFormat} className={styles['select-head']} />
                        至
                        <DatePicker locale={locale} onChange={this.changeTransInfo.bind(this, 'end')} allowClear={false}
                          value={transferSearch.end} format={dateFormat} className={styles['select-head']} />
                        <Button onClick={this.toSearchTransfer} type='primary' className='margin-r20'>搜索</Button>
                        <Button onClick={this.toExcelTransfer} type='primary'>导出Excel</Button>
                        <div className='margin-l20 bold fz20'>转化率：{transferSearch.rate}</div>

                      </div>
                    </div>
                    <div className='w100 back-color0 '>
                      <Table
                        columns={this.columnsTransfer}
                        dataSource={transfetData}
                        size={'small'}
                        className='w100 margin-t10'
                        rowKey={record => record.id}
                        bordered
                        pagination={false}
                      />
                      <PageTurning pageMaps={this.pageMapsTransfer} ref={this.pageModalTrans} />
                    </div>
                  </div>
                ) : ''
              }
              {
                navigation === '服务记录' ?
                  (
                    <div className='margin-t25' key='follow'>
                      <div className='row-l margin-b10'>
                        <div className='fz20'>类型：</div>
                        <div className='row-w '>
                          {
                            typeList.map((item, index) => {
                              return (
                                <div className={`bar-item fz18 ${type === item ? 'bar-item-color' : ''}`}
                                  key={index} onClick={this.changeRecord.bind(this, item)}
                                >
                                  {item}
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                      <Table
                        rowKey={record => record.id}
                        dataSource={follow}
                        columns={columsFollow}
                        pagination={false}
                        bordered
                      />
                      <div className='back-color0 w100'>
                        <PageTurning pageMaps={this.pageMapFollow.bind(this)} ref={this.followPage} />
                      </div>
                    </div>
                  ) : ''
              }
              {
                navigation === '操作记录' ?
                  (
                    <div className='margin-t25' key='operate'>
                      <Table
                        rowKey={'index'}
                        dataSource={operate}
                        columns={columsOperate}
                        pagination={false}
                        bordered
                      />
                      <div className='back-color0 w100'>
                        <PageTurning pageMaps={this.pageMapOperate.bind(this)} ref={this.operatePage} />
                      </div>
                    </div>
                  ) : ''
              }
            </div>
            :
            <div className='col-t vertical-lt w100' key='baseInfo'>
              <div className='row-l vertical-lt w100'>
                <div className='col-t vertical-lt margin-r20'>
                  <div className='row-l margin-t20'>
                    <div className={`${styles['property']} row-r`}>
                      <div className='color2'>*</div>
                      <div>师傅姓名：</div>
                    </div>
                    <Input value={newMaster.name} onChange={this.changeSummary.bind(this, 'adname')} className={`${styles['address']} tl`} />
                  </div>
                  <div className='row-l margin-t20'>
                    <div className={`${styles['property']} row-r`}>
                      <div className='color2'>*</div>
                      <div>手机账号：</div>
                    </div>
                    <Input value={newMaster.uid} onChange={this.changeSummary.bind(this, 'aduid')} className={`${styles['input']} tl`} />
                  </div>
                  <div className='row-l margin-t20'>
                    <div className={`${styles['property']} tr`}>负责人：</div>
                    <Input disabled className='w126' value={newMaster.mname} />
                    <Button type='primary' className='margin-l10' onClick={this.openModal.bind(this, '客服')}>选择</Button>
                    <div className={`${styles['property']} tr`}>负责人2：</div>
                    <Select value={newMaster.manager2 ? newMaster.manager2 : null} onChange={this.changeSummary.bind(this, 'admanager2')} className='w300'>
                      {
                        waiters.map((item, index) => {
                          return (
                            <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                          );
                        })
                      }
                    </Select>

                  </div>
                  <div className='row-l margin-t20'>
                    <div className={`${styles['property']} tr`}>身份证号：</div>
                    <Input value={newMaster.id_number} onChange={this.changeSummary.bind(this, 'adid_number')} className={`${styles['input']} tl`} />
                    <div className={`${styles['property']} tr`}>备用电话：</div>
                    <Input value={newMaster.an_phone} onChange={this.changeSummary.bind(this, 'adan_phone')} className={`${styles['input']} tl`} />
                  </div>
                  <div className='row-l margin-t20'>
                    <div className={`${styles['property']} tr`}>微信号码：</div>
                    <Input value={newMaster.wechat} onChange={this.changeSummary.bind(this, 'adwechat')} className={`${styles['input']} tl`} />
                    <div className={`${styles['property']} tr`}>QQ号码：</div>
                    <Input value={newMaster.qq} onChange={this.changeSummary.bind(this, 'adqq')} className={`${styles['input']} tl`} />
                  </div>
                  <div className='row-l margin-t20'>
                    <div className={`${styles['property']} tr`}>紧急联系人：</div>
                    <Input value={newMaster.emergency_contact} onChange={this.changeSummary.bind(this, 'ademergency_contact')} className={`${styles['input']} tl`} />
                    <div className={`${styles['property']} tr`}>紧急电话：</div>
                    <Input value={newMaster.emergency_phone} onChange={this.changeSummary.bind(this, 'ademergency_phone')} className={`${styles['input']} tl`} />
                  </div>
                  <div className='row-l margin-t20'>
                    <div className={`${styles['property']} tr`}>当前住址：</div>
                    <div className={`${styles['address']} tl row-sb`}>
                      <div className={`${styles['address-detail']} oneline`}>{newMaster.current_address}</div>
                      <div onClick={this.showProvincesModal} className='padding-a510 cursor-pointer'><HomeTwoTone /></div>
                    </div>
                  </div>
                  <div className='row-l  margin-t20'>
                    <div className={`${styles['property']} tr`}>服务类型：</div>
                    <div className={`${styles['service-box']} row-w padding10`}>
                      <Checkbox.Group options={serviceList} value={newMaster.work_type} onChange={this.changeSummary.bind(this, 'adservice')} />
                    </div>
                  </div>
                  <div className='row-l vertical-lt margin-t20'>
                    <div className='w70 tc margin-l10 margin-r5'>区域合作计划备注：</div>
                    {/* <TextArea value={newMaster.remark} onChange={this.changeSummary.bind(this, 'adremark')} className={`${styles['remark']} tl`} /> */}
                    <div className='col-t vertical-lt '>
                      <div className='row-l margin-b5'>
                        <div className='w120 row-l'>
                          <div className='fz18 margin-b5'>①</div>
                          <div>第一合作师傅：</div>
                        </div>
                        <Input value={newMaster.remark[0]} onChange={this.changeReAdd.bind(this, 'remark', '0')} className={`${styles['input']} tl`} />
                      </div>
                      <div className='row-l margin-b5'>
                        <div className='w120 row-l'>
                          <div className='fz18 margin-b5 opacity0 none-select'>①</div>
                          <div>第二合作师傅：</div>
                        </div>
                        <Input value={newMaster.remark[1]} onChange={this.changeReAdd.bind(this, 'remark', '1')} className={`${styles['input']} tl`} />
                      </div>
                      <div className='row-l margin-b5'>
                        <div className='w120 row-l'>
                          <div className='fz18 margin-b5 opacity0 none-select'>①</div>
                          <div className='opacity0 none-select'>第二</div>
                          <div>备用师傅：</div>
                        </div>
                        <Input value={newMaster.remark[2]} onChange={this.changeReAdd.bind(this, 'remark', '2')} className={`${styles['input']} tl`} />
                      </div>
                      <div className='row-l margin-b5'>
                        <div className='w120 row-l'>
                          <div className='fz18 margin-b5'>②</div>
                          <div>师傅服务区域：</div>
                        </div>
                        <Input value={newMaster.remark[3]} onChange={this.changeReAdd.bind(this, 'remark', '3')} className={`${styles['input']} tl`} />
                      </div>
                      <div className='row-l margin-b5'>
                        <div className='w120 row-l'>
                          <div className='fz18 margin-b5'>③</div>
                          <div>师傅费用情况：</div>
                        </div>
                        <Input value={newMaster.remark[4]} onChange={this.changeReAdd.bind(this, 'remark', '4')} className={`${styles['input']} tl`} />
                      </div>
                      <div className='row-l margin-b5'>
                        <div className='w120 row-l'>
                          <div className='fz18 margin-b5'>④</div>
                          <div>师傅安装手艺 / </div>
                        </div>
                        测量数据是否清晰：
                        <Select value={newMaster.remark[5] ? newMaster.remark[5] : ''}
                          onChange={this.changeReAdd.bind(this, 'remark', '5')} className='w90'>
                          <Select.Option value=''>未选择</Select.Option>
                          <Select.Option value='是'>是</Select.Option>
                          <Select.Option value='否'>否</Select.Option>
                        </Select>
                      </div>
                      <div className='row-l vertical-lt margin-b5'>
                        <div className='w120 row-l'>
                          <div className='fz18 margin-b5'>⑤</div>
                          <div>其他备注：</div>
                        </div>
                        <Input.TextArea value={newMaster.remark[6]} onChange={this.changeReAdd.bind(this, 'remark', '6')}
                          rows={5} className='w550' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-t vertical-lt'>
                  <div className='row-l margin-t25'>
                    <div className={`${styles['property']} tr color6`}>账号状态：</div>
                    <Radio.Group onChange={this.changeSummary.bind(this, 'adenable')} value={newMaster.enable === '是' ? '启用' : '禁用'} size='large'>
                      {
                        enableList.map((item, index) => {
                          return <Radio value={item} key={index} checked={newMaster.enable === '是' ? true : false} className='fz16'>{item}</Radio>;
                        })
                      }
                    </Radio.Group>
                  </div>
                  <div className='row-l margin-t30'>
                    <div className={`${styles['property']} tr color6`}>认证状态：</div>
                    <Radio.Group onChange={this.changeSummary.bind(this, 'adchecked')} value={newMaster.checked === '是' ? '已认证' : '未认证'} size='large'>
                      {
                        checkList.map((item, index) => {
                          return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                        })
                      }
                    </Radio.Group>
                  </div>
                  <div className='row-l margin-t10'>
                    <div className={`${styles['property']} tr color6`}>接单状态：</div>
                    <Radio.Group onChange={this.changeSummary.bind(this, 'adorder_status')} value={newMaster.order_status === '接单中' ? '接单中' : '不接单'} size='large'>
                      {
                        statusList.map((item, index) => {
                          return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                        })
                      }
                    </Radio.Group>
                  </div>
                  <div className='row-l margin-t20'>
                    <div className={`${styles['property']} tr color6`}>是否签约：</div>
                    <Radio.Group onChange={this.changeSummary.bind(this, 'adsigned')} value={newMaster.signed} size='large'>
                      {
                        signList2.map((item, index) => {
                          return <Radio value={item.value} key={index} className='fz16'>{item.title}</Radio>;
                        })
                      }
                    </Radio.Group>
                  </div>
                  <div className='row-l margin-t20'>
                    <div className={`${styles['property']} tr color6`}>服务分类：</div>
                    <Radio.Group onChange={this.changeSummary.bind(this, 'adremote')} value={newMaster.remote} size='large'>
                      {
                        ['一类', '二类'].map((item, index) => {
                          return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                        })
                      }
                    </Radio.Group>
                  </div>
                  <div className={`${styles['disable']} row-l margin-t20 back-color2`}>
                    <div className={`${styles['property']} tr`}>质保金额：</div>
                    <InputNumber onChange={this.changeSummary.bind(this, 'addeposit')} min={0} value={newMaster.deposit} className={`${styles['bail']} tl padding-l20 back-color10`} />
                    <div className='margin-l10'>元</div>
                  </div>
                  <div className='row-l margin-t20'>
                    <div className={`${styles['property']} tr col-r`}>
                      <div>身份证：</div>
                      <div className='color5'>（人像面）</div>
                    </div>
                    <UploadPic
                      oss={myOss.host}
                      src={newMaster.id_card_pic && newMaster.id_card_pic[0] ? newMaster.id_card_pic[0] : ''}
                      upLoad={this.upLoadIdPicNew.bind(this, 0)}
                      deletePic={this.delIdPicNew.bind(this, 0)}
                      size={'small'}
                      className={styles['box']}
                    />
                    <div className={`${styles['property']} tr col-r`}>
                      <div>身份证：</div>
                      <div className='color5'>（国徽面）</div>
                    </div>
                    <UploadPic
                      oss={myOss.host}
                      src={newMaster.id_card_pic && newMaster.id_card_pic[1] ? newMaster.id_card_pic[1] : ''}
                      upLoad={this.upLoadIdPicNew.bind(this, 1)}
                      deletePic={this.delIdPicNew.bind(this, 1)}
                      size={'small'}
                      className={styles['box']}
                    />
                  </div>
                  {/* <div className='margin-t70'> */}
                  {/* <div className='w70 tc margin-l10 margin-r5'>师傅个人特殊备注：</div> */}
                  <div className='col-t vertical-lt margin-t20'>
                    <div>师傅特殊备注：</div>
                    <div className='row-l margin-b5 margin-l20'>
                      <div className='w140 row-l'>
                        <div className='fz18 margin-b5'>①</div>
                        <div>维护师傅来源：</div>
                      </div>
                      <Input value={newMaster.remarkt[0]} onChange={this.changeReAdd.bind(this, 'remarkt', '0')} className={`${styles['input']} tl`} />
                    </div>
                    <div className='row-l margin-b5 margin-l20'>
                      <div className='w140 row-l'>
                        <div className='fz18 margin-b5'>②</div>
                        <div>是否是窗帘店：</div>
                      </div>
                      <Select value={newMaster.remarkt[1] ? newMaster.remarkt[1] : ''}
                        onChange={this.changeReAdd.bind(this, 'remarkt', '1')} className='w90'>
                        <Select.Option value=''>未选择</Select.Option>
                        <Select.Option value='是'>是</Select.Option>
                        <Select.Option value='否'>否</Select.Option>
                      </Select>
                    </div>
                    <div className='row-l margin-b5 margin-l20'>
                      <div className='w140 row-l'>
                        <div className='fz18 margin-b5'>③</div>
                        <div>是否是团队师傅：</div>
                      </div>
                      <Select value={newMaster.remarkt[2] ? newMaster.remarkt[2] : ''}
                        onChange={this.changeReAdd.bind(this, 'remarkt', '2')} className='w90'>
                        <Select.Option value=''>未选择</Select.Option>
                        <Select.Option value='是'>是</Select.Option>
                        <Select.Option value='否'>否</Select.Option>
                      </Select>
                    </div>
                    <div className='row-l margin-b5 margin-l20'>
                      <div className='w140 row-l'>
                        <div className='fz18 margin-b5'>④</div>
                        <div>与那些平台合作：</div>
                      </div>
                      <Input value={newMaster.remarkt[3]} onChange={this.changeReAdd.bind(this, 'remarkt', '3')} className={`${styles['input']} tl`} />
                    </div>
                    <div className='row-l margin-b5 margin-l20'>
                      <div className=' row-l'>
                        <div className='fz18 margin-b5'>⑤</div>
                        <div>在会安装的产品后填写安装费用：</div>
                      </div>
                    </div>
                    <div className='row-l margin-b5 margin-l20'>
                      <div className='w140 row-l'>
                        <div className='fz18 margin-b5 opacity0'>④</div>
                        <div>窗帘盒费用：</div>
                      </div>
                      <Input value={newMaster.remarkt[4]} onChange={this.changeReAdd.bind(this, 'remarkt', '4')}
                        className={`${styles['input']} w120 tl`} />
                    </div>
                    <div className='row-l margin-b5 margin-l20'>
                      <div className='w140 row-l'>
                        <div className='fz18 margin-b5 opacity0'>④</div>
                        <div>电动直轨费用：</div>
                      </div>
                      <Input value={newMaster.remarkt[5]} onChange={this.changeReAdd.bind(this, 'remarkt', '5')}
                        className={`${styles['input']} w120 tl`} />
                    </div>
                    <div className='row-l margin-b5 margin-l20'>
                      <div className='w140 row-l'>
                        <div className='fz18 margin-b5 opacity0'>④</div>
                        <div>电动弯轨费用：</div>
                      </div>
                      <Input value={newMaster.remarkt[6]} onChange={this.changeReAdd.bind(this, 'remarkt', '6')}
                        className={`${styles['input']} w120 tl`} />
                    </div>
                    <div className='row-l margin-b5 margin-l20'>
                      <div className='w140 row-l'>
                        <div className='fz18 margin-b5 opacity0'>④</div>
                        <div>手动梦幻帘费用：</div>
                      </div>
                      <Input value={newMaster.remarkt[7]} onChange={this.changeReAdd.bind(this, 'remarkt', '7')}
                        className={`${styles['input']} w120 tl`} />
                    </div>
                    <div className='row-l margin-b5 margin-l20'>
                      <div className='w140 row-l'>
                        <div className='fz18 margin-b5 opacity0'>④</div>
                        <div>电动梦幻帘费用：</div>
                      </div>
                      <Input value={newMaster.remarkt[8]} onChange={this.changeReAdd.bind(this, 'remarkt', '8')}
                        className={`${styles['input']} w120 tl`} />
                    </div>
                    <div className='row-l margin-b5 margin-l20'>
                      <div className='w140 row-l'>
                        <div className='fz18 margin-b5'>⑥</div>
                        <div>费用支付方式：</div>
                      </div>
                      <Select value={newMaster.remarkt[9] ? newMaster.remarkt[9] : ''}
                        onChange={this.changeReAdd.bind(this, 'remarkt', '9')} className='w90'>
                        <Select.Option value=''>未选择</Select.Option>
                        <Select.Option value='微信'>微信</Select.Option>
                        <Select.Option value='支付宝'>支付宝</Select.Option>
                      </Select>
                    </div>
                    <div className='row-l vertical-lt margin-b5 margin-l20'>
                      <div className='w140 row-l'>
                        <div className='fz18 margin-b5'>⑦</div>
                        <div>其他备注：</div>
                      </div>
                      <Input.TextArea value={newMaster.remarkt[10]} onChange={this.changeReAdd.bind(this, 'remarkt', '10')}
                        rows={5} className='w330' />
                    </div>
                    {/* <div className='w70 tc margin-l10 margin-r5'>师傅个人特殊备注：</div>
                          <TextArea value={currentMaster.remarkt} onChange={this.changeSummary.bind(this, 'remarkt')} className={`${styles['remark']} tl`} /> */}

                  </div>
                  {/* <TextArea value={newMaster.remarkt} onChange={this.changeSummary.bind(this, 'adremarkt')} className={`${styles['remark']} tl`} /> */}
                  {/* </div> */}
                </div>
              </div>
              <div className='row-l margin-t25 w100'>
                <div className={`${styles['property']} tr`}>服务区域：</div>
                <div className={`${styles['area-box']} row-w padding10 `}>
                  {newMaster.areas ?
                    newMaster.areas.map((item, index) => {
                      return (
                        <div className={`${styles['circle']} col margin-r10 margin-b10 position`} key={index}>
                          <div className={`${styles['area']} row-l `}>
                            <Image src={locate} className={`${styles['img-locate']} margin-l5 margin-r5`} preview={false} />
                            <div className='col-l'>
                              <div>{item.district}</div>
                              <div>{item.province + item.city}</div>
                            </div>
                          </div>
                          <Image onClick={this.delAreaad.bind(this, index)} src={del} className={styles['img-delarea']} preview={false} />
                          <Select onSelect={this.changeSummary.bind(this, 'adselect')} value={'当前服务等级：' + item.rank} className={`${styles['class-line']} w100`} bordered={false}>
                            {
                              classList.map((item, indexs) => {
                                return (
                                  <Option key={indexs} value={item + index}>{item}</Option>
                                );
                              })
                            }
                          </Select>
                        </div>
                      );
                    }) : ''
                  }
                </div>
                <div className='col-t margin-l10'>
                  <div onClick={this.showAreaModal} className={`${styles['btn-service']} tc color0 back-color5 margin-b20`}>新增服务区域</div>
                  <div onClick={this.emptyArea} className={`${styles['btn-service']} tc color0 back-color8`}>清空服务区域</div>
                </div>
              </div>
              <div className='row-l  margin-t20'>
                <div className={`${styles['property']} tr`}>师傅标签：</div>
                <div className={`${styles['service-box']} row-w padding10 w850`}>
                  <Checkbox.Group options={labelList} value={newMaster.labels} onChange={this.changeSummary.bind(this, 'adlabels')} />
                </div>
              </div>
            </div>
        }
        <TransferInfoModal
          ref={this.transferInfoModal}
          update={this.getTransfer}
        />
        <Modal
          title='现居地址'
          visible={provincesModal}
          width={700}
          onCancel={this.hideProvincesModal}
          okText='确认'
          cancelText='取消'
          onOk={this.setTitude}
        >
          <div className='row-l margin-t20'>
            <div className={`${styles['property']} tr`}>现居地址：</div>
            <Regin value={provinces} onChange={this.changeProvince.bind(this, 'provinces')}></Regin>
          </div>
          <div className='row-l margin-t20'>
            <div className={`${styles['property']} tr`}>详细地址：</div>
            <Input value={address} onChange={this.changeProvince.bind(this, 'address')} className={`${styles['address']} tl`} />
          </div>
        </Modal>
        <Modal
          title='新增服务区域'
          visible={areaModal}
          width={700}
          onCancel={this.hideAreaModal}
          okText='确认'
          cancelText='取消'
          onOk={this.addArea}
        // footer={[
        //   <Button onClick={this.hideAreaModal} className={`${styles['btn-modal']} color6 fz17 margin-r20`} key='return'>取消</Button>,
        //   <Button onClick={this.addArea} className={`${styles['btn-modal']} back-color9 color0 fz17 margin-r40`} key='save'>确认新增</Button>,
        // ]}
        >
          <div className='col-t vertical-lt'>
            <div className='row-l'>
              <div className={`${styles['property']} tr`}>区域选择：</div>
              <Regin value={provinces} onChange={this.changeSummary.bind(this, 'provinces')}></Regin>
            </div>
            <div className='row-l margin-t25'>
              <div className={`${styles['property']} tr`}>服务等级：</div>
              <Select onSelect={this.changeSummary.bind(this, 'rank')} value={rank} className={styles['select-modal']}>
                {
                  classList.map((item, index) => {
                    return (
                      <Option key={index} value={item}>{item}</Option>
                    );
                  })
                }
              </Select>
            </div>
          </div>
        </Modal>
        <Modal
          title='调整修正'
          visible={balanceModal}
          width={500}
          onCancel={this.hideBalanceModal}
          footer={
            <div className='w100 row-r'>
              <div onClick={this.hideBalanceModal} className={`${styles['btn-modal']} color6 fz17 margin-r20`} key='return'>取消</div>
              <div onClick={this.editBalance} className={`${styles['btn-modal']} back-color9 color0 fz17 margin-r40`} key='save'>确认调整</div>

            </div>
          }
        >
          <div className='row-l'>
            <div className={`${styles['property']} tr`}>订单编号：</div>
            <InputNumber onChange={this.changeSummary.bind(this, 'balanceOrderid')} value={balanceAdjust.orderid} className={`${styles['input']} tl`} placeholder='请输入订单编号' />
          </div>
          <div className='row-l margin-t20'>
            <div className={`${styles['property']} tr`}>调整金额：</div>
            <InputNumber onChange={this.changeSummary.bind(this, 'balancePrice')} value={balanceAdjust.price} className={`${styles['input']} tl`} placeholder='请输入调整金额' />
          </div>
          <div className='row-l margin-t20'>
            <div className={`${styles['property']} tr`}>处理备注：</div>
            <TextArea onChange={this.changeSummary.bind(this, 'balanceRemark')} value={balanceAdjust.remark} className={`${styles['input']} tl`} placeholder='备注说明' />
          </div>
        </Modal>
        <Modal
          title='新增提现'
          visible={withdrawModal}
          width={500}
          onCancel={this.hideWithdrawModal}
          footer={
            <div className='w100 row-r'>
              <div onClick={this.hideWithdrawModal} className={`${styles['btn-modal']} color6 fz17 margin-r20`} key='return'>取消</div>
              <div onClick={this.addWithdrawal} className={`${styles['btn-modal']} back-color9 color0 fz17 margin-r40`} key='save'>确认申请</div>
            </div>
          }
        >
          <div className='row-l'>
            <div className={`${styles['property']} tr`}>提现金额：</div>
            <InputNumber onChange={this.changeSummary.bind(this, 'withDrawPrice')} value={withDrawAdjust.price} min={0} className={`${styles['input']} tl`} placeholder='请输入调整金额' />
          </div>
          <div className='row-l margin-t20'>
            <div className={`${styles['property']} tr`}>提现说明：</div>
            <TextArea onChange={this.changeSummary.bind(this, 'withDrawRemark')} value={withDrawAdjust.remark} className={`${styles['input']} tl`} placeholder='提现说明' />
          </div>
        </Modal>
        <Modal
          title='新增记录'
          visible={followModal}
          width={500}
          onCancel={this.hideFollowModal}
          footer={
            <div className='w100 row-r'>
              <div onClick={this.hideFollowModal} className={`${styles['btn-modal']} color6 fz17 margin-r20`} key='return'>取消</div>
              <div onClick={this.addFollow} className={`${styles['btn-modal']} back-color9 color0 fz17 margin-r40`} key='save'>确认提交</div>
            </div>
          }
        >
          <div className='row-l vertical-lt margin-t20'>
            <div className={`${styles['property']} tr`}>说明：</div>
            <TextArea onChange={this.changeSummary.bind(this, 'followAjust')} value={followAjust} className={`${styles['input']} tl`} placeholder='培训说明' />
          </div>
        </Modal>
        <Modal
          title='师傅位置'
          visible={mapModal}
          width={600}
          onCancel={this.hideMpaModal}
          footer={null}
        >
          <div className={styles['map']}>
            <Map
              plugins={["ToolBar", 'Scale']}
              center={center}
              zoom={12}
            >
              <Marker label={centerName} icon={redmark} visiable={true} position={center} />
            </Map>

          </div>
        </Modal>
        <WorkerModal
          ref={this.workerModal}
          updateTable={this.updateTable}
        />
      </Drawer >
    );

  }
  getServicer = async () => {
    let res = await getServiceList('', '全部', 0, 500);
    if (res.status === 200) {
      this.setState({
        waiters: res.data
      });
    }
  }
  //换页
  pageMapOperate = ({ skip, take }) => {
    this.setState({
      skipo: skip,
    }, async () => this.getOperateList())
  }
  pageMapFollow = ({ skip, take }) => {
    this.setState({
      skipF: skip,
    }, async () => this.getFollow())
  }
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.searchBill())
  }
  pageMap = ({ skip, take }) => {
    this.setState({
      skips: skip,
    }, async () => this.searchWithdrawal())
  }
  getMaster = async (id) => {
    if (id) {
      let res = await masterInfo(id);
      if (res.status === 200) {
        let currentMaster = _.cloneDeep(res.data);
        currentMaster.id_card_pic = res.data.id_card_pic.length > 0 ? JSON.parse(res.data.id_card_pic) : ['', ''];
        currentMaster.work_type = res.data.work_type.length > 0 ? JSON.parse(res.data.work_type) : [];
        currentMaster.labels = res.data.labels && res.data.labels.length > 0 ? JSON.parse(res.data.labels) : [];
        try {
          currentMaster.remark = res.data.remark && JSON.parse(res.data.remark) ? JSON.parse(res.data.remark) : ['', '', '', '', '', '', ''];
          if (typeof currentMaster.remark === 'number') {
            // currentMaster.remark = ['', '', '', '', '', '', ''];
            currentMaster.remark[6] = res.data.remark;
          }
        } catch (e) {
          currentMaster.remark = ['', '', '', '', '', '', ''];
          currentMaster.remark[6] = res.data.remark;
        }
        try {
          currentMaster.remarkt = JSON.parse(res.data.remarkt) && res.data.remarkt ? JSON.parse(res.data.remarkt) : ['', '', '', '', '', '', '', '', '', '', ''];
          if (typeof currentMaster.remarkt === 'number') {
            // currentMaster.remarkt = ['', '', '', '', '', '', '', '', '', '', ''];
            currentMaster.remarkt[10] = res.data.remarkt;
          }
        } catch (e) {
          currentMaster.remarkt = ['', '', '', '', '', '', '', '', '', '', ''];
          currentMaster.remarkt[10] = res.data.remarkt;
        }
        const accounts = [...currentMaster.accounts];
        let currentAccounts = accounts.map(item => {
          if (item.enable === '是') {
            return { ...item };
          }
        })
        let currentAccount = currentAccounts[0] ? currentAccounts[0] : this.state.currentAccount;
        let billsearch = { ...this.state.billsearch };
        billsearch.workerid = res.data.id
        this.setState({
          currentMaster,
          currentAccount,
          billsearch,
        })
        this.getTemplate(currentMaster.scripts)
      }
    }
  }
  getMyService = async () => {
    let res = await getService();
    if (res.status === 200) {
      this.setState({
        serviceList: res.data
      })
    }
  }
  getTemplate = async (e) => {
    if (e[0]) {
      let res = await getMasterTemplate('是', 0, 10, e[0].script);
      if (res.status === 200) {
        this.setState({
          template: res.data[0]
        });
      }
    }
  }
  getMasterLabel = async () => {
    let res = await getMasterLabel();
    if (res.status === 200) {
      let labelList = res.data.map(item => {
        return item.name;
      })
      this.setState({
        labelList,
      });
    }
  }
  changeSummary = (type, e) => {
    let currentMaster = { ...this.state.currentMaster };
    let billsearch = { ...this.state.billsearch };
    let balanceAdjust = { ...this.state.balanceAdjust };
    let withDrawAdjust = { ...this.state.withDrawAdjust };
    let newMaster = { ...this.state.newMaster };
    switch (type) {
      case 'top':
        this.setState({
          navigation: title[e],
          skip: 0,
          skips: 0,
          skipF: 0,
          skipo: 0,
        });
        if (title[e] === '财务流水') {
          this.amountReady();
        } else if (title[e] === '服务记录') {
          this.getFollow();
        } else if (title[e] === '操作记录') {
          this.getOperateList();
        } else if (title[e] === '订单转化') {
          this.getTransfer();
        }
        break;
      case 'name':
      case 'id_number'://身份证号
      case 'an_phone'://电话
      case 'wechat'://微信
      case 'qq'://QQ
      case 'emergency_contact'://紧急联系人
      case 'emergency_phone'://紧急电话
      case 'address':
      case 'order_status'://接单状态
        currentMaster[type] = e.target.value;
        this.setState({
          currentMaster
        });
        break;
      case 'uid'://账号
        currentMaster[type] = e.target.value.replace(/\s/g, '');
        this.setState({
          currentMaster
        });
        break;
      case 'manager2':
        currentMaster[type] = e;
        this.setState({
          currentMaster
        });
        break;
      case 'admanager2':
        newMaster['manager2'] = e;
        this.setState({
          newMaster
        });
        break;
      case 'adname':
        newMaster.name = e.target.value;
        this.setState({
          newMaster
        });
        break;
      case 'aduid'://账号
        newMaster.uid = e.target.value.replace(/\s/g, '');
        this.setState({
          newMaster
        });
        break;
      case 'adid_number'://身份证号
        newMaster.id_number = e.target.value;
        this.setState({
          newMaster
        });
        break;
      case 'adan_phone'://电话
        newMaster.an_phone = e.target.value;
        this.setState({
          newMaster
        });
        break;
      case 'adwechat'://微信
        newMaster.wechat = e.target.value;
        this.setState({
          newMaster
        });
        break;
      case 'adqq'://QQ
        newMaster.qq = e.target.value;
        this.setState({
          newMaster
        });
        break;
      case 'ademergency_contact'://紧急联系人
        newMaster.emergency_contact = e.target.value;
        this.setState({
          newMaster
        });
        break;
      case 'ademergency_phone'://紧急电话
        newMaster.emergency_phone = e.target.value;
        this.setState({
          newMaster
        });
        break;

      case 'provinces':
        this.setState({
          provinces: e
        });
        break;

      case 'remark':
        currentMaster.remark = e.target.value;
        this.setState({
          currentMaster
        });
        break;
      case 'adremark':
        newMaster.remark = e.target.value;
        this.setState({
          newMaster
        });
        break;
      case 'remarkt':
        currentMaster.remarkt = e.target.value;
        this.setState({
          currentMaster
        });
        break;
      case 'adremarkt':
        newMaster.remarkt = e.target.value;
        this.setState({
          newMaster
        });
        break;
      case 'enable'://启用状态
        currentMaster.enable = e.target.value === '启用' ? '是' : '否';
        this.setState({
          currentMaster
        });
        break;
      case 'adenable'://启用状态
        newMaster.enable = e.target.value === '启用' ? '是' : '否';
        this.setState({
          newMaster
        });
        break;
      case 'checked'://认证状态
        currentMaster.checked = e.target.value === '已认证' ? '是' : '否';
        this.setState({
          currentMaster
        });
        break;
      case 'adchecked'://认证状态
        newMaster.checked = e.target.value === '已认证' ? '是' : '否';
        this.setState({
          newMaster
        });
        break;
      case 'service'://服务类别
        currentMaster.work_type = e;
        this.setState({
          currentMaster
        });
        break;
      case 'adservice'://服务类别
        newMaster.work_type = e;
        this.setState({
          newMaster
        });
        break;
      case 'labels'://师傅标签
        currentMaster.labels = e;
        this.setState({
          currentMaster
        });
        break;
      case 'adlabels'://师傅标签
        newMaster.labels = e;
        this.setState({
          newMaster
        });
        break;
      case 'adorder_status'://接单状态
        newMaster.order_status = e.target.value;
        this.setState({
          newMaster
        });
        break;
      case 'signed'://签约状态
        currentMaster.signed = e.target.value;
        this.setState({
          currentMaster
        });
        break;
      case 'adsigned'://签约状态
        newMaster.signed = e.target.value;
        this.setState({
          newMaster
        });
        break;
      case 'remote'://服务分类
        currentMaster.remote = e.target.value
        this.setState({
          currentMaster
        });
        break;
      case 'adremote'://服务分类
        newMaster.remote = e.target.value
        this.setState({
          newMaster
        });
        break;
      case 'deposit'://保证金
        currentMaster.deposit = e
        this.setState({
          currentMaster
        });
        break;
      case 'addeposit'://保证金
        newMaster.deposit = e
        this.setState({
          newMaster
        });
        break;
      case 'select':
        let index = e.slice(1);
        let rank = e.slice(0, 1);
        currentMaster.areas[index].rank = rank;
        this.setState({
          currentMaster
        });
        break;
      case 'adselect':
        let indexad = e.slice(1);
        let rankad = e.slice(0, 1);
        newMaster.areas[indexad].rank = rankad;
        this.setState({
          newMaster
        });
        break;
      case 'rank':
        this.setState({
          rank: e
        });
        break;
      case 'billInfo':
        billsearch.info = e.target.value;
        this.setState({
          billsearch
        });
        break;
      case 'type':
        billsearch.type = e;
        this.setState({
          billsearch
        });
        break;
      case 'timeVisible':
        billsearch.timeVisible = e.target.checked;
        this.setState({
          billsearch
        });
        break;
      case 'date':
        billsearch.start = e[0];
        billsearch.end = e[1];
        // billsearch.start = moment(e[0]).format('YYYY-MM-DD HH:mm');
        // billsearch.end = moment(e[1]).format('YYYY-MM-DD HH:mm');
        this.setState({
          billsearch
        });
        break;
      case 'emptyBillSearch':
        billsearch.info = ''
        billsearch.start = ''
        billsearch.end = ''
        this.setState({
          billsearch
        });
        break;
      case 'balancePrice':
        balanceAdjust.price = e;
        this.setState({
          balanceAdjust
        });
        break;
      case 'balanceOrderid':
        balanceAdjust.orderid = e;
        this.setState({
          balanceAdjust
        });
        break;
      case 'balanceRemark':
        balanceAdjust.remark = e.target.value;
        this.setState({
          balanceAdjust
        });
        break;
      case 'withDrawPrice':
        withDrawAdjust.price = e;
        this.setState({
          withDrawAdjust
        });
        break;
      case 'withDrawRemark':
        withDrawAdjust.remark = e.target.value;
        this.setState({
          withDrawAdjust
        });
        break;
      case 'followAjust':
        this.setState({
          followAjust: e.target.value
        });
        break;
    }
  }
  //现居地址
  changeProvince = (type, e) => {
    if (type === 'provinces') {
      this.setState({
        provinces: e,
      });
    } else {
      this.setState({
        address: e.target.value,
      });
    }
  }
  //师傅服务数据
  changeKpi = (type, e) => {
    let kpiSearch = _.cloneDeep(this.state.kpiSearch);
    kpiSearch[type] = moment(e).format('YYYY-MM-DD');
    this.setState({
      kpiSearch
    })
  }
  getWorkkpi = async () => {
    const { kpiSearch, masterId } = this.state;
    let res = await getMasterKpi(masterId, kpiSearch.start, kpiSearch.end);
    if (res.status === 200) {
      this.setState({
        kpiData: res.data
      })
    }
  }
  //修改备注
  changeRe = (type, index, e) => {
    let currentMaster = _.cloneDeep(this.state.currentMaster);
    if ((type === 'remark' && index === '5') || (type === 'remarkt' && ['1', '2', '9'].includes(index))) {
      currentMaster[type][index] = e;
    } else {
      currentMaster[type][index] = e.target.value;
    }
    this.setState({
      currentMaster
    })
  }
  //新增备注
  changeReAdd = (type, index, e) => {
    let newMaster = _.cloneDeep(this.state.newMaster);
    if ((type === 'remark' && index === '5') || (type === 'remarkt' && ['1', '2', '9'].includes(index))) {
      newMaster[type][index] = e;
    } else {
      newMaster[type][index] = e.target.value;
    }
    this.setState({
      newMaster
    })
  }
  empty = () => {
    let billsearch = { ...this.state.billsearch };
    billsearch.info = ''
    billsearch.start = ''
    billsearch.end = ''
    this.setState({
      billsearch,
      skip: 0,
    }, () => {
      this.searchBill();
    });
  }
  setTitude = async () => {
    const { provinces, address, modalType, } = this.state;
    let currentMaster = { ...this.state.currentMaster };
    let newMaster = { ...this.state.newMaster };
    let current_address = provinces[0] + provinces[1] + provinces[2] + address
    let res = await toTitude(current_address);
    if (res.status === 200) {
      let location = res.data.geocodes[0].location;
      let longitude = Number(location.slice(0, location.indexOf(',')))
      let latitude = Number(location.slice(location.indexOf(',') + 1))
      if (modalType === 'edit') {
        currentMaster.current_address = current_address
        currentMaster.longitude = longitude
        currentMaster.latitude = latitude
        this.setState({
          currentMaster,
          provincesModal: false
        })
      } else {
        newMaster.current_address = current_address
        newMaster.longitude = longitude
        newMaster.latitude = latitude
        this.setState({
          newMaster,
          provincesModal: false
        })
      }
    }
  }
  //省份证图片
  upLoadIdPic = async (index, file) => {
    let name = await ossUpload(file, null);
    let currentMaster = { ...this.state.currentMaster };
    currentMaster.id_card_pic[index] = name
    this.setState({
      currentMaster
    });
  }
  delIdPic = async (index) => {
    let currentMaster = { ...this.state.currentMaster };
    currentMaster.id_card_pic[index] = ''
    this.setState({
      currentMaster
    });
  }
  upLoadIdPicNew = async (index, file) => {
    let name = await ossUpload(file, null);
    let newMaster = { ...this.state.newMaster };
    newMaster.id_card_pic[index] = name
    this.setState({
      newMaster
    });
  }
  delIdPicNew = (index) => {
    let newMaster = { ...this.state.newMaster };
    newMaster.id_card_pic[index] = ''
    this.setState({
      newMaster
    });
  }
  //保存基本信息，服务信息
  editMyInfo = async () => {
    const { navigation } = this.state;
    let currentMaster = { ...this.state.currentMaster };
    if (currentMaster.uid === '') {
      message.error('账号未填写');
      return
    }
    if (currentMaster.name === '') {
      message.error('名称未填写');
      return
    }
    currentMaster.id_card_pic = JSON.stringify(currentMaster.id_card_pic);
    currentMaster.work_type = JSON.stringify(currentMaster.work_type);
    currentMaster.labels = JSON.stringify(currentMaster.labels);
    currentMaster.remark = JSON.stringify(currentMaster.remark);
    currentMaster.remarkt = JSON.stringify(currentMaster.remarkt);
    if (navigation === '基本信息') {
      currentMaster.phone = currentMaster.an_phone;
      let res = await editMasterInfo(currentMaster.id, currentMaster);
      if (res.status === 204) {
        this.hideDrawer();
        this.update(currentMaster);
      }
    } else if (navigation === '服务信息') {
      if (currentMaster.deposit === null) {
        message.error('数据不完整');
        return
      }
      let res = await editMasterService(currentMaster.id, currentMaster);
      if (res.status === 204) {
        this.hideDrawer();
        this.update(currentMaster);
      }
    }
  }
  //服务区域
  addArea = () => {
    const { provinces, rank, modalType } = this.state;
    let currentMaster = { ...this.state.currentMaster };
    let newMaster = { ...this.state.newMaster };
    let area = {
      id: 0,
      worker: currentMaster.id,
      province: provinces[0],
      city: provinces[1],
      district: provinces[2],
      enable: '是',
      rank: rank,
      remark: '',
    }
    let areaad = {
      id: 0,
      worker: newMaster.id,
      province: provinces[0],
      city: provinces[1],
      district: provinces[2],
      enable: '是',
      rank: rank,
      remark: '',
    }
    if (modalType === 'edit') {
      let same = currentMaster.areas.filter(item =>
        item.province === area.province && item.city === area.city && item.district === area.district
      )
      if (same.length > 0) {
        message.error('已存在该区域')
        return;
      }
      currentMaster.areas.push(area);
      this.setState({
        currentMaster
      });
    } else {
      let same = newMaster.areas.filter(item =>
        item.province === areaad.province && item.city === areaad.city && item.district === areaad.district
      )
      if (same.length > 0) {
        message.error('已存在该区域')
        return;
      }
      newMaster.areas.push(areaad);
      this.setState({
        newMaster
      });
    }
    this.hideAreaModal();
  }
  emptyArea = () => {
    let currentMaster = { ...this.state.currentMaster };
    let newMaster = { ...this.state.newMaster };
    currentMaster.areas = [];
    newMaster.areas = [];
    this.setState({
      currentMaster,
      newMaster
    })
  }
  delArea = (e) => {
    let currentMaster = { ...this.state.currentMaster };
    currentMaster.areas.splice(e, 1);
    this.setState({
      currentMaster
    })
  }
  delAreaad = (e) => {
    let newMaster = { ...this.state.newMaster };
    newMaster.areas.splice(e, 1);
    this.setState({
      newMaster
    })
  }
  //当前账户
  changeAccount = (index, type, e) => {
    let currentMaster = { ...this.state.currentMaster };
    if (type === 'type') {
      currentMaster.accounts[index][type] = e;
    } else {
      currentMaster.accounts[index][type] = e.target.value;
    }
    this.setState({
      currentMaster
    });
  }
  //当前账户图片
  upLoadAccount = async (index, file) => {
    let currentMaster = { ...this.state.currentMaster };
    let name = await ossUpload(file, null);
    currentMaster.accounts[index].pic = name;
    this.setState({
      currentMaster
    });
  }
  delAccountPic = (index) => {
    let currentMaster = { ...this.state.currentMaster };
    currentMaster.accounts[index].pic = '';
    this.setState({
      currentMaster
    });
  }
  //新建账户
  changeNewAccount = (type, e) => {
    let newAccount = { ...this.state.newAccount };
    if (type === 'type') {
      newAccount[type] = e;
    } else {
      newAccount[type] = e.target.value;
    }
    this.setState({
      newAccount
    });
  }
  //新账户图片
  upLoadNewAccount = async (file) => {
    let newAccount = { ...this.state.newAccount };
    let name = await ossUpload(file, null);
    newAccount.pic = name;
    this.setState({
      newAccount
    });
  }
  delNewAccountPic = () => {
    let newAccount = { ...this.state.newAccount };
    newAccount.pic = '';
    this.setState({
      newAccount
    });
  }

  replaceAccount = async (e) => {
    let currentMaster = { ...this.state.currentMaster };
    let account = currentMaster.accounts[e]
    if (account.type === '' || account.name === '' || account.card_number === '') {
      message.error('内容不能为空');
      return
    }
    let res = await editMasterAccount(currentMaster.id, account)
    if (res.status === 204) {
      currentMaster.accounts = [];
      currentMaster.accounts.push(account);
      this.setState({
        currentAccount: account,
        currentMaster,
      })
      this.hideDrawer();
    }
  }
  addAccount = async () => {
    let currentMaster = { ...this.state.currentMaster };
    let newAccount = { ...this.state.newAccount };
    if (newAccount.type === '' || newAccount.name === '' || newAccount.card_number === '') {
      message.error('内容不能为空');
      return
    }
    let res = await editMasterAccount(currentMaster.id, newAccount)
    if (res.status === 204) {
      currentMaster.accounts = [];
      currentMaster.accounts.push(newAccount);
      this.setState({
        currentMaster,
        currentAccount: newAccount,
        newAccount: {
          type: '',
          card_number: '',
          name: '',
          city: '',
          remark: '',
          pic: '',
        },
      })
    }
  }
  update = (e) => {
    const { updateMaster } = this.props;
    updateMaster(e)
  }

  amountReady = () => {
    const { currentMaster } = this.state;
    this.getAmount(currentMaster.id);
    this.searchBill();
    this.searchWithdrawal();
  }
  getAmount = async (e) => {
    let res = await getMasterAmount(e)
    if (res.status === 200) {
      this.setState({
        amount: res.data
      })
    }
  }
  searchBill = async () => {
    const { billsearch } = this.state;
    let workerid = billsearch.workerid;
    let info = billsearch.info;
    let type = billsearch.type;
    let start = billsearch.timeVisible ? billsearch.start ? moment(billsearch.start).format('YYYY-MM-DD') : '' : '';
    let end = billsearch.timeVisible ? billsearch.start ? moment(billsearch.end).format('YYYY-MM-DD') : '' : '';
    let skip = billsearch.skip;
    let take = billsearch.take;
    let res = await getMasterBill(workerid, info, type, start, end, this.state.skip, this.state.take);
    if (res.status === 200) {
      this.setState({
        bill: res.data
      });

    }
  }
  searchWithdrawal = async () => {
    let currentMaster = { ...this.state.currentMaster };
    let res = await getWithdrawalList(currentMaster.id, '全部', this.state.skips, this.state.take);
    if (res.status === 200) {
      this.setState({
        withdrawal: res.data
      });
    }

  }
  editBalance = async () => {
    const { balanceAdjust, currentMaster } = this.state;
    if (balanceAdjust.price === '' || balanceAdjust.remark === '') {
      message.error('数据不完整');
      return;
    }
    let res = await editMasterBalance(currentMaster.id, balanceAdjust.price, balanceAdjust.remark, balanceAdjust.orderid);
    if (res.status === 204) {
      this.hideBalanceModal()
      this.searchBill()
    }
  }
  editWithdrawal = async (index, type) => {
    let withdrawal = { ...this.state.withdrawal };
    let res = await editMasterWithdrawal(withdrawal[index].id, type, '');
    if (res.status === 204) {
      this.searchWithdrawal()
      if (this.props.updateWithdraw) {
        this.props.updateWithdraw();
      }
    }
  }
  addWithdrawal = async () => {
    let currentMaster = { ...this.state.currentMaster };
    let withDrawAdjust = { ...this.state.withDrawAdjust };
    if (withDrawAdjust.price === '' || withDrawAdjust.remark === '') {
      message.error('数据不完整');
      return;
    }
    let res = await addMasterWithdrawal(currentMaster.id, withDrawAdjust);
    if (res.status === 204) {
      this.hideWithdrawModal()
      this.searchWithdrawal()
    }
  }
  getFollow = async () => {
    const { type, currentMaster, skipF, take } = this.state;
    let res = await getMasterFollow(currentMaster.id, type, skipF, take);
    if (res.status === 200) {
      this.setState({
        follow: res.data
      })
    }
  }
  addFollow = async () => {
    const { type, followAjust, currentMaster } = this.state;
    let res = await addMasterFollow(0, currentMaster.id, type, followAjust)
    if (res.status === 200) {
      this.hideFollowModal()
      this.getFollow()
    }
  }
  changeRecord = (e) => {
    this.setState({
      type: e,
    }, () => this.getFollow());
  }
  getOperateList = async () => {
    let currentMaster = { ...this.state.currentMaster };
    let res = await getMasterOperate(currentMaster.id, this.state.skipo, this.state.take);
    if (res.status === 200) {
      this.setState({
        operate: res.data
      })
    }
  }

  addMaster = async () => {
    let newMaster = { ...this.state.newMaster };
    if (newMaster.uid === '') {
      message.error('账号未填写');
      return
    }
    if (newMaster.name === '') {
      message.error('名称未填写');
      return
    }
    newMaster.work_type = newMaster.work_type.length > 0 ? JSON.stringify(newMaster.work_type) : '';
    newMaster.id_card_pic = newMaster.id_card_pic.length > 0 ? JSON.stringify(newMaster.id_card_pic) : '';
    newMaster.labels = JSON.stringify(newMaster.labels);
    newMaster.remark = JSON.stringify(newMaster.remark);
    newMaster.remarkt = JSON.stringify(newMaster.remarkt);
    let res = await addMasterInfo(newMaster);
    if (res.status === 200) {
      this.hideDrawer()
      this.update()
    }
  }
  updateTable = (row, type) => {
    let newMaster = { ...this.state.newMaster };
    const { modalType, template } = this.state;
    if (row) {
      if (type === '客服') {
        if (modalType === 'add') {
          newMaster.mname = row.name;
          newMaster.manager = row.id;
          this.setState({
            newMaster
          });
        } else {
          let currentMaster = { ...this.state.currentMaster };
          currentMaster.manager = row.id;
          this.setState({
            currentMaster,
            manager: row.name,
          })
        }
      } else if (type === '模板') {
        if (template.id === row.id) {
          message.error('与当前价格模板相同');
          return;
        }
        this.addTemplate(row)
      }
    }
  }
  addTemplate = async (e) => {
    const { currentMaster, } = this.state;
    let res = await addMasterTemplate('师傅', e.id, currentMaster.id);
    if (res.status === 200) {
      this.setState({
        template: e,
      })
    }
  }
  delTemplate = async () => {
    const { currentMaster } = this.state;
    let res = await delPriceTemplate('师傅', currentMaster.scripts[0].id);
    if (res.status === 204) {
      this.setState({
        template: {
          name: '',
          id: 0,
        }
      });
    }
  }

  openModal = (e) => {
    this.workerModal.current.onShowModal(e);
  }
  openTemplate = () => {
    this.workerModal.current.onShowModal('模板');
  }
  showDrawer = (e) => {
    this.getMaster(e.id);
    this.setState({
      drawerShow: true,
      modalType: e.type,
      manager: e.manager,
      masterId: e.id,
    }, () => this.getWorkkpi())
  }
  hideDrawer = () => {
    const { modalType } = this.state;
    if (modalType === 'edit') {
      this.optionRef.current.returnFirst();
    }
    this.setState({
      drawerShow: false,
      navigation: '基本信息',
      newMaster: {
        id: 0,
        name: '',
        uid: '',
        id_number: '',
        an_phone: '',
        qq: '',
        wechat: '',
        emergency_contact: '',
        emergency_phone: '',
        current_address: '',
        province: '',
        city: '',
        district: '',
        address: '',
        latitude: 0,
        longitude: 0,
        remark: [],
        remarkt: [],
        enable: '是',
        checked: '否',
        order_status: '是',
        id_card_pic: [],
        deposit: 0,
        work_type: [],
        signed: '',
        manager: 0,
        mname: '',
        areas: [],
        avator: '',
        miniProgram: '',
        offiaccount: '',
        labels: [],
      },
      template: {
        name: '',
        id: 0,
      },
      currentAccount: {
        type: '',
        card_number: '',
        name: '',
        city: '',
        remark: '',
        pic: '',
      },
      newAccount: {
        type: '',
        card_number: '',
        name: '',
        city: '',
        remark: '',
        pic: '',
      },
      bill: [],
      withdrawal: [],
      transferSearch: {
        state: '已转化',
        type: '下单',
        start: moment().subtract(1, 'year'),
        end: moment(),
        skip: 0,
        take: 10,
        total: 0,
        trans: 0,
        rate: 0,
      },
    })
  }
  showProvincesModal = () => {
    this.setState({
      provincesModal: true
    })
  }
  hideProvincesModal = () => {
    this.setState({
      provincesModal: false
    })
  }
  showAreaModal = () => {
    this.setState({
      areaModal: true
    });
  }
  hideAreaModal = () => {
    this.setState({
      areaModal: false
    });
  }
  showBalanceModal = () => {
    this.setState({
      balanceModal: true
    });
  }
  hideBalanceModal = () => {
    this.setState({
      balanceModal: false
    });
  }
  showWithdrawModal = () => {
    this.setState({
      withdrawModal: true
    });
  }
  hideWithdrawModal = () => {
    this.setState({
      withdrawModal: false
    });
  }
  showFollowModal = () => {
    this.setState({
      followModal: true
    });
  }
  hideFollowModal = () => {
    this.setState({
      followModal: false
    });
  }
  showMapModal = (e) => {
    let centerName = {
      content: e.name ? e.name : '',
      direction: 'right'
    }
    let center = [e.longitude, e.latitude];
    this.setState({
      mapModal: true,
      centerName,
      center,
    });
  }
  hideMpaModal = () => {
    this.setState({
      mapModal: false
    });
  }

  //订单转化
  changeTransInfo = (type, e) => {
    let transferSearch = { ...this.state.transferSearch };
    switch (type) {
      case 'state':
      case 'type':
        transferSearch[type] = e.target.value;
        break;
      case 'start':
      case 'end':
        transferSearch[type] = e;
        break;
      default: break;
    }
    this.setState({
      transferSearch
    });
  }
  toSearchTransfer = () => {
    this.pageModalTrans.current.returnFirst();
    let transferSearch = { ...this.state.transferSearch };
    transferSearch.skip = 0;
    this.setState({
      transferSearch
    }, () => this.getTransfer());
  }
  pageMapsTransfer = ({ skip, take }) => {
    let transferSearch = { ...this.state.transferSearch };
    transferSearch.skip = skip;
    this.setState({
      transferSearch,
    }, async () => this.getTransfer())
  }
  getTransfer = async () => {
    const { currentMaster, } = this.state;
    let transferSearch = _.cloneDeep(this.state.transferSearch);
    let start = moment(transferSearch.start).format(dateFormat);
    let end = moment(transferSearch.end).format(dateFormat);
    let res = await getMasterTransfer(currentMaster.id, transferSearch.state, transferSearch.type, start, end, transferSearch.skip, transferSearch.take);
    if (res.status === 200) {
      transferSearch.total = res.data.total;
      transferSearch.trans = res.data.trans;
      if (transferSearch.total) {
        transferSearch.rate = Number(transferSearch.trans / transferSearch.total * 100).toFixed(1) + '%';
      } else {
        transferSearch.rate = 'N';
      }
      this.setState({
        transfetData: res.data.list,
        transferSearch
      })
    }
  }
  toExcelTransfer = async () => {
    const { currentMaster, } = this.state;
    let transferSearch = _.cloneDeep(this.state.transferSearch);
    let start = moment(transferSearch.start).format(dateFormat);
    let end = moment(transferSearch.end).format(dateFormat);
    let res = await getMasterTransfer(currentMaster.id, transferSearch.state, transferSearch.type, start, end, transferSearch.skip, transferSearch.take);
    if (res.status === 200) {
      let columns = [
        {
          title: '测量单编号',
          key: 'id',
        },
        {
          title: '下单时间',
          key: 'create_time',
        },
        {
          title: '客户信息',
          key: 'info',
        },
        {
          title: '转化时间',
          key: 'trans_time',
        },
        {
          title: '转化安装单编号',
          key: 'order',
        },
      ];
      let data = res.data.list.map(item => {
        return {
          id: item.id ? item.id : '',
          create_time: moment(item.create_time).format('YYYY/MM/DD-HH:mm:ss'),
          orderInfo: `${item.id}-${item.category}-${item.service}-${item.type}【${item.state}】`,
          trans_time: item.trans_time && item.trans_time !== '1970-01-01T00:00:00' ? moment(item.trans_time).format('YYYY/MM/DD-HH:mm:ss') : '',
          order: item.order ? item.order : '',
        };
      });

      jsToExcel(columns, data, `${currentMaster.name}_${start}-${end}订单转化`, '');
    }
  }
  transferShow = (e) => {
    this.transferInfoModal.current.onShow(e);
  }
}

export default Index;
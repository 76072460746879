import React, { useEffect, useState, Component } from 'react';
import styles from './modal.module.css';
import { message, Modal, Input, Radio, Upload, Button, Select, Checkbox, Drawer, DatePicker, Table, InputNumber, Popconfirm, } from 'antd';
import Options from '../../components/option';
import { DeleteOutlined, FormOutlined, PlusCircleOutlined, PlusOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import UploadPic from '../../components/UploadPic';
import Regin from '../../components/regioRegister';
import WorkerModal from '../orderCore/allOrder/workerModal';
import PageTurning from '../../components/pageTurning';
import TransferInfoModal from './transferInfoModal';
import convert from '../../utils/convert';
import { getService } from '../../services/general';
import { getServiceList } from '../../services/serviceManagement';
import { jsToExcel } from '../../utils/exportExcel';
import {
  editShop, getShopServicer, addShop, putBonus, getShopBalance, getShopBill, adjustBalance, getShopDiscount,
  getShopDiscountList, delShopDiscount, addShopDiscount, getShopFollow, addShopFollow, getShopOperate,
  getShopTransfer, putShopTransfer,
} from '../../services/shopManagement';
import { putBindShop, getShopVideos, putShopVideos, getVideoPlay, getVideoInfo, getVideoSub } from '../../services/newOder';
import { addMasterTemplate, delPriceTemplate, getMasterTemplate } from '../../services/templateManagement';
import { getOssBaseAuth } from '../../config/storage';
import { ossUpload, } from '../../utils/ossUpload.js';
import { getDepartment_Data } from '../../config/storage';
import IndexModal from '../orderCore/tableList/indexModal';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const title = ['基本信息', '好评维护', '视频模板', '商家客服', '财务流水', '优惠券', '订单转化', '跟踪回访', '操作记录'];
const enableList = ['启用', '禁用'];
const permissionList = ['显示', '不显示'];
const checkList = ['已认证', '未认证'];
const rankList = ['A', 'B', 'C', 'D'];
const costList = ['全部', '消费', '充值', '好评', '变更', '赠送', '修正', '理赔', '退款'];
const adjustList = ['修正', '充值', '赠送'];
const sendList = ['自动', '手动'];
const fixedPriceList = ['否', '是'];
const dateFormat = 'YYYY/MM/DD-HH:mm'
const dateFormat2 = 'YYYY/MM/DD'
const addNewShop = {
  id: 0,
  department: 0,
  name: '',
  contact: '',
  manager: 0,
  manager2: 0,
  mname: '',
  enable: '是',
  phone: '',
  weixin: '',
  // join_time: '',
  debt: 0,
  remark: '',
  price_remark: '',
  province: '',
  city: '',
  district: '',
  address: '',
  checked: '否',
  service_type: [],
  show_worker: '否',
  show_book: '否',
  allot_type: '自动',
  fix_price: '否',
  model: '旧版',
  sale: '否',
  urage: '否',
  pic: '',
  rank: '',
  refer_worker: 0,
  refer_name: '',
  m_remark: '',//测量备注
  i_remark: '',//安装备注
  sms: '墨墨管家',
};
const modeList = ['固定金额', '不固定金额', '不参与'];
const videoModel = {
  measure: {
    pics: [],
    vod: [],
  },
  install: {
    pics: [],
    vod: [],
  },
};


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myOss: {
        host: '',
        expiration: 0,
        key: '',
        policy: '',
        prefix: '',
        signature: '',
      },
      modalType: '',
      navigation: '基本信息',
      skip: 0,
      take: 10,
      provinces: ['全部', '全部', '全部'],
      serviceList: ['窗帘', '墙布墙纸', '晾衣架', '地板', '其他'],
      servicerList: [],
      billList: [],
      currentShop: {},
      template: {
        name: '',
        id: 0,
      },
      billSearch: {
        info: '',
        type: '全部',
        start: '',
        end: '',
        searchType: '流水',
        timeVisible: true,
      },
      adjust: {
        type: '修正',
        price: 0,
        remark: '',
        orderid: null,
      },
      discountList: [],
      discountTotal: {
        install: 0,
        measure: 0,
      },
      discount: [],
      delDiscount: [],
      currentDiscount: {},
      newShop: { ...addNewShop },
      follow: [],
      followAjust: '',
      operate: [],
      drawerShow: false,
      adjustShow: false,
      discountShow: false,
      followModal: false,
      dateKey: '',
      role: 0,
      adjustLoading: false,

      measureVideo: {
        pics: [],
        vod: [],
      },
      installVideo: {
        pics: [],
        vod: [],
      },
      transferSearch: {
        state: '已转化',
        type: '下单',
        start: moment().subtract(1, 'month'),
        end: moment(),
        skip: 0,
        take: 10,
        total: 0,
        trans: 0,
        rate: 0,
        info: '',
      },
      transfetData: [],
      waiters: [],
      smsDiy: '墨墨管家',
      // 不发送
    };
    this.workerModal = React.createRef();
    this.optionRef = React.createRef();
    this.pageModal = React.createRef();
    this.pageModalTrans = React.createRef();
    this.transferInfoModal = React.createRef();
    this.indexModal = React.createRef();
    this.servicer = getDepartment_Data().id;
    this.baseUrl = getDepartment_Data().service;
    this.columnsTransfer = [

      {
        title: '订单信息',
        align: 'center',
        width: 230,
        dataIndex: 'orderInfo',
        key: 'orderInfo',
        render: (text, record, index) => {
          return (
            // <div>{record.service}-{record.type}-{record.state}</div>
            <div>
              <div>{record.id}-{record.category}-{record.service}-{record.type}
                【{record.state}】
              </div>
              <div>下单时间：{moment(record.create_time).format('YYYY/MM/DD-HH:mm:ss')}</div>
            </div>
          );
        }
      },
      {
        title: '测量订单编号',
        align: 'center',
        width: 100,
        dataIndex: 'id',
        key: 'id',
        render: (text, record, index) => {
          return (
            <div>{record.id}</div>
          );
        }
      },
      {
        title: '客户信息',
        align: 'center',
        width: 250,
        dataIndex: 'info',
        key: 'info',
        render: (text, record, index) => {
          return (
            <div>
              <div>{record.name}，{record.phone}，
                {record.province}{record.city}{record.district},{record.address}
              </div>
            </div>
          );
        }
      },
      {
        title: '商户费用',
        align: 'center',
        width: 100,
        dataIndex: 'price_app',
        key: 'price_app',
      },
      {
        title: '订单是否已完成',
        align: 'center',
        width: 200,
        dataIndex: 'state',
        render: (text, record, index) => {
          return (
            <div>
              <div>{record.state}</div>
              <div>完成时间：{record.compete_time && record.compete_time !== '1970-01-01T00:00:00' ? moment(record.compete_time).format('YYYY/MM/DD-HH:mm:ss') : ''}</div>

            </div>

          );
        }
      },
      {
        title: '转化状态',
        align: 'center',
        width: 100,
        dataIndex: 'status',
        key: 'status',
        render: (text, record, index) => {
          return (
            <div onClick={this.transferShow.bind(this, record)} className='color3 underline cursor-pointer'>{record.order ? '是' : '否'}</div>
          );
        }
      },
      {
        title: '转化时间',
        align: 'center',
        width: 80,
        dataIndex: 'trans_time',
        key: 'trans_time',
        render: (text, record, index) => {
          return (
            <div>{record.trans_time && record.trans_time !== '1970-01-01T00:00:00' ? moment(record.trans_time).format('YYYY/MM/DD-HH:mm:ss') : ''}</div>
          );
        }
      },
      {
        title: '转化订单编号',
        align: 'center',
        width: 80,
        dataIndex: 'order',
        key: 'order',
        render: (text, record, index) => {
          return (
            <div onClick={this.onOpenModal.bind(this, record)} className='color3 underline cursor-pointer'>{record.order ? record.order : ''}</div>
          );
        }
      },
      {
        title: '说明',
        align: 'center',
        width: 220,
        dataIndex: 'bonus_remark',
        key: 'bonus_remark',
      },

    ];
  }
  componentDidMount() {
    let myOss = getOssBaseAuth();
    let { roles } = getDepartment_Data();
    this.setState({
      myOss,
      role: roles[0]
    })
    this.getServicer();
  }

  render() {
    const { drawerShow, myOss, modalType, navigation, provinces, serviceList, newShop, currentShop,
      servicerList, billList, billSearch, adjustShow, adjust, discountList, discount, delDiscount,
      discountShow, currentDiscount, follow, followModal, followAjust, operate, discountTotal, dateKey,
      template, role, adjustLoading, measureVideo, installVideo, transferSearch, transfetData, waiters, smsDiy,
    } = this.state;
    const columsServicer = [
      {
        title: '客服姓名',
        align: 'center',
        width: 150,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '账号',
        align: 'center',
        width: 150,
        dataIndex: 'uid',
        key: 'uid',
      },
      {
        title: '手机号码',
        align: 'center',
        width: 150,
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: '备注信息',
        align: 'center',
        width: 580,
        dataIndex: 'remark',
        key: 'remark',
      },
      {
        title: '启用状态',
        align: 'center',
        width: 150,
        dataIndex: 'enable',
        key: 'enable',
        render: (text, render, index) => {
          return render.enable === '是' ? '启用中' : '禁用';
        }
      },
      {
        title: '微信',
        align: 'center',
        width: 150,
        dataIndex: 'weixin',
        key: 'weixin',
      },
    ];
    const columnsBill = [
      {
        title: '流水号',
        align: 'center',
        width: 110,
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: '订单编号',
        align: 'center',
        width: 110,
        dataIndex: 'order',
        key: 'order',
      },
      {
        title: '订单类型',
        align: 'center',
        width: 110,
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: '费用类型',
        align: 'center',
        width: 110,
        dataIndex: 'service_type',
        key: 'service_type',
      },
      {
        title: '发生金额',
        align: 'center',
        width: 110,
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: '账户金额',
        align: 'center',
        width: 110,
        dataIndex: 'balance',
        key: 'balance',
      },
      {
        title: '结算时间',
        align: 'center',
        width: 150,
        dataIndex: 'check_time',
        key: 'check_time',
        render: (text, record, index) => {
          return (<div >{moment(record.check_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '消费时间',
        align: 'center',
        width: 150,
        dataIndex: 'add_time',
        key: 'add_time',
        render: (text, record, index) => {
          return (<div >{moment(record.add_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '客户信息',
        align: 'center',
        width: 220,
        dataIndex: 'order_info',
        key: 'order_info',
      },
      {
        title: '唯一订单号',
        align: 'center',
        width: 150,
        dataIndex: 'platform',
        key: 'platform',
      },
      {
        title: '备注信息',
        align: 'center',
        width: 160,
        dataIndex: 'content',
        key: 'content',
      },

    ];
    const columnsDiscount = [
      {
        title: 'ID',
        align: 'center',
        width: 200,
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: '名称',
        align: 'center',
        width: 200,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '价格',
        align: 'center',
        width: 200,
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: '类型',
        align: 'center',
        width: 200,
        dataIndex: 'service',
        key: 'service',
        filters: [
          {
            text: '测量劵',
            value: '测量',
          },
          {
            text: '安装劵',
            value: '安装',
          },
        ],
        filterMode: 'tree',
        onFilter: (value, record) => record.service.includes(value),
      },
      {
        title: '发送时间',
        align: 'center',
        width: 200,
        dataIndex: 'send_time',
        key: 'send_time',
        render: (text, record, index) => {
          return (<div >{moment(record.send_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '到期时间',
        align: 'center',
        width: 200,
        dataIndex: 'due_time',
        key: 'due_time',
        render: (text, record, index) => {
          return (<div >{moment(record.due_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 200,
        dataIndex: 'expire',
        key: 'expire',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div onClick={this.deleteSingleDiscount.bind(this, record.id)} className={`${styles['btn-finance']} back-color11 color0 fz16  cursor-pointer`}>删除</div>
            </div>
          );
        }
      },
    ];
    const columsFollow = [
      {
        title: '编号',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
      },
      {
        title: '回访客服',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
      },
      {
        title: '回访时间',
        dataIndex: 'submit_time',
        key: 'time',
        align: 'submit_time',
        render: (text, record, index) => {
          return (<div >{moment(record.submit_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '回访内容',
        dataIndex: 'content',
        key: 'content',
        align: 'center',
      },
    ];
    const columsOperate = [
      {
        title: '操作记录',
        dataIndex: 'content',
        key: 'content',
        align: 'center',
      },
      {
        title: '操作时间',
        dataIndex: 'submit_time',
        key: 'submit_time',
        align: 'center',
        render: (text, record, index) => {
          return (<div >{moment(record.submit_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '操作人',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
      },
    ];
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          delDiscount: selectedRowKeys
        })
      },
    };
    return (
      <Drawer
        title='    商户信息'
        style={{ position: 'fixed', right: 0 }}
        visible={drawerShow}
        onClose={this.hideDrawer}
        width={1300}
        footer={
          <div className='w100 row-r'>
            <div onClick={this.hideDrawer} className={`${styles['btn']} border color6 fz17 margin-r20 cursor-pointer`} key='return'>返回</div>
            {
              navigation === '跟踪回访' && modalType === 'edit' ?
                <div onClick={this.showFollowModal} className={`${styles['btn']} back-color9 color0 fz17 margin-r40 cursor-pointer`} key='follow'>新增回访</div> : ''
            }
            {
              navigation === '财务流水' && modalType === 'edit' ?
                <div onClick={this.showAdjustModal} className={`${styles['btn']} back-color9 color0 fz17 margin-r40 cursor-pointer`} key='adjust'>财务调整</div> : ''
            }
            {
              navigation === '基本信息' && modalType === 'edit' ?
                <div onClick={this.editMyShop} className={`${styles['btn']} back-color9 color0 fz17 margin-r40 cursor-pointer`} key='save'>保存</div> : ''
            }
            {
              modalType === 'add' ?
                <div onClick={this.addMyShop} className={`${styles['btn']} back-color9 color0 fz17 margin-r40 cursor-pointer`} key='save'>保存</div> : ''
            }
            {
              navigation === '视频模板' && modalType === 'edit' ?
                <div onClick={this.saveVideo} className={`${styles['btn']} back-color9 color0 fz17 margin-r40 cursor-pointer`} key='save'>保存</div> : ''
            }
            {
              navigation === '好评维护' && modalType === 'edit' ?
                <div onClick={this.saveBonus} className={`${styles['btn']} back-color9 color0 fz17 margin-r40 cursor-pointer`} key='save'>保存</div> : ''
            }
          </div>
        }
      >
        {
          modalType === 'edit' ?
            <div>
              <Options title={title} top={0} changeMyTop={this.changeSummary.bind(this, 'top')} ref={this.optionRef} style={2} number={true} />
              {
                navigation === '基本信息' ?
                  (
                    <div className='row-l vertical-lt w100' key='baseInfo'>
                      <div className='col-t vertical-lt margin-r20'>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>商户姓名：</div>
                          <Input value={currentShop.name} onChange={this.changeSummary.bind(this, 'name')} className={`${styles['address']} tl`} />
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>注册时间：</div>
                          <DatePicker locale={locale} onChange={this.changeSummary.bind(this, 'join_time')} defaultValue={currentShop.join_time} format={dateFormat} showTime className={styles['select-head']} />
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>负责人：</div>
                          <Input disabled className='w126' value={currentShop.mname} />
                          <Button disabled={role === 1} type='primary' className='margin-l10' onClick={this.openModal.bind(this, '客服')}>选择</Button>
                          <div className={`${styles['property']} tr`}>负责人2：</div>
                          <Select value={currentShop.manager2 ? currentShop.manager2 : null} onChange={this.changeSummary.bind(this, 'manager2')} className={styles['select-head']}>
                            {
                              waiters.map((item, index) => {
                                return (
                                  <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                );
                              })
                            }
                          </Select>
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>联系方式：</div>
                          <Input onChange={this.changeSummary.bind(this, 'phone')} value={currentShop.phone} className={`${styles['input']} tl`} />
                          <div className={`${styles['property']} tr`}>微信号码：</div>
                          <Input value={currentShop.weixin} onChange={this.changeSummary.bind(this, 'weixin')} className={`${styles['input']} tl`} />
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>信用额度：</div>
                          <Input disabled={role === 1} onChange={this.changeSummary.bind(this, 'debt')} value={currentShop.debt} className={`${styles['input']} tl`} />
                          <div className={`${styles['property']} tr`}>商户等级：</div>
                          <Select disabled={role === 1} onSelect={this.changeSummary.bind(this, 'rank')} value={currentShop.rank} className={styles['input']} bordered={false}>
                            {
                              rankList.map((item, indexs) => {
                                return (
                                  <Option key={indexs} value={item}>{item}</Option>
                                );
                              })
                            }
                          </Select>
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>所在地址：</div>
                          <div className={styles['provinces']} >
                            <Regin value={[currentShop.province, currentShop.city, currentShop.district]} onChange={this.changeSummary.bind(this, 'provinces')} />
                          </div>
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>详细地址：</div>
                          <Input onChange={this.changeSummary.bind(this, 'address')} value={currentShop.address} className={`${styles['address']} tl`} />
                        </div>
                        <div className='row-l  margin-t20'>
                          <div className={`${styles['property']} tr`}>服务类型：</div>
                          <div className={`${styles['service-box']} row-w padding10`}>
                            <Checkbox.Group options={serviceList} value={currentShop.service_type} onChange={this.changeSummary.bind(this, 'service_type')} />
                          </div>
                        </div>
                        {
                          ([7, 9, 11, 13].includes(role) || this.servicer === currentShop.manager) &&
                          <div className='row-l margin-t20'>
                            <div className={`${styles['property']} tr`}>合作备注：</div>
                            <TextArea onChange={this.changeSummary.bind(this, 'remark')} value={currentShop.remark} className={`${styles['remark']} tl`} />
                          </div>
                        }
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>测量备注：</div>
                          <TextArea onChange={this.changeSummary.bind(this, 'm_remark')} value={currentShop.m_remark} className={`${styles['remark']} tl`} />
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>安装备注：</div>
                          <TextArea onChange={this.changeSummary.bind(this, 'i_remark')} value={currentShop.i_remark} className={`${styles['remark']} tl`} />
                        </div>
                        {/* <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>费用备注：</div>
                          <TextArea onChange={this.changeSummary.bind(this, 'price_remark')} value={currentShop.price_remark} className={`${styles['remark']} tl`} />
                        </div> */}
                      </div>
                      <div className='col-t vertical-lt'>
                        <div className='row-l margin-t25'>
                          <div className={`${styles['property']} tr color6`}>账号状态：</div>
                          <Radio.Group onChange={this.changeSummary.bind(this, 'enable')} value={currentShop.enable === '是' ? '启用' : '禁用'} size='large'>
                            {
                              enableList.map((item, index) => {
                                return <Radio value={item} key={index} checked={currentShop.enable === '是' ? true : false} className='fz16'>{item}</Radio>;
                              })
                            }
                          </Radio.Group>
                        </div>
                        <div className='row-l margin-t25'>
                          <div className={`${styles['property']} tr color6`}>销售单支持：</div>
                          <Radio.Group onChange={this.changeSummary.bind(this, 'sale')} value={currentShop.sale === '是' ? '启用' : '禁用'} size='large'>
                            {
                              enableList.map((item, index) => {
                                return <Radio value={item} key={index} checked={currentShop.sale === '是' ? true : false} className='fz16'>{item}</Radio>;
                              })
                            }
                          </Radio.Group>
                          {
                            currentShop.sale === '是' &&
                            <div className='row'>
                              <div className={currentShop.lj_shop > 0 ? 'color2' : ''}>{currentShop.lj_shop > 0 ? '已绑定' : '未绑定'}杆轨店铺</div>
                              <Button disabled={currentShop.lj_shop > 0} onClick={this.bindShop} type='primary' className='margin-l10'>去绑定</Button>
                            </div>
                          }
                        </div>
                        <div className='row-l margin-t25'>
                          <div className={`${styles['property']} tr color6`}>师傅电话：</div>
                          <Radio.Group onChange={this.changeSummary.bind(this, 'show_worker')} value={currentShop.show_worker === '是' ? '显示' : '不显示'} size='large'>
                            {
                              permissionList.map((item, index) => {
                                return <Radio value={item} key={index} checked={currentShop.show_worker === '是' ? true : false} className='fz16'>{item}</Radio>;
                              })
                            }
                          </Radio.Group>
                        </div>
                        <div className='row-l margin-t25'>
                          <div className={`${styles['property']} tr color6`}>订单预约：</div>
                          <Radio.Group onChange={this.changeSummary.bind(this, 'show_book')} value={currentShop.show_book === '是' ? '启用' : '禁用'} size='large'>
                            {
                              enableList.map((item, index) => {
                                return <Radio value={item} key={index} checked={currentShop.show_book === '是' ? true : false} className='fz16'>{item}</Radio>;
                              })
                            }
                          </Radio.Group>
                        </div>
                        <div className='row-l margin-t30'>
                          <div className={`${styles['property']} tr color6`}>认证状态：</div>
                          <Radio.Group onChange={this.changeSummary.bind(this, 'checked')} value={currentShop.checked === '是' ? '已认证' : '未认证'} size='large'>
                            {
                              checkList.map((item, index) => {
                                return <Radio value={item} key={index} checked={currentShop.checked === '是' ? true : false} className='fz16'>{item}</Radio>;
                              })
                            }
                          </Radio.Group>
                        </div>
                        <div className='row-l margin-t30'>
                          <div className={`${styles['property']} tr color6`}>派单方式：</div>
                          <Radio.Group onChange={this.changeSummary.bind(this, 'send')} value={currentShop.allot_type} size='large'>
                            {
                              sendList.map((item, index) => {
                                return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                              })
                            }
                          </Radio.Group>
                        </div>
                        <div className='row-l margin-t30'>
                          <div className={`${styles['property']} tr color6`}>一口价：</div>
                          <Radio.Group disabled={role === 1} onChange={this.changeSummary.bind(this, 'fix_price')} value={currentShop.fix_price} size='large'>
                            {
                              fixedPriceList.map((item, index) => {
                                return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                              })
                            }
                          </Radio.Group>
                        </div>
                        <div className='row-l margin-t30'>
                          <div className={`${styles['property']} tr color6`}>自动催单：</div>
                          <Radio.Group disabled={role === 1} onChange={this.changeSummary.bind(this, 'urage')} value={currentShop.urage} size='large'>
                            {
                              fixedPriceList.map((item, index) => {
                                return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                              })
                            }
                          </Radio.Group>
                        </div>
                        <div className='row-l margin-t30'>
                          <div className='tr color6'>测量数据模板：</div>
                          <Radio.Group disabled={role === 1} onChange={this.changeSummary.bind(this, 'model')} value={currentShop.model} size='large'>
                            {
                              ['旧版', '新版', '三千金'].map((item, index) => {
                                return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                              })
                            }
                          </Radio.Group>
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>短信模板：</div>
                          <Radio checked={currentShop.sms === '不发送'} value={'不发送'} onChange={this.changeSms}>不发送</Radio>
                          <Radio checked={currentShop.sms === '自定义'} value={'自定义'} onChange={this.changeSms}>自定义</Radio>
                          {
                            currentShop.sms === '自定义' &&
                            <Input value={smsDiy} onChange={this.changeSmsDiy} className='w150' />
                          }
                        </div>
                        <div className='row-l margin-t30'>
                          <div className={`${styles['property']} tr col-r`}>
                            <div>营业执照：</div>
                            <div className='color5'>（纸质版）</div>
                          </div>
                          <UploadPic
                            oss={myOss.host}
                            src={currentShop.pic ? currentShop.pic : ''}
                            upLoad={this.upLoadIdPic}
                            size={'small'}
                            className={styles['box']}
                          />
                        </div>
                        <div className='row-l margin-t30'>
                          <div className={`${styles['property']} tr`}>价格模板：</div>
                          <Input disabled={true} className={styles['select']} value={template.name} />
                          {
                            role === 1 ?
                              <div></div>
                              :
                              template.name ?
                                <div onClick={this.delTemplate} className={`${styles['btn-price']} back-color11 color0 tc margin-l25 fz16 cursor-pointer`}>删除价格模板</div>
                                : <div onClick={this.openTemplate} className={`${styles['btn-price']} back-color5 color0 tc margin-l25 fz16 cursor-pointer`}>选择价格模板</div>
                          }
                        </div>
                        <div className='row-l margin-t20'>
                          <div className={`${styles['property']} tr`}>费用备注：</div>
                          <TextArea onChange={this.changeSummary.bind(this, 'price_remark')} value={currentShop.price_remark} className={`${styles['remark']} tl`} />
                        </div>

                        {/* <div className={`${styles['attention']} col-t margin-t30 w100 back-color2 padding20`}> */}
                        {/* <div className='tl w100 color2'>注意事项：</div> */}
                        {/* <div className='tl'>我是说明文本，我是说明文本，我是说明文本，我是说明文本，我是说明文本，
                            我是说明文本，我是说明文本，我是说明文本，我是说明文本，我是说明文本，
                            我是说明文本，我是说明文本，我是说明文本，我是说明文本，我是说明文本，
                            我是说明文本，我是说明文本，我是说明文本，我是说明文本，我是说明文本，
                            我是说明文本。
                          </div> */}
                        {/* </div> */}
                      </div>
                    </div>
                  ) : ''
              }
              {
                navigation === '好评维护' &&
                <div className='col-t vertical-lt w100 margin-t20' key='bonus'>
                  <div className='row-l w100'>
                    <div className={`${styles['property']} tr`}>好评模式：</div>
                    <Radio.Group value={currentShop.bonus_type} onChange={this.changeSummary.bind(this, 'bonus_type')}>
                      {
                        modeList.map((mitem, mindex) => {
                          return (
                            <Radio value={mitem} key={mindex}>{mitem}</Radio>
                          );
                        })
                      }
                    </Radio.Group>
                  </div>
                  <div className='row-l w100 margin-t20'>
                    <div className={`${styles['property']} tr`}>好评要求：</div>
                    <Input.TextArea value={currentShop.bonus_remark} onChange={this.changeSummary.bind(this, 'bonus_remark')}
                      className='w500' rows={3} />
                  </div>
                  {
                    currentShop.bonus_type === '固定金额' &&
                    <div className='col-t'>
                      <div className='margin-t20 fz18'>好评金额</div>
                      <div className='row-l'>
                        <div className={`${styles['property']} tr`}>商家金额：</div>
                        <InputNumber value={currentShop.price_shop} className='w150' onChange={this.changeSummary.bind(this, 'price_shop')} />
                        <div className={`${styles['property']} tr`}>师傅金额：</div>
                        <InputNumber value={currentShop.price_worker} className='w150' onChange={this.changeSummary.bind(this, 'price_worker')} />
                      </div>
                    </div>
                  }
                </div>
              }
              {
                navigation === '视频模板' ?
                  (
                    <div className='col-t vertical-lt w100' key='videoTemplate'>
                      <div className='row-l margin-t10 margin-b5'>
                        <div className='fz18 bold margin-r20'>测量图片：</div>
                        <Upload
                          type='file'
                          beforeUpload={this.onAddPic}
                          fileList={[]}
                          accept='.jpg,.png,.jpeg,.icon,.svg,.gif'
                          // accept='image/*'
                          multiple={true}
                        >
                          <Button type='primary'>新增</Button>
                        </Upload>
                      </div>
                      <div className='row-w w100 margin-l10 '>
                        {
                          measureVideo.pics.map((item, index) => {
                            return (
                              <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                                <div className='row-sb margin-b5'>
                                  <div className='bold'>名称：</div>
                                  <Input value={item.name} className='w160' onChange={this.changeTempete.bind(this, index, 'pics', 'name')} />
                                </div>
                                <div className='row-l'>
                                  <div key={index} className={`${styles['content']}`}>
                                    <img
                                      alt="暂无图片"
                                      className={`${styles['pic']}`}
                                      src={`${this.baseUrl}/upload/${item.url}`}
                                    />
                                    <div className={`row ${styles['content-div']} cursor-pointer`}>
                                      <EyeOutlined
                                        className={`${styles['pic-icon-E']}`}
                                        onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item.url}`)}
                                        theme="outlined"
                                        title="预览"
                                      />
                                    </div>
                                  </div>
                                  <div className='col'>
                                    <Popconfirm
                                      title="您确定要删除该图片模板吗?"
                                      onConfirm={this.delVideo.bind(this, index, 'pics')}
                                      okText="确认"
                                      cancelText="取消"
                                    >
                                      <Button type='danger' className='margin-b10'>删除模板</Button>
                                    </Popconfirm>
                                    <Checkbox checked={item.selected} onChange={this.changeTempete.bind(this, index, 'pics', 'selected')}>默认</Checkbox>

                                  </div>
                                </div>

                              </div>
                            );
                          })
                        }

                      </div>
                      <div className='margin-t10'>
                        <div className='row-l margin-t10 margin-b5'>
                          <div className='fz18 bold margin-r20'>测量视频：</div>
                          <Upload
                            type='file'
                            beforeUpload={this.uploadVideo}
                            customRequest={() => { }}
                            fileList={[]}
                            multiple={false}
                            maxCount={1}

                          >
                            <Button type='primary'>新增测量视频</Button>
                          </Upload>
                        </div>
                      </div>
                      <div className='row-w margin-t10'>
                        {
                          measureVideo.vod.map((item, index) => {
                            return (
                              <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                                <div className='row-sb margin-b5'>
                                  <div className='bold'>名称：</div>
                                  <Input value={item.name} className='w200' onChange={this.changeTempete.bind(this, index, 'vod', 'name')} />
                                </div>
                                <div className={`${styles['content-video']}`}>
                                  <video controls='controls' className={styles['content-image']} src={item.videoUrl} />
                                </div>
                                <div className='row w100 margin-t5'>
                                  <Popconfirm
                                    title="您确定要删除该视频模板吗?"
                                    onConfirm={this.delVideo.bind(this, index, 'vod')}
                                    okText="确认"
                                    cancelText="取消"
                                  >
                                    <Button type='danger' className='w50 margin-r20'>删除模板</Button>
                                  </Popconfirm>
                                  <Checkbox checked={item.selected} onChange={this.changeTempete.bind(this, index, 'vod', 'selected')}>默认</Checkbox>

                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                      <div className='row-l margin-t10 margin-b5'>
                        <div className='fz18 bold margin-r20'>安装图片：</div>
                        <Upload
                          type='file'
                          beforeUpload={this.onAddPic2}
                          fileList={[]}
                          accept='.jpg,.png,.jpeg,.icon,.svg,.gif'
                          // accept='image/*'
                          multiple={true}
                        >
                          <Button type='primary'>新增</Button>
                        </Upload>
                      </div>
                      <div className='row-w w100 margin-l10 '>
                        {
                          installVideo.pics.map((item, index) => {
                            return (
                              <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                                <div className='row-sb margin-b5'>
                                  <div className='bold'>名称：</div>
                                  <Input value={item.name} className='w160' onChange={this.changeTempete2.bind(this, index, 'pics', 'name')} />
                                </div>
                                <div className='row-l'>
                                  <div key={index} className={`${styles['content']}`}>
                                    <img
                                      alt="暂无图片"
                                      className={`${styles['pic']}`}
                                      src={`${this.baseUrl}/upload/${item.url}`}
                                    />
                                    <div className={`row ${styles['content-div']} cursor-pointer`}>
                                      <EyeOutlined
                                        className={`${styles['pic-icon-E']}`}
                                        onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item.url}`)}
                                        theme="outlined"
                                        title="预览"
                                      />
                                    </div>
                                  </div>
                                  <div className='col'>
                                    <Popconfirm
                                      title="您确定要删除该图片模板吗?"
                                      onConfirm={this.delVideo2.bind(this, index, 'pics')}
                                      okText="确认"
                                      cancelText="取消"
                                    >
                                      <Button type='danger' className='margin-b10'>删除模板</Button>
                                    </Popconfirm>
                                    <Checkbox checked={item.selected} onChange={this.changeTempete2.bind(this, index, 'pics', 'selected')}>默认</Checkbox>

                                  </div>
                                </div>

                              </div>
                            );
                          })
                        }

                      </div>
                      <div className='margin-t10 '>
                        <div className='row-l margin-t10 margin-b5'>
                          <div className='fz18 bold margin-r20'>安装视频：</div>
                          <Upload
                            type='file'
                            beforeUpload={this.uploadVideo2}
                            customRequest={() => { }}
                            fileList={[]}
                            multiple={false}
                            maxCount={1}
                          >
                            <Button type='primary'>新增安装视频</Button>
                          </Upload>

                        </div>
                      </div>
                      <div className='row-w margin-t10 margin-l10'>
                        {
                          installVideo.vod.map((item, index) => {
                            return (
                              <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                                <div className='row-sb margin-b5'>
                                  <div className='bold'>名称：</div>
                                  <Input value={item.name} className='w200' onChange={this.changeTempete2.bind(this, index, 'vod', 'name')} />
                                </div>
                                <div className={`${styles['content-video']}`}>
                                  <video controls='controls' className={styles['content-image']} src={item.videoUrl} />
                                </div>
                                <div className='row w100 margin-t5'>
                                  <Popconfirm
                                    title="您确定要删除该视频模板吗?"
                                    onConfirm={this.delVideo2.bind(this, index, 'vod')}
                                    okText="确认"
                                    cancelText="取消"
                                  >
                                    <Button type='danger' className='w50 margin-r20'>删除模板</Button>
                                  </Popconfirm>
                                  <Checkbox checked={item.selected} onChange={this.changeTempete2.bind(this, index, 'vod', 'selected')}>默认</Checkbox>

                                </div>

                              </div>
                            );
                          })
                        }
                      </div>



                    </div>
                  ) : ''
              }
              {
                navigation === '商家客服' ?
                  (
                    <div className='margin-t25' key='serviceInfo'>
                      <Table
                        columns={columsServicer}
                        dataSource={servicerList}
                        size={'small'}
                        rowKey={record => record.id}
                        bordered
                        pagination={false}
                      />
                    </div>
                  ) : ''
              }

              {
                navigation === '财务流水' ?
                  (
                    <div className='col-t w100 vertical-lt w100 h100 position'>
                      <div className='row w100 margin-t25 bold'>
                        <div className={`${styles['amount']} ${styles['border-right']} col tc`}>
                          <div className='margin-b5'>{currentShop.balance}</div>
                          <div>商户余额（元）</div>
                        </div>
                        <div className={`${styles['amount']}  col tc`}>
                          <div className='margin-b5'>{currentShop.debt}</div>
                          <div>信用额度（元）</div>
                        </div>
                      </div>
                      <div className='row-l  margin-t25'>
                        <div className={`${styles['property']} tr`}>费用类型：</div>
                        <Select className={styles['select-head']} value={billSearch.type} onChange={this.changeSummary.bind(this, 'cost')} >
                          {
                            costList.map((item, index) => {
                              return (
                                <Option key={index} value={item}>{item}</Option>
                              );
                            })
                          }
                        </Select>
                        <Checkbox onChange={this.changeSummary.bind(this, 'timeVisible')} checked={billSearch.timeVisible} className={`${styles['propertys']} margin-l20`}>发生时间：</Checkbox>
                        <RangePicker onChange={this.changeSummary.bind(this, 'date')} value={[billSearch.start, billSearch.end]} disabled={!billSearch.timeVisible} placeholder={['开始日期', '结束日期']} />
                      </div>
                      <div className='row-sb w100'>
                        <div className='row-l margin-t25'>
                          <div className={`${styles['property']} tr`}>客户信息：</div>
                          <Input value={billSearch.info} onChange={this.changeSummary.bind(this, 'info')} className={`${styles['input']} tl`} placeholder='请输入客户相关信息' />
                          <div onClick={this.searchBill.bind(this, '流水')} className={`${styles['btn-finance']} margin-l20 tc back-color5 color0 fz16 cursor-pointer`}>搜索流水</div>
                          <div onClick={this.searchBill.bind(this, '结算')} className={`${styles['btn-finance']} margin-l20 tc back-color5 color0 fz16 cursor-pointer`}>搜索结算</div>
                          <div onClick={this.emptyBillSearch} className={`${styles['btn-finance']} margin-l20 tc margin-r40 fz16 cursor-pointer`}>重置</div>
                        </div>
                        <div onClick={this.toExcel} className={`${styles['btn-finance']} margin-t25 margin-r40 tc back-color5 color0 fz16 cursor-pointer`}>表格导出</div>
                      </div>
                      <div className='col-t  margin-t25 w100'>
                        <Table
                          rowKey={record => record.id}
                          dataSource={billList}
                          columns={columnsBill}
                          pagination={false}
                          bordered />
                        <div className='back-color0 w100'>
                          <PageTurning pageMaps={this.pageMaps.bind(this)} ref={this.pageModal} />
                        </div>
                      </div>
                      {/* <div className={`${styles['bill-count']} row`}>
                  <div className='back-color9 tc color0 fz16'>
                    期间消费金额：0  期间充值金额：0  期间理赔金额：0  期间赠送金额：0  期间修改金额：0  期间终止金额：0  期间结算金额：0  期间调整金额：0
                  </div>
                </div> */}
                    </div>
                  ) : ''
              }
              {
                navigation === '优惠券' ?
                  (
                    <div className='col-t w100 vertical-lt w100'>
                      {
                        role === 1 ?
                          <div></div>
                          :
                          <div className='row-w margin-t25'>
                            {discountList ?
                              discountList.map((item, index) => {
                                return (
                                  <div className={`${styles['discount']} col-t margin-b10 margin-r10 `} key={index}>
                                    <div className={`${styles['border-b']} row-sb w100  padding10 w100`}>
                                      <div className='col-l'>
                                        <div>名称：{item.name}</div>
                                        <div className='row-l'>
                                          <div className='margin-r20'>类别：{item.service}</div>
                                          <div>金额：{item.price}元</div>
                                        </div>
                                        <div>类型：{item.category}</div>
                                      </div>
                                      <div onClick={this.showDiscountModal.bind(this, index)} className={`${styles['btn-finance']} back-color5 color0 tc fz17  cursor-pointer`}>发劵</div>
                                    </div>
                                    <div className='row margin-t10'>
                                      <div>数量：</div>
                                      <InputNumber value={item.amount} min={1} onChange={this.discountTime.bind(this, index, 'amount')} className='tc w60' />
                                      <div>时间：</div>
                                      <DatePicker placeholder='请选择截止时间' locale={locale} key={dateKey} defaultValue={item.due_time} onChange={this.discountTime.bind(this, index, 'due_time')} showTime allowClear format={dateFormat} className='w166' />
                                    </div>
                                  </div>
                                );
                              }) : ''
                            }
                          </div>
                      }
                      <div className='col-t margin-t20 w100'>
                        <div className='w100 row-l'>
                          当前商户存在：
                          <div className='bold margin-r5 fz20'>
                            {discountTotal.install}
                          </div>
                          张 安装类优惠券，
                          <div className='bold margin-r5 fz20'>
                            {discountTotal.measure}

                          </div>
                          张 测量类优惠券
                        </div>
                        <div className='w100 row-l margin-b10'>
                          <div onClick={this.deleteDiscount} className={`${styles['btn-finance']} tc back-color11 color0  cursor-pointer`}>批量删除</div>
                          {
                            delDiscount.length > 0 ?
                              <div className='margin-l10'>已选中 {delDiscount.length} 项</div>
                              : ''
                          }
                        </div>
                        <Table
                          rowSelection={{
                            ...rowSelection,
                          }}
                          columns={columnsDiscount}
                          dataSource={discount}
                          size={'small'}
                          rowKey={record => record.id}
                          bordered
                          pagination={false}
                        />
                        <div className='back-color0 w100'>
                          <PageTurning pageMaps={this.pageMaps.bind(this)} />
                        </div>
                      </div>
                    </div>
                  ) : ''
              }
              {
                navigation === '订单转化' ? (
                  <div className='col-t w100 vertical-lt w100'>
                    <div className='row-l margin-t10'>
                      <div>转化状态：</div>
                      <Radio.Group value={transferSearch.state} onChange={this.changeTransInfo.bind(this, 'state')}>
                        {
                          ['已转化', '未转化'].map((item, index) => {
                            return (
                              <Radio value={item} key={index}>{item}</Radio>
                            );
                          })
                        }
                      </Radio.Group>
                      <div className='margin-l20'>时间类型：</div>
                      <Radio.Group value={transferSearch.type} onChange={this.changeTransInfo.bind(this, 'type')}>
                        {
                          ['下单', '完成', '转化'].map((item, index) => {
                            return (
                              <Radio value={item} key={index}>{item}</Radio>
                            );
                          })
                        }
                      </Radio.Group>
                    </div>
                    <div className='row-l margin-t10'>
                      <div>时间范围：</div>
                      <DatePicker locale={locale} onChange={this.changeTransInfo.bind(this, 'start')} allowClear={false}
                        value={transferSearch.start} format={dateFormat2} className={styles['select-head']} />
                      至
                      <DatePicker locale={locale} onChange={this.changeTransInfo.bind(this, 'end')} allowClear={false}
                        value={transferSearch.end} format={dateFormat2} className={styles['select-head']} />
                    </div>
                    <div className='row-l margin-t10'>
                      <div>客户信息：</div>
                      <Input value={transferSearch.info} onChange={this.changeTransInfo.bind(this, 'info')}
                        className='w200' />
                      <Button onClick={this.toSearchTransfer} type='primary' className='margin-r20'>搜索</Button>
                      <Button onClick={this.toExcelTransfer} type='primary'>导出Excel</Button>
                      <div className='margin-l20 bold fz20'>转化率：{transferSearch.rate}</div>
                    </div>
                    <div className='w100 back-color0 '>
                      <Table
                        columns={this.columnsTransfer}
                        dataSource={transfetData}
                        size={'small'}
                        className='w100 margin-t10'
                        rowKey={record => record.id}
                        bordered
                        pagination={false}
                      />
                      <PageTurning pageMaps={this.pageMapsTransfer} ref={this.pageModalTrans} />
                    </div>
                  </div>
                ) : ''
              }
              {
                navigation === '跟踪回访' ?
                  (
                    <div className='margin-t25'>
                      <Table
                        rowKey={record => record.id}
                        dataSource={follow}
                        columns={columsFollow}
                        pagination={false}
                        bordered
                      />
                      <div className='back-color0 w100'>
                        <PageTurning pageMaps={this.pageMaps.bind(this)} />
                      </div>
                    </div>
                  ) : ''
              }
              {
                navigation === '操作记录' ?
                  (
                    <div className='margin-t25'>
                      <Table
                        rowKey={record => record.id}
                        dataSource={operate}
                        columns={columsOperate}
                        pagination={false}
                        bordered
                      />
                      <div className='back-color0 w100'>
                        <PageTurning pageMaps={this.pageMaps.bind(this)} />
                      </div>
                    </div>
                  ) : ''
              }
            </div>
            :
            <div className='row-l vertical-lt w100' key='baseInfo'>
              {/* 新增 */}
              <div className='col-t vertical-lt margin-r20'>
                <div className='row-l margin-t20'>
                  <div className={`${styles['property']} tr`}>商户姓名：</div>
                  <Input value={newShop.name} onChange={this.changeSummary.bind(this, 'adname')} className={`${styles['address']} tl`} />
                </div>
                <div className='row-l margin-t20'>
                  <div className={`${styles['property']} tr`}>负责人：</div>
                  <Input disabled className='w126' value={newShop.mname} />
                  <Button type='primary' className='margin-l10' onClick={this.openModal.bind(this, '客服')}>选择</Button>
                  <div className={`${styles['property']} tr`}>负责人2：</div>
                  <Select value={newShop.manager2 ? newShop.manager2 : null} onChange={this.changeSummary.bind(this, 'admanager2')} className={styles['select-head']}>
                    {
                      waiters.map((item, index) => {
                        return (
                          <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                        );
                      })
                    }
                  </Select>
                </div>
                <div className='row-l margin-t20'>
                  <div className={`${styles['property']} tr`}>推荐人：</div>
                  <Input disabled className='w126' value={newShop.refer_name} />
                  <Button type='primary' className='margin-l10' onClick={this.openModal.bind(this, '师傅')}>选择</Button>
                </div>

                <div className='row-l margin-t20'>
                  <div className={`${styles['property']} tr`}>手机账号：</div>
                  <Input onChange={this.changeSummary.bind(this, 'adphone')} value={newShop.phone} className={`${styles['input']} tl`} />
                  <div className={`${styles['property']} tr`}>信用额度：</div>
                  <Input onChange={this.changeSummary.bind(this, 'addebt')} value={newShop.debt} className={`${styles['input']} tl`} />
                </div>
                <div className='row-l margin-t20'>
                  <div className={`${styles['property']} tr`}>微信号码：</div>
                  <Input value={newShop.weixin} onChange={this.changeSummary.bind(this, 'adweixin')} className={`${styles['input']} tl`} />
                  <div className={`${styles['property']} tr`}>商户等级：</div>
                  <Select onSelect={this.changeSummary.bind(this, 'adrank')} value={newShop.rank} className={styles['input']} bordered={false}>
                    {
                      rankList.map((item, indexs) => {
                        return (
                          <Option key={indexs} value={item}>{item}</Option>
                        );
                      })
                    }
                  </Select>
                </div>
                <div className='row-l margin-t20'>
                  <div className={`${styles['property']} tr`}>所在地址：</div>
                  <div className={styles['provinces']} >
                    <Regin value={[newShop.province, newShop.city, newShop.district]} onChange={this.changeSummary.bind(this, 'adprovinces')} />
                  </div>
                </div>
                <div className='row-l margin-t20'>
                  <div className={`${styles['property']} tr`}>详细地址：</div>
                  <Input onChange={this.changeSummary.bind(this, 'adaddress')} value={newShop.address} className={`${styles['address']} tl`} />
                </div>
                <div className='row-l  margin-t20'>
                  <div className={`${styles['property']} tr`}>服务类型：</div>
                  <div className={`${styles['service-box']} row-w padding10`}>
                    <Checkbox.Group options={serviceList} value={newShop.service_type} onChange={this.changeSummary.bind(this, 'adservice_type')} />
                  </div>
                </div>
                <div className='row-l margin-t20'>
                  <div className={`${styles['property']} tr`}>合作备注：</div>
                  <TextArea onChange={this.changeSummary.bind(this, 'adremark')} value={newShop.remark} className={`${styles['remark']} tl`} />
                </div>
                <div className='row-l margin-t20'>
                  <div className={`${styles['property']} tr`}>测量备注：</div>
                  <TextArea onChange={this.changeSummary.bind(this, 'm_adremark')} value={newShop.m_remark} className={`${styles['remark']} tl`} />
                </div>
                <div className='row-l margin-t20'>
                  <div className={`${styles['property']} tr`}>安装备注：</div>
                  <TextArea onChange={this.changeSummary.bind(this, 'i_adremark')} value={newShop.i_remark} className={`${styles['remark']} tl`} />
                </div>
              </div>
              <div className='col-t vertical-lt'>
                <div className='row-l margin-t25'>
                  <div className={`${styles['property']} tr color6`}>账号状态：</div>
                  <Radio.Group onChange={this.changeSummary.bind(this, 'adenable')} value={newShop.enable === '是' ? '启用' : '禁用'} size='large'>
                    {
                      enableList.map((item, index) => {
                        return <Radio value={item} key={index} checked={newShop.enable === '是' ? true : false} className='fz16'>{item}</Radio>;
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l margin-t25'>
                  <div className={`${styles['property']} tr color6`}>师傅电话：</div>
                  <Radio.Group onChange={this.changeSummary.bind(this, 'adshow_worker')} value={newShop.show_worker === '是' ? '显示' : '不显示'} size='large'>
                    {
                      permissionList.map((item, index) => {
                        return <Radio value={item} key={index} checked={newShop.show_worker === '是' ? true : false} className='fz16'>{item}</Radio>;
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l margin-t25'>
                  <div className={`${styles['property']} tr color6`}>订单预约：</div>
                  <Radio.Group onChange={this.changeSummary.bind(this, 'adshow_book')} value={newShop.show_book === '是' ? '启用' : '禁用'} size='large'>
                    {
                      enableList.map((item, index) => {
                        return <Radio value={item} key={index} checked={newShop.show_book === '是' ? true : false} className='fz16'>{item}</Radio>;
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l margin-t30'>
                  <div className={`${styles['property']} tr color6`}>认证状态：</div>
                  <Radio.Group onChange={this.changeSummary.bind(this, 'adchecked')} value={newShop.checked === '是' ? '已认证' : '未认证'} size='large'>
                    {
                      checkList.map((item, index) => {
                        return <Radio value={item} key={index} checked={newShop.checked === '是' ? true : false} className='fz16'>{item}</Radio>;
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l margin-t30'>
                  <div className={`${styles['property']} tr color6`}>派单方式：</div>
                  <Radio.Group onChange={this.changeSummary.bind(this, 'adsend')} value={newShop.allot_type} size='large'>
                    {
                      sendList.map((item, index) => {
                        return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l margin-t30'>
                  <div className={`${styles['property']} tr color6`}>一口价：</div>
                  <Radio.Group onChange={this.changeSummary.bind(this, 'adfix_price')} value={newShop.fix_price} size='large'>
                    {
                      fixedPriceList.map((item, index) => {
                        return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l margin-t30'>
                  <div className={`${styles['property']} tr color6`}>自动催单：</div>
                  <Radio.Group disabled={role === 1} onChange={this.changeSummary.bind(this, 'adurage')} value={newShop.urage} size='large'>
                    {
                      fixedPriceList.map((item, index) => {
                        return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l margin-t30'>
                  <div className={`${styles['property']} tr color6`}>测量数据模板：</div>
                  <Radio.Group onChange={this.changeSummary.bind(this, 'admodel')} value={newShop.model} size='large'>
                    {
                      ['旧版', '新版', '三千金'].map((item, index) => {
                        return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l margin-t40'>
                  <div className={`${styles['property']} tr col-r`}>
                    <div>营业执照：</div>
                    <div className='color5'>（纸质版）</div>
                  </div>
                  <UploadPic
                    oss={myOss.host}
                    src={newShop.pic ? newShop.pic : ''}
                    upLoad={this.upLoadIdPic}
                    size={'small'}
                    className={styles['box']}
                  />
                </div>
                <div className='row-l margin-t20'>
                  <div className={`${styles['property']} tr`}>费用备注：</div>
                  <TextArea onChange={this.changeSummary.bind(this, 'adprice_remark')} value={newShop.price_remark} className={`${styles['remark']} tl`} />
                </div>

              </div>
            </div>
        }
        <WorkerModal
          ref={this.workerModal}
          updateTable={this.updateTable}
        />
        <TransferInfoModal
          ref={this.transferInfoModal}
          update={this.getTransfer}
        />
        <IndexModal
          ref={this.indexModal}
        />
        <Modal
          title='财务调整'
          visible={adjustShow}
          width={500}
          cancelText='取消'
          okText='确认'
          onCancel={this.hideAdjustModal}
          onOk={this.adjustBill}
          footer={
            <div className='row-r w100'>
              <Button onClick={this.hideAdjustModal} type='ghost'>取消</Button>
              <Button loading={adjustLoading} onClick={this.adjustBill} type='primary'>确认</Button>
            </div>
          }
        >

          <div className='row-l margin-t20'>
            <div className='row-r w90'>
              <div className='color2'>*</div>
              <div>调整类型：</div>
            </div>
            <Select className={styles['select-head']} value={adjust.type} onChange={this.changeSummary.bind(this, 'type')} >
              {
                adjustList.map((item, index) => {
                  return (
                    <Option key={index} value={item}>{item}</Option>
                  );
                })
              }
            </Select>
          </div>
          <div className='row-l margin-t20'>
            <div className='row-r w90'>
              <div className='color2'>*</div>
              <div>调整金额：</div>
            </div>
            <InputNumber onChange={this.changeSummary.bind(this, 'price')} value={adjust.price} className={`${styles['bail']} tl padding-l20`} />
          </div>
          <div className='row-l margin-t20'>
            <div className='row-r w90'>
              <div className='color2'>*</div>
              <div>订单编号：</div>
            </div>
            <InputNumber disabled={['充值', '赠送'].includes(adjust.type)} onChange={this.changeSummary.bind(this, 'orderid')} value={adjust.orderid} className={`${styles['bail']} tl padding-l20`} />
          </div>
          <div className='row-l margin-t20'>
            <div className='row-r w90'>
              <div className='color2'>*</div>
              <div>调整说明：</div>
            </div>
            <TextArea onChange={this.changeSummary.bind(this, 'adRemark')} value={adjust.remark} className={`${styles['input']} tl`} placeholder='提现说明' />
          </div>
        </Modal>
        <Modal
          title='发送优惠券'
          visible={discountShow}
          width={500}
          cancelText='取消'
          okText='确认'
          onCancel={this.hideDiscountModal}
          onOk={this.addDiscount}
        >
          <div className='col-l margin-t20'>
            <div className='row-l'>
              <div className='w80 tl'>名称 ：</div>
              {currentDiscount.name}
            </div>
            <div className='row-l'>
              <div className='w80 tl'>类别 ：</div>
              {currentDiscount.service}
            </div>
            <div className='row-l'>
              <div className='w80 tl'>类型 ：</div>
              {currentDiscount.category}
            </div>
            <div className='row-l'>
              <div className='w80 tl'>金额 ：</div>
              {currentDiscount.price}
            </div>
            <div className='row-l'>
              <div className='w80 tl'>数量 ：</div>
              {currentDiscount.amount}
            </div>
            <div className='row-l'>
              <div>截止时间 ：</div>
              {currentDiscount.due_time}
            </div>
          </div>
        </Modal>
        <Modal
          title='新增回访记录'
          visible={followModal}
          width={500}
          cancelText='取消'
          okText='确认'
          onCancel={this.hideFollowModal}
          onOk={this.addFollow}
        >

          <div className='row-l margin-t20'>
            <div className={`${styles['property']} tr`}>回访说明：</div>
            <TextArea onChange={this.changeSummary.bind(this, 'followAjust')} value={followAjust} className={`${styles['input']} tl`} placeholder='回访说明' />
          </div>
        </Modal>
      </Drawer >
    );
  }
  getServicer = async () => {
    let res = await getServiceList('', '全部', 0, 500);
    if (res.status === 200) {
      this.setState({
        waiters: res.data
      });
    }
  }
  bindShop = async () => {
    const { currentShop } = this.state;
    let data = {
      outId: currentShop.id.toString(),
      name: currentShop.name,
      contact: currentShop.name,
      phone: currentShop.phone,
      province: currentShop.province,
      city: currentShop.city,
      district: currentShop.district,
      address: currentShop.address,
    };
    let res = await putBindShop(currentShop.id, data);
    if (res.status === 200) {
      message.success(res.data);
      currentShop.lj_shop = 111;
      this.setState({
        currentShop
      });
    }
  }
  getMyService = async () => {
    let res = await getService();
    if (res.status === 200) {
      this.setState({
        serviceList: res.data
      })

    }
  }
  //价格模板
  getTemplate = async (e) => {
    if (e && e[0]) {
      let res = await getMasterTemplate('是', 0, 10, e[0].script);
      if (res.status === 200) {
        let template = res.data[0] ? res.data[0] : {
          name: '',
          id: 0,
        }
        this.setState({
          template
        });
      }
    }
  }
  changeSummary = (type, e) => {
    let currentShop = { ...this.state.currentShop };
    let billSearch = { ...this.state.billSearch };
    let adjust = { ...this.state.adjust };
    let newShop = { ...this.state.newShop };
    switch (type) {
      case 'top':
        if (title[e] === '商家客服') {
          this.getMyservicer()
        } else if (title[e] === '财务流水') {
          this.getBalance();
          this.searchBill()
        } else if (title[e] === '优惠券') {
          this.getDiscount()
          this.getDiscountList()
        } else if (title[e] === '跟踪回访') {
          this.getFollow()
        } else if (title[e] === '操作记录') {
          this.getOperateList()
        } else if (title[e] === '视频模板') {
          this.getVideos();
        } else if (title[e] === '订单转化') {
          this.getTransfer();
        }
        this.setState({
          navigation: title[e],
          skip: 0,
          take: 10,
        });
        break;
      case 'name':
        currentShop.name = e.target.value;
        break;
      case 'adname':
        newShop.name = e.target.value;
        newShop.contact = e.target.value;
        break;
      case 'join_time':
        currentShop.join_time = moment(e).format(dateFormat);
        break;
      case 'adjoin_time':
        newShop.join_time = moment(e).format(dateFormat);
        break;
      case 'phone':
        currentShop.phone = e.target.value;
        break;
      case 'adphone':
        newShop.phone = e.target.value;
        break;
      case 'debt':
        currentShop.debt = e.target.value;
        break;
      case 'addebt':
        newShop.debt = e.target.value;
        break;
      case 'weixin':
        currentShop.weixin = e.target.value;
        break;
      case 'adweixin':
        newShop.weixin = e.target.value;
        break;
      case 'rank':
        currentShop.rank = e;
        break;
      case 'adrank':
        newShop.rank = e;
        break;
      case 'manager2':
        currentShop.manager2 = e;
        break;
      case 'admanager2':
        newShop.manager2 = e;
        break;
      case 'provinces':
        currentShop.province = e[0];
        currentShop.city = e[1];
        currentShop.district = e[2];
        break;
      case 'adprovinces':
        newShop.province = e[0];
        newShop.city = e[1];
        newShop.district = e[2];
        break;
      case 'address':
        currentShop.address = e.target.value;
        break;
      case 'adaddress':
        newShop.address = e.target.value;
        break;
      case 'service_type':
        currentShop.service_type = e;
        break;
      case 'adservice_type':
        newShop.service_type = e;
        break;
      case 'm_remark':
        currentShop.m_remark = e.target.value;
        break;
      case 'm_adremark':
        newShop.m_remark = e.target.value;
        break;
      case 'i_remark':
        currentShop.i_remark = e.target.value;
        break;
      case 'i_adremark':
        newShop.i_remark = e.target.value;
        break;
      case 'remark':
        currentShop.remark = e.target.value;
        break;
      case 'price_remark':
        currentShop.price_remark = e.target.value;
        break;
      case 'adremark':
        newShop.remark = e.target.value;
        break;
      case 'price_remark':
        currentShop.price_remark = e.target.value;
        break;
      case 'adprice_remark':
        newShop.price_remark = e.target.value;
        break;
      case 'model':
        currentShop.model = e.target.value;
        break;
      case 'admodel':
        newShop.model = e.target.value;
        break;
      case 'enable':
      case 'sale':
      case 'show_book':
        currentShop[type] = e.target.value === '启用' ? '是' : '否';
        break;
      case 'adenable':
        newShop.enable = e.target.value === '启用' ? '是' : '否';
        break;
      case 'adshow_book':
        newShop.show_book = e.target.value === '启用' ? '是' : '否';
        break;
      case 'show_worker':
        currentShop.show_worker = e.target.value === '显示' ? '是' : '否';
        break;
      case 'adshow_worker':
        newShop.show_worker = e.target.value === '显示' ? '是' : '否';
        break;
      case 'send':
        currentShop.allot_type = e.target.value;
        break;
      case 'adsend':
        newShop.allot_type = e.target.value;
        break;
      case 'checked':
        currentShop.checked = e.target.value === '已认证' ? '是' : '否';
        break;
      case 'adchecked':
        newShop.checked = e.target.value === '已认证' ? '是' : '否';
        break;
      case 'fix_price':
        currentShop.fix_price = e.target.value;
        break;
      case 'adfix_price':
        newShop.fix_price = e.target.value;
        break;
      case 'urage':
        currentShop.urage = e.target.value;
        break;
      case 'adurage':
        newShop.urage = e.target.value;
        break;
      case 'cost':
        billSearch.type = e;
        break;
      case 'info':
        billSearch.info = e.target.value;
        break;
      case 'timeVisible':
        billSearch.timeVisible = e.target.checked;
        break;
      case 'date':
        billSearch.start = e[0];
        billSearch.end = e[1];
        break;
      case 'type':
        adjust.type = e;
        break;
      case 'adRemark':
        adjust.remark = e.target.value;
        break;
      case 'orderid':
        adjust.orderid = e;
        break;
      case 'price':
        adjust.price = e;
        break;
      case 'followAjust':
        this.setState({
          followAjust: e.target.value
        });
        break;
      case 'bonus_type':
      case 'bonus_remark':
        currentShop[type] = e.target.value;
        break;
      case 'adbonus_type':
        newShop.bonus_type = e.target.value;
        break;
      case 'adbonus_remark':
        newShop.bonus_remark = e.target.value;
        break;
      case 'price_shop':
      case 'price_worker':
        currentShop[type] = e;
        break;
      case 'adprice_shop':
        newShop.price_shop = e;
        break;
      case 'adprice_worker':
        newShop.price_worker = e;
        break;

    }
    this.setState({
      currentShop, newShop, billSearch, adjust,
    });
  }
  //短信模板
  changeSms = (e) => {
    let currentShop = { ...this.state.currentShop };
    currentShop.sms = e.target.value;
    this.setState({
      currentShop,
      smsDiy: '墨墨管家',
    })
  }
  changeSmsDiy = (e) => {
    this.setState({
      smsDiy: e.target.value,
    })
  }
  upLoadIdPic = async (file) => {
    let name = await ossUpload(file, null);
    let newShop = { ...this.state.newShop };
    let currentShop = { ...this.state.currentShop };
    const { modalType } = this.state;
    if (modalType === 'edit') {
      currentShop.pic = name
      this.setState({
        currentShop
      });
    } else {
      newShop.pic = name
      this.setState({
        newShop
      });
    }
  }
  updateTable = (row, type) => {
    let currentShop = { ...this.state.currentShop };
    let newShop = { ...this.state.newShop };
    const { modalType, template } = this.state;
    if (row) {
      if (type === '客服') {
        if (modalType === 'edit') {
          currentShop.mname = row.name;
          currentShop.manager = row.id;
          this.setState({
            currentShop
          });
        } else {
          newShop.mname = row.name;
          newShop.manager = row.id;
          this.setState({
            newShop
          });
        }
      } else if (type === '师傅') {
        if (modalType === 'add') {
          newShop.refer_name = row.name;
          newShop.refer_worker = row.id;
          this.setState({
            newShop
          });
        }
      } else if (type === '模板') {
        if (template.id === row.id) {
          message.error('与当前价格模板相同');
          return;
        }
        this.addTemplate(row)
      }
    }
  }
  editMyShop = async () => {
    const { smsDiy } = this.state;
    let currentShop = { ...this.state.currentShop };
    if (currentShop.sms === '自定义' && smsDiy === '') {
      message.warn('自定义短信模板不能为空');
      return;
    }
    currentShop.sms = currentShop.sms === '自定义' ? smsDiy : currentShop.sms;
    currentShop.join_time = moment(currentShop.join_time).format(dateFormat);
    currentShop.service_type = JSON.stringify(currentShop.service_type)
    let res = await editShop(currentShop.id, currentShop);
    if (res.status === 204) {
      this.hideDrawer()
      this.update()
    }
  }
  //好评修改
  saveBonus = async () => {
    const { currentShop } = this.state;
    let data = {
      bonus_remark: currentShop.bonus_remark,
      bonus_type: currentShop.bonus_type,
      price_shop: currentShop.price_shop,
      price_worker: currentShop.price_worker,
    };
    let res = await putBonus(currentShop.id, data);
    if (res.status === 204) {
      this.hideDrawer()
      this.update()
    }
  }
  getMyservicer = async () => {
    let currentShop = { ...this.state.currentShop };
    let res = await getShopServicer(currentShop.id);
    if (res.status === 200) {
      this.setState({
        servicerList: res.data
      })
    }
  }
  getBalance = async () => {
    let currentShop = { ...this.state.currentShop };
    let res = await getShopBalance(currentShop.id)
    if (res.status === 200) {
      currentShop.balance = res.data;
      this.setState({
        currentShop
      })
    }
  }
  searchBill = (e) => {
    const { skip, take } = this.state;
    let billSearch = { ...this.state.billSearch };
    let currentShop = { ...this.state.currentShop };
    let start = billSearch.timeVisible ? billSearch.start ? moment(billSearch.start).format('YYYY-MM-DD') : '' : '';
    let end = billSearch.timeVisible ? billSearch.start ? moment(billSearch.end).format('YYYY-MM-DD') : '' : '';
    let searchType = e ? e : billSearch.searchType;
    if (searchType === billSearch.searchType) {
      this.getBill(searchType, currentShop.id, billSearch.info, billSearch.type, start, end, false, skip, take)
      billSearch.searchType = searchType;
      this.setState({
        billSearch
      })
    } else {
      this.getBill(searchType, currentShop.id, billSearch.info, billSearch.type, start, end, false, 0, take)
      billSearch.searchType = searchType;
      this.setState({
        billSearch,
        skip: 0,
      })
      this.pageModal.current.returnFirst();
    }
  }
  getBill = async (search, shop, info, type, start, end, isexcel, skip, take) => {
    let res = await getShopBill(search, shop, info, type, start, end, isexcel, skip, take);
    if (res.status === 200) {
      this.setState({
        billList: res.data
      })
    }
  }
  getDiscount = async () => {
    let currentShop = { ...this.state.currentShop };
    let res = await getShopDiscount(currentShop.id);
    let discountList = res.data.coupons.map(item => {
      item.amount = 1;
      item.due_time = '';
      return item
    })
    let discountTotal = { ...this.state.discountTotal };
    discountTotal.install = res.data.types['安装'] ? res.data.types['安装'] : 0;
    discountTotal.measure = res.data.types['测量'] ? res.data.types['测量'] : 0;
    let dateKey = new Date().getTime();
    if (res.status === 200) {
      this.setState({
        discountList,
        discountTotal,
        dateKey,
      })
    }
  }
  getDiscountList = async () => {
    let { currentShop, skip, take } = this.state;
    let res = await getShopDiscountList(currentShop.id, skip, take);
    if (res.status === 200) {
      this.setState({
        discount: res.data
      })
    }
  }
  deleteSingleDiscount = async (e) => {
    const { currentShop } = this.state;
    let delList = [];
    delList.push(e);
    let res = await delShopDiscount(currentShop.id, delList)
    if (res.status === 204) {
      this.getDiscount()
      this.getDiscountList()
      this.setState({
        delDiscount: []
      })
    }
  }
  deleteDiscount = async () => {
    const { delDiscount, currentShop } = this.state;
    let res = await delShopDiscount(currentShop.id, delDiscount)
    if (res.status === 204) {
      this.getDiscount()
      this.getDiscountList()
      this.setState({
        delDiscount: []
      })
    }
  }
  addDiscount = async () => {
    let currentShop = { ...this.state.currentShop };
    let currentDiscount = { ...this.state.currentDiscount };
    currentDiscount.due_time = moment(currentDiscount.due_time).format()
    let res = await addShopDiscount(currentShop.id, currentDiscount);
    if (res.status === 204) {
      this.getDiscount()
      this.hideDiscountModal()
      this.getDiscountList()
    }
  }
  emptyBillSearch = () => {
    this.setState({
      billSearch: {
        info: '',
        type: '全部',
        start: '',
        end: '',
        searchType: '',
        timeVisible: true,
      },
    })
  }
  adjustBill = async () => {
    this.setState({
      adjustLoading: true,
    });
    let adjust = { ...this.state.adjust };
    let currentShop = { ...this.state.currentShop };
    if (adjust.remark === '' || adjust.price === '') {
      message.error('数据不完整');
      this.setState({
        adjustLoading: false,
      });
      return
    }
    if (adjust.type === '修正' && (adjust.orderid === '' || adjust.orderid === null || adjust.orderid === 0)) {
      message.error('订单编号错误');
      this.setState({
        adjustLoading: false,
      });
      return
    }
    let res = await adjustBalance(adjust.type, currentShop.id, adjust.price, adjust.remark, adjust.orderid)
    if (res.status === 204) {
      this.hideAdjustModal()
      this.searchBill()
    }
    this.setState({
      adjustLoading: false,
    });
  }
  discountTime = (index, type, e) => {
    let discountList = [...this.state.discountList];
    discountList[index][type] = e;
    this.setState({
      discountList
    })
  }
  getFollow = async () => {
    let { currentShop, skip, take } = this.state;

    let res = await getShopFollow(currentShop.id, skip, take)
    if (res.status === 200) {
      this.setState({
        follow: res.data
      })
    }
  }
  addFollow = async () => {
    let currentShop = { ...this.state.currentShop };
    let followAjust = this.state.followAjust;
    let res = await addShopFollow(currentShop.id, followAjust)
    if (res.status === 200) {
      this.hideFollowModal()
      this.getFollow()
    }
  }
  getOperateList = async () => {
    const { currentShop, skip, take } = this.state;
    let res = await getShopOperate(currentShop.id, skip, take);
    if (res.status === 200) {
      this.setState({
        operate: res.data
      })
    }
  }
  addMyShop = async () => {
    let newShop = { ...this.state.newShop };
    if (!newShop.phone) {
      message.error('电话不能为空');
      return;
    }
    if (!newShop.name) {
      message.error('名称不能为空');
      return;
    }
    newShop.service_type = newShop.service_type.length > 0 ? JSON.stringify(newShop.service_type) : ''
    let res = await addShop(newShop)
    if (res.status === 200) {
      this.hideDrawer()
      this.update()
    }
  }
  addTemplate = async (e) => {
    const { currentShop, } = this.state;
    let res = await addMasterTemplate('商户', e.id, currentShop.id);
    if (res.status === 200) {
      this.setState({
        template: e,
      })
      this.update()
    }
  }
  delTemplate = async () => {
    const { currentShop } = this.state;
    let res = await delPriceTemplate('商户', currentShop.scripts[0].id);
    if (res.status === 204) {
      this.setState({
        template: {
          name: '',
          id: 0,
        }
      });
      this.update()
    }
  }

  update = () => {
    const { updateShop } = this.props;
    updateShop()
  }

  openModal = (e) => {
    this.workerModal.current.onShowModal(e);
  }
  openTemplate = () => {
    this.workerModal.current.onShowModal('模板');
  }
  showDrawer = (e) => {
    let currentShop = { ...e.currentShop };
    let modalType = e.type
    let smsDiy = '墨墨管家';
    if (currentShop.sms !== '不发送') {
      smsDiy = currentShop.sms;
      currentShop.sms = '自定义';
    }
    currentShop.join_time = moment(currentShop.join_time)
    currentShop.service_type = currentShop.service_type && currentShop.service_type.length > 0 ? JSON.parse(currentShop.service_type) : [];
    this.setState({
      currentShop,
      drawerShow: true,
      modalType, smsDiy,
    })
    this.getTemplate(currentShop.scripts)
  }
  hideDrawer = () => {
    let lastCurrentShop = { ...this.state.currentShop };
    const { modalType } = this.state;
    if (modalType === 'edit') {
      this.optionRef.current.returnFirst();
    }
    let videos = _.cloneDeep(videoModel)
    this.setState({
      drawerShow: false,
      currentShop: {
        province: lastCurrentShop.province,
        city: lastCurrentShop.city,
        district: lastCurrentShop.district,
      },
      navigation: '基本信息',
      newShop: { ...addNewShop },
      template: {
        name: '',
        id: 0,
      },
      videos: videos,
      installVideo: videos.install,
      measureVideo: videos.measure,
      transferSearch: {
        state: '已转化',
        type: '下单',
        start: moment().subtract(1, 'month'),
        end: moment(),
        skip: 0,
        take: 10,
        total: 0,
        trans: 0,
        rate: 0,
        info: '',
      },
      smsDiy: '',
    })
  }

  showAdjustModal = () => {
    this.setState({
      adjustShow: true
    })
  }
  hideAdjustModal = () => {
    this.setState({
      adjustShow: false,
      adjust: {
        type: '修正',
        price: 0,
        remark: '',
        orderid: null,
      },
    })
  }
  showDiscountModal = (e) => {
    let discountList = [...this.state.discountList];
    let currentDiscount = discountList[e];
    if (currentDiscount.due_time === '') {
      message.error('时间未填写');
      return;
    }
    currentDiscount.due_time = moment(currentDiscount.due_time).format(dateFormat);
    this.setState({
      currentDiscount,
      discountShow: true,
    })
  }
  hideDiscountModal = () => {
    this.setState({
      discountShow: false,
    })
  }
  showFollowModal = () => {
    this.setState({
      followModal: true
    })
  }
  hideFollowModal = () => {
    this.setState({
      followModal: false
    })
  }
  //换页
  pageMaps = ({ skip, take }) => {
    const { navigation } = this.state;
    this.setState({
      skip: skip,
    }, () => {
      if (navigation === '操作记录') {
        this.getOperateList()
      } else if (navigation === '跟踪回访') {
        this.getFollow()
      } else if (navigation === '优惠券') {
        this.getDiscountList()
      } else if (navigation === '财务流水') {
        this.searchBill()
      }
    })
  }

  toExcel = async () => {
    const { billList, currentShop, skip, take } = this.state;
    const columnsBill = [
      {
        title: '流水号',
        align: 'center',
        width: 110,
        dataIndex: 'id',
        key: 'id',
        type: 'key',
      },
      {
        title: '订单编号',
        align: 'center',
        width: 110,
        dataIndex: 'order',
        key: 'order',
      },
      {
        title: '订单类型',
        align: 'center',
        width: 110,
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: '费用类型',
        align: 'center',
        width: 110,
        dataIndex: 'service_type',
        key: 'service_type',
      },
      {
        title: '发生金额',
        align: 'center',
        width: 110,
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: '账户金额',
        align: 'center',
        width: 110,
        dataIndex: 'balance',
        key: 'balance',
      },
      {
        title: '结算时间',
        align: 'center',
        width: 150,
        dataIndex: 'check_time',
        key: 'check_time',
        // render: (text, record, index) => {
        //   return (<div >{moment(record.check_time).format('YYYY-MM-DD HH:mm')}</div>)
        // }
      },
      {
        title: '消费时间',
        align: 'center',
        width: 150,
        dataIndex: 'add_time',
        key: 'add_time',
        // render: (text, record, index) => {
        //   return (<div >{moment(record.add_time).format('YYYY-MM-DD HH:mm')}</div>)
        // }
      },
      {
        title: '客户信息',
        align: 'center',
        width: 220,
        dataIndex: 'order_info',
        key: 'order_info',
      },
      {
        title: '唯一订单号',
        align: 'center',
        width: 150,
        dataIndex: 'platform',
        key: 'platform',
      },
      {
        title: '备注信息',
        align: 'center',
        width: 160,
        dataIndex: 'content',
        key: 'content',
      },

    ];
    let billSearch = { ...this.state.billSearch };
    let start = billSearch.timeVisible ? billSearch.start ? moment(billSearch.start).format('YYYY-MM-DD') : '' : '';
    let end = billSearch.timeVisible ? billSearch.start ? moment(billSearch.end).format('YYYY-MM-DD') : '' : '';
    let res = await getShopBill(billSearch.searchType, currentShop.id, billSearch.info, billSearch.type, start, end, true, skip, take)
    if (res.status === 200) {
      let excelData = res.data.map((item, index) => {
        return {
          // locate: index + 1,
          id: item.id,
          order: item.order,
          type: item.type,
          service_type: item.service_type,
          price: item.price,
          balance: item.balance,
          check_time: moment(item.check_time).format('YYYY-MM-DD HH:mm'),
          add_time: moment(item.add_time).format('YYYY-MM-DD HH:mm'),
          order_info: item.order_info,
          platform: item.platform ? item.platform : '',
          content: item.content,
        }
      })
      let fileName = `${currentShop.name}-流水记录`;
      let titleName = billSearch.timeVisible ? '流水表' : `${billSearch.start + '-' + billSearch.end} 流水表`;
      jsToExcel(columnsBill, excelData, fileName, titleName);
    }
  }

  //视频模板
  getVideos = async (e) => {
    let currentShop = { ...this.state.currentShop };
    let res = await getShopVideos(currentShop.id);
    if (res.status !== 200) {
      message.error(res.data);
      return
    } else {
      let videos = _.cloneDeep(videoModel)
      let installVideo = {};
      let measureVideo = {};
      if (res.data && JSON.parse(res.data)) {
        videos = JSON.parse(res.data);
      }
      installVideo = videos.install;
      measureVideo = videos.measure;
      let that = this;
      if (installVideo.vod.length > 0) {
        installVideo.vod.map((item, index) => {
          (async () => {
            let locate = index;
            let rsp = await getVideoPlay(item.videoId);
            if (rsp.status === 200) {
              installVideo.vod[locate].videoUrl = rsp.data.playURL
              that.setState({
                installVideo
              });
            }
          })()
        })
      }
      if (measureVideo.vod.length > 0) {
        measureVideo.vod.map((item, index) => {
          (async () => {
            let locate = index;
            let rsp = await getVideoPlay(item.videoId);
            if (rsp.status === 200) {
              measureVideo.vod[locate].videoUrl = rsp.data.playURL
              that.setState({
                measureVideo
              });
            }
          })()
        })
      }
      this.setState({
        installVideo, measureVideo,
      })
    }
  }
  saveVideo = async () => {
    const { currentShop, installVideo, measureVideo } = this.state;
    let data = _.cloneDeep(videoModel);
    data.measure = measureVideo;
    data.install = installVideo;
    data.measure.vod = measureVideo.vod.map(r => {
      let single = {
        name: r.name,
        videoId: r.videoId,
        selected: r.selected,
      };
      if (r.name === '') {
        check = false;
      }
      return single;
    });
    data.install.vod = installVideo.vod.map(r => {
      let single = {
        name: r.name,
        videoId: r.videoId,
        selected: r.selected,
      };
      if (r.name === '') {
        check = false;
      }
      return single;
    });
    let errors1 = measureVideo.pics.filter(r => r.name === '');
    let errors2 = measureVideo.vod.filter(r => r.name === '');
    let errors3 = installVideo.pics.filter(r => r.name === '');
    let errors4 = installVideo.vod.filter(r => r.name === '');
    let errrorCount = errors1.concat(errors2, errors3, errors4);
    if (errrorCount.length > 0) {
      message.warn('有名称未填写');
      return;
    }
    data = JSON.stringify(data);
    let res = await putShopVideos(currentShop.id, data);
    if (res.status === 200) {
      message.success('保存成功');
      this.hideDrawer()
    } else {
      message.error(res.data);
    }
  }
  //视频
  uploadVideo = async (file, fileList) => {
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    if (fileList.length > 1) {
      message.warn('只能上传单个视频');
      return;
    }
    let decimal = (new Date()).valueOf();
    let name = `${convert.toString36(decimal)}`.replace(/:/g, '-');
    let res = await getVideoInfo(name, file.name);
    let uploadAddress = JSON.parse(decodeURI(atob(res.data.uploadAddress)));
    let uploadInfos = {
      video: res.data.videoId,
      Endpoint: uploadAddress.Endpoint,
      Bucket: uploadAddress.Bucket,
      object: null,
    };
    let that = this;
    this.uploader = new AliyunUpload.Vod({
      userId: '112',
      region: 'cn-shanghai',
      partSize: 1048576,
      parallel: 5,
      retryCount: 3,
      retryDuration: 2,
      'onUploadstarted': async (uploadInfo) => {
        this.uploader.setUploadAuthAndAddress(uploadInfo, res.data.uploadAuth, res.data.uploadAddress, res.data.videoId);
      },
      'onUploadEnd': async function (uploadInfo) {
        let rsp = await getVideoSub(res.data.videoId);
        if (rsp.status === 204) {
          that.getVideoUrl(res.data.videoId);
        } else {
          message.error(rsp.data);
        }
      }
    });
    this.uploader.addFile(file, uploadInfos.Endpoint, uploadInfos.Bucket, null, '{"Vod":{}}');
    this.uploader.startUpload();
  }
  uploadVideo2 = async (file, fileList) => {
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    if (fileList.length > 1) {
      message.warn('只能上传单个视频');
      return;
    }
    let decimal = (new Date()).valueOf();
    let name = `${convert.toString36(decimal)}`.replace(/:/g, '-');
    let res = await getVideoInfo(name, file.name);
    let uploadAddress = JSON.parse(decodeURI(atob(res.data.uploadAddress)));
    let uploadInfos = {
      video: res.data.videoId,
      Endpoint: uploadAddress.Endpoint,
      Bucket: uploadAddress.Bucket,
      object: null,
    };
    let that = this;
    this.uploader = new AliyunUpload.Vod({
      userId: '112',
      region: 'cn-shanghai',
      partSize: 1048576,
      parallel: 5,
      retryCount: 3,
      retryDuration: 2,
      'onUploadstarted': async (uploadInfo) => {
        this.uploader.setUploadAuthAndAddress(uploadInfo, res.data.uploadAuth, res.data.uploadAddress, res.data.videoId);
      },
      'onUploadEnd': async function (uploadInfo) {
        let rsp = await getVideoSub(res.data.videoId);
        if (rsp.status === 204) {
          that.getVideoUrl2(res.data.videoId);
        } else {
          message.error(rsp.data);
        }
      }
    });
    this.uploader.addFile(file, uploadInfos.Endpoint, uploadInfos.Bucket, null, '{"Vod":{}}');
    this.uploader.startUpload();
  }
  getVideoUrl = async (e) => {
    let res = await getVideoPlay(e);
    if (res.status === 200) {
      let measureVideo = _.cloneDeep(this.state.measureVideo);
      measureVideo.vod.push({
        name: '新模板',
        videoId: e,
        videoUrl: res.data.playURL,
        selected: true,
      });
      this.setState({
        measureVideo
      })
    }
  }
  getVideoUrl2 = async (e) => {
    let res = await getVideoPlay(e);
    if (res.status === 200) {
      let installVideo = _.cloneDeep(this.state.installVideo);
      installVideo.vod.push({
        name: '新模板',
        videoId: e,
        videoUrl: res.data.playURL,
        selected: true,
      });
      this.setState({
        installVideo
      })
    }
  }
  delVideo = (index, type) => {
    let measureVideo = _.cloneDeep(this.state.measureVideo);
    measureVideo[type].splice(index, 1)
    this.setState({
      measureVideo
    })
  }
  delVideo2 = (index, type) => {
    let installVideo = _.cloneDeep(this.state.installVideo);
    installVideo[type].splice(index, 1)
    this.setState({
      installVideo
    })
  }
  changeTempete = (index, category, type, e) => {
    let measureVideo = _.cloneDeep(this.state.measureVideo);
    if (type === 'selected') {
      measureVideo[category][index][type] = e.target.checked;
    } else {
      measureVideo[category][index][type] = e.target.value ? e.target.value : '';
    }
    this.setState({
      measureVideo
    })
  }
  changeTempete2 = (index, category, type, e) => {
    let installVideo = _.cloneDeep(this.state.installVideo);
    if (type === 'selected') {
      installVideo[category][index][type] = e.target.checked;
    } else {
      installVideo[category][index][type] = e.target.value ? e.target.value : '';
    }
    this.setState({
      installVideo
    })
  }
  //图片
  onAddPic2 = async (file, fileList) => {
    let installVideo = _.cloneDeep(this.state.installVideo)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = installVideo.pics.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      installVideo.pics[leng + i] = {
        name: '安装图片',
        url: name,
        selected: true,
      };
    }
    this.setState({
      installVideo
    })
  }
  onAddPic = async (file, fileList) => {
    let measureVideo = _.cloneDeep(this.state.measureVideo)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = measureVideo.pics.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      measureVideo.pics[leng + i] = {
        name: '测量图片',
        url: name,
        selected: true,
      };
    }
    this.setState({
      measureVideo
    })
  }
  preview = (url) => {
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
  //订单转化
  changeTransInfo = (type, e) => {
    let transferSearch = { ...this.state.transferSearch };
    switch (type) {
      case 'state':
      case 'type':
      case 'info':
        transferSearch[type] = e.target.value;
        break;
      case 'start':
      case 'end':
        transferSearch[type] = e;
        break;
      default: break;
    }
    this.setState({
      transferSearch
    });
  }
  toSearchTransfer = () => {
    this.pageModalTrans.current.returnFirst();
    let transferSearch = { ...this.state.transferSearch };
    transferSearch.skip = 0;
    this.setState({
      transferSearch
    }, () => this.getTransfer());
  }
  getTransfer = async () => {
    const { currentShop, } = this.state;
    let transferSearch = _.cloneDeep(this.state.transferSearch);
    let start = moment(transferSearch.start).format(dateFormat2);
    let end = moment(transferSearch.end).format(dateFormat2);
    let res = await getShopTransfer(currentShop.id, transferSearch.state, transferSearch.type, start, end, transferSearch.skip, transferSearch.take, transferSearch.info);
    if (res.status === 200) {
      transferSearch.total = res.data.total;
      transferSearch.trans = res.data.trans;
      if (transferSearch.total) {
        transferSearch.rate = Number(transferSearch.trans / transferSearch.total * 100).toFixed(1) + '%';
      } else {
        transferSearch.rate = 0;
      }
      this.setState({
        transfetData: res.data.list,
        transferSearch
      })
    }
  }
  transferShow = (e) => {
    this.transferInfoModal.current.onShow(e);
  }
  pageMapsTransfer = ({ skip, take }) => {
    let transferSearch = { ...this.state.transferSearch };
    transferSearch.skip = skip;
    this.setState({
      transferSearch,
    }, async () => this.getTransfer())
  }
  toExcelTransfer = async () => {
    const { transferSearch, currentShop } = this.state;
    let start = moment(transferSearch.start).format(dateFormat2);
    let end = moment(transferSearch.end).format(dateFormat2);
    let res = await getShopTransfer(currentShop.id, transferSearch.state, transferSearch.type, start, end, 0, transferSearch.total, transferSearch.info);
    if (res.status === 200) {
      let columns = [
        {
          title: '订单信息',
          key: 'orderInfo',
        },
        {
          title: '测量订单编号',
          key: 'id',
        },
        {
          title: '客户信息',
          key: 'info',
        },
        {
          title: '商户费用',
          key: 'price_app',
        },
        {
          title: '订单是否已完成',
          key: 'state',
        },
        {
          title: '转化状态',
          key: 'status',
        },
        {
          title: '转化时间',
          key: 'trans_time',
        },
        {
          title: '转化订单编号',
          key: 'order',
        },
        {
          title: '说明',
          key: 'bonus_remark',
        },
      ];
      let data = res.data.list.map(item => {
        return {
          orderInfo: `${item.id}-${item.category}-${item.service}-${item.type}【${item.state}】-下单时间：${moment(item.create_time).format('YYYY/MM/DD-HH:mm:ss')}`,
          id: item.id ? item.id : '',
          info: `${item.name}，${item.phone}，${item.province}${item.city}${item.district},${item.address}`,
          price_app: item.price_app,
          state: item.state + `${item.compete_time && item.compete_time !== '1970-01-01T00:00:00' ? '，完成时间：' + moment(item.compete_time).format('YYYY/MM/DD-HH:mm:ss') : ''}`,
          status: item.order ? '是' : '否',
          trans_time: item.trans_time && item.trans_time !== '1970-01-01T00:00:00' ? moment(item.trans_time).format('YYYY/MM/DD-HH:mm:ss') : '',
          order: item.order ? item.order : '',
          bonus_remark: item.bonus_remark ? item.bonus_remark : '',
        };
      });

      jsToExcel(columns, data, `${currentShop.name}_${start}-${end}订单转化`, '');
    }
  }
  onOpenModal = (data) => {
    let installData = _.cloneDeep(data);
    installData.id = data.order;
    this.indexModal.current.onShowModal(installData, '订单详情');
  }
}

export default Index;
import React, { Component } from 'react';
import { Table, Modal, Input, message, Button, } from 'antd';
import { putCancelorder } from '../../../services/orderCore';

class OperateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      id: 0,
      operationType: '',
      remark: '',
      loading: false,
    };
  }
  render() {
    const { visible, operationType, remark, loading, } = this.state;
    return (
      <Modal
        title={operationType}
        visible={visible}
        width={700}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='确定'
        cancelText='取消'
        confirmLoading={loading}
        destroyOnClose={true}
      >
        <div>
          <div className='row-l vertical-lt'>
            <div>终止备注：</div>
            <Input.TextArea value={remark} onChange={this.changeRemark} rows={4} className='w400'
              placeholder='请输入备注' />
          </div>
        </div>
      </Modal>
    );
  }
  changeRemark = (e) => {
    this.setState({
      remark: e.target.value,
    })
  }
  onShow = (id, type) => {
    this.setState({
      visible: true,
      id,
      operationType: type,
    })
  }
  onCancel = () => {
    this.setState({
      visible: false,
    })
  }
  onOk = async () => {
    const { id, remark } = this.state;
    let res = await putCancelorder(id, remark);
    if (res.status === 204) {
      this.onCancel();
    }
  }
}
export default OperateModal;
import React, { Component } from 'react';
import { Button, Popconfirm, DatePicker, Input, message, Radio, Select, Table, Modal } from 'antd';
import { getInvite, delInvite } from '../../services/inviteSign';
import { getServiceList } from '../../services/serviceManagement';

import PageTurning from '../../components/pageTurning';
import InviteDrawer from './inviteDrawer';
import moment from 'moment';

const dateFormate = 'YYYY-MM-DD HH:mm:ss';
const states = ['待确认', '已确认', '已拒绝', '已通过'];
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        info: '',
        state: '待确认',
        skip: 0,
        take: 10,
      },
      data: [],
      serviceList: [],
    };
    this.pageModal = React.createRef();
    this.inviteDrawer = React.createRef();
    this.columns = [
      {
        title: '邀请时间',
        align: 'center',
        dataIndex: 'add_time',
        width: 100,
        render: (text, record, index) => {
          return (
            <div>{moment(record.add_time).format(dateFormate)}</div>
          );
        }
      },
      {
        title: '师傅',
        align: 'center',
        dataIndex: 'name',
        width: 100,
      },
      {
        title: '电话',
        align: 'center',
        dataIndex: 'uid',
        width: 80,
      },
      {
        title: '师傅地址',
        align: 'center',
        dataIndex: 'current_address',
        width: 170,
      },
      {
        title: '平台备注',
        align: 'center',
        dataIndex: 'plat_remark',
        width: 200,
        render: (text, record, index) => {
          return (
            <div className='lineCut'>{record.plat_remark}</div>
          );
        }
      },
      {
        title: '师傅确认时间',
        align: 'center',
        dataIndex: 'confirm_time',
        width: 100,
        render: (text, record, index) => {
          return (
            <div>{record.confirm_time !== '0001-01-01T00:00:00' ? moment(record.confirm_time).format(dateFormate) : ''}</div>
          );
        }
      },
      {
        title: '审核时间',
        align: 'center',
        dataIndex: 'check_time',
        width: 100,
        render: (text, record, index) => {
          let exitIndex = this.state.serviceList.findIndex(r => r.id === record.checker);
          return (
            <div>
              <div>{exitIndex > -1 ? this.state.serviceList[exitIndex].name : ''}</div>
              <div>{record.check_time !== '0001-01-01T00:00:00' ? moment(record.check_time).format(dateFormate) : ''}</div>
            </div>
          );
        }
      },
      {
        title: '操作',
        align: 'center',
        dataIndex: 'operate',
        width: 120,
        render: (text, record, index) => {
          return (
            <div>
              <Button onClick={this.toCheck.bind(this, record, 'see')} type='primary' className='margin-l10'>详情</Button>
              {
                record.state === '已确认' &&
                <Button onClick={this.toCheck.bind(this, record, 'check')} type='primary' className='margin-l10'>去审核</Button>
              }
              {
                false &&
                <Popconfirm
                  title='您确定要删除该邀请吗?'
                  onConfirm={this.deleteInvite.bind(this, record.id)}
                  okText='确认'
                  cancelText='取消'
                >
                  <Button type='danger' className='margin-l10'>删除</Button>
                </Popconfirm>
              }
            </div>
          );
        }
      },
    ];
  }
  componentDidMount() {
    this.getList();
    this.getServices();
  }
  render() {
    const { search, data, serviceList } = this.state
    return (
      <div>
        <div className='bar margin-b10'>
          <div className='row-l margin-b10'>
            <div>状态：</div>
            <Radio.Group value={search.state} onChange={this.changeInfo.bind(this, 'state')}>
              {
                states.map((item, index) => {
                  return (
                    <Radio value={item} key={index}>{item}</Radio>
                  );
                })
              }
            </Radio.Group>
          </div>
          <div className='row-l'>
            <div>搜索：</div>
            <Input value={search.info} onChange={this.changeInfo.bind(this, 'info')} className='w200' />
            <Button onClick={this.getList} type='primary'>搜索</Button>
          </div>
        </div>
        <div className='bar'>
          <Table
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} ref={this.pageModal} />
        </div>
        <InviteDrawer ref={this.inviteDrawer} serviceList={serviceList} update={this.getList} />
      </div>
    );
  }
  getServices = async () => {
    let res = await getServiceList('', '全部', 0, 500);
    if (res.status === 200) {
      this.setState({
        serviceList: res.data
      })
    }
  }
  changeInfo = (type, e) => {
    let search = { ...this.state.search };
    search[type] = e.target.value;
    this.setState({
      search,
    });
  }
  getList = async () => {
    const { search } = this.state;
    let res = await getInvite(search.info, search.state, search.skip, search.take);
    if (res.status === 200) {
      this.setState({
        data: res.data
      });
    }
  }
  pageMaps = ({ skip, take }) => {
    let search = { ...this.state.search };
    search.skip = skip;
    this.setState({
      search,
    }, () => this.getList())
  }
  deleteInvite = async (id) => {
    let res = await delInvite(id);
    if (res.status === 204) {
      this.getList();
    }
  }
  toCheck = (e, type) => {
    this.inviteDrawer.current.onShow(e, type);
  }
}
export default Index;
import request from '../utils/request';
export async function getFeeds() {
  return await request('platbiz/reasonlist', 'get');
}
export async function postFeeds(data) {
  return await request('platbiz/addfeed', 'post', data);
}
export async function putFeeds(id, cash, name) {
  return await request(`platbiz/mdyfeed?id=${id}&cash=${cash}&name=${name}`, 'put');
}
export async function delFeeds(id) {
  return await request(`platbiz/delfeed?id=${id}`, 'delete');
}

export async function postDetail(data) {
  return await request('platbiz/adddetail', 'post', data);
}
export async function putDetail(id, name) {
  return await request(`platbiz/mdydetail?id=${id}&name=${name}`, 'put');
}
export async function delDetail(id) {
  return await request(`platbiz/deldetail?id=${id}`, 'delete');
}

export async function putFeedbackList(data) {
  return await request('platbiz/feedbacklist', 'put', data);
}
export async function postFeedback(data) {
  return await request('platbiz/addfeedback', 'post', data);
}
export async function getFeedback(id) {
  return await request(`platbiz/feedback?id=${id}`, 'get');
}
export async function mdyFeedback(data) {
  return await request('platbiz/mdyfeedback', 'post', data);
}

export async function putFeedback(data) {
  return await request('platbiz/feedback', 'put', data);
}
export async function delFeedback(id, content) {
  return await request(`platbiz/stopfeedback?id=${id}&content=${content}`, 'get',);
}


import React, { PureComponent } from 'react';
import { Upload, Icon, Modal, message, Popconfirm } from 'antd';
import { EyeOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import pic from '../../resource/img/404pic.jpg';

class UploadPic extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.upLoad = this.upLoad.bind(this);
    this.preview = this.preview.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.delete = this.delete.bind(this);
  }

  render() {
    let { oss, src, size, upload = true } = this.props;
    let url = oss ? `${oss}${'/upload/'}${src}` : src;
    return (
      <div className={size === 'small' ? `cursor-pointer ${styles['content']} ${styles['content-small']}` : `cursor-pointer ${styles['content']} ${styles['content']}`}>
        <img
          draggable="false"
          className={styles['content-image']}
          alt="找不到该图片"
          src={src ? url : pic} />
        <div className={size === 'small' ? `row ${styles['content-div']} ${styles['content-div-small']} ` : `row ${styles['content-div']}`} >
          {!this.props.picType && <div className={`${styles['delete']}`}>
            <Popconfirm
              title="您确定要删除图片吗?"
              onConfirm={this.delete}
              okText="确认"
              cancelText="取消"
            >
              <DeleteOutlined
                className={`${styles['pic-icon']}`}
                theme="outlined"
                style={{ color: 'red', fontSize: 15 }}
                title="删除" />
            </Popconfirm>
          </div>}
          <EyeOutlined
            className={`${styles['pic-icon']}`}
            onClick={this.preview}
            theme="outlined"
            title="预览"
          />
          {
            upload &&
            <Upload
              type='file'
              beforeUpload={() => {
                return false;
              }}
              fileList={[]}
              accept='.jpg,.png,.jpeg,.icon,.svg,.gif'
              // accept='image/*'
              multiple={false}
              onChange={this.upLoad}
            >
              <UploadOutlined className={`${styles['pic-icon']}`} />
              {/* <Icon type="upload" className={`${styles['pic-icon']}`} theme="outlined"
                title="上传" /> */}
            </Upload>
          }
        </div>
        <Modal
          title="图片显示"
          destroyOnClose={true}
          width={700}
          visible={this.state.visible}
          footer={false}
          onCancel={this.onCancel}
        >
          <img draggable="false" src={url} style={{ width: '100%' }} alt="找不到该图片" />
        </Modal>
      </div>
    );
  }

  upLoad(e) {
    let { file } = e;
    let { upLoad } = this.props;
    if (upLoad) {
      upLoad(file);
    }
  }
  delete() {
    let index = this.props.index;
    if (this.props.deletePic) {
      this.props.deletePic(index);
    }
  }
  preview() {
    let { src } = this.props;
    if (src) {
      this.setState({
        visible: true
      });
    }
    else {
      message.warn('暂无图片');
    }
  }

  onCancel() {
    this.setState({
      visible: false
    });
  }
}

UploadPic.propTypes = {
  oss: PropTypes.string,
  src: PropTypes.string,
  upLoad: PropTypes.func,
  size: PropTypes.string
};

export default UploadPic;
import request from '../utils/request';

export async function getInvite(info, state, skip, take) {
  return await request(`platbiz/invite?info=${info}&state=${state}&skip=${skip}&take=${take}`, 'get');
}
export async function postInvite(data) {
  return await request('platbiz/invite', 'post', data);
}
export async function putInvite(data) {
  return await request('platbiz/invite', 'put', data);
}
export async function delInvite(id) {
  return await request(`platbiz/invite?id=${id}`, 'delete');
}

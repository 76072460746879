import React, { Component } from 'react';
import { Modal, Button, message, Input, InputNumber, } from 'antd';
import { postInvite } from '../../services/inviteSign';
import Regin from '../../components/regioRegister';

const init = {
  worker: 0,
  state: '',
  price: 0,
  area: [],
  plat_remark: '',
};
class InviteSignModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      workInfo: {},
      data: { ...init },
      provinces: ['', '', ''],

    };
  }
  render() {
    const { visible, data, provinces } = this.state;
    return (
      <Modal
        title={'邀请签约'}
        width={700}
        visible={visible}
        onCancel={this.onCancel}
        cancelText='取消'
        footer={
          <div className='row-r'>
            <Button onClick={this.onCancel} type='ghost'>取消</Button>
            <Button onClick={this.onOk} type='primary'>确认</Button>
          </div>
        }
      >
        <div>
          <div className='row-l vertical-lt margin-b10'>
            <div className='col vertical-lt w80'>
              <div className='w80'>签约区域:</div>
            </div>
            <div className='row-w vertical-lt w550 bord1 h160 padding5 radius5 over'>
              {
                data.area.map((item, index) => {
                  return (
                    <div className='row-l bord1 radius5 padding5 margin-r10 margin-b10' key={index}>
                      <div className='col margin-r10'>
                        <div>{item.district}</div>
                        <div>{item.province + item.city}</div>
                      </div>
                      <div onClick={this.delArea.bind(this, index)} className='fz18 cursor-pointer w35 h35 back-color11 radius35 col color0'>✖</div>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div className='w100 row-l margin-b20'>
            <div className='w80'>新增区域:</div>
            <Regin value={provinces} onChange={this.changeProvince}></Regin>
            <Button onClick={this.newArea} type='primary' className='margin-l10'>新增</Button>
          </div>
          <div className='w100 row-l margin-b20'>
            <div className='w80'>签约金:</div>
            <InputNumber value={data.price} onChange={this.changeInfo.bind(this, 'price')} className='w150' controls={false} />
          </div>
          <div className='row-l vertical-lt'>
            <div className='w80'>备注：</div>
            <Input.TextArea value={data.plat_remark} onChange={this.changeInfo.bind(this, 'plat_remark')} className='w550' rows={3} />
          </div>
        </div>
      </Modal>
    );
  }
  changeInfo = (type, e) => {
    let data = { ...this.state.data };
    if (type === 'plat_remark') {
      data[type] = e.target.value;
    } else {
      data[type] = e;
    }
    this.setState({
      data
    });
  }
  delArea = (index) => {
    let data = _.cloneDeep(this.state.data);
    data.area.splice(index, 1);
    this.setState({
      data,
    });
  }
  changeProvince = (e) => {
    this.setState({
      provinces: e,
    });
  }
  newArea = () => {
    const { provinces } = this.state;
    let data = _.cloneDeep(this.state.data);
    if (!provinces[0]) {
      message.warn('未选择');
      return;
    }
    let sameExit = false;
    data.area.map(item => {
      if (item.province === provinces[0] && item.city === provinces[1] && item.district === provinces[2]) {
        sameExit = true;
      }
    })
    if (sameExit) {
      message.warn('已存在');
      return;
    } else {
      data.area.push({
        province: provinces[0],
        city: provinces[1],
        district: provinces[2]
      });
      this.setState({
        data,
      });
    }

  }
  onOk = async () => {
    const { data, workInfo } = this.state;
    let newData = _.cloneDeep(this.state.data);
    newData.area = JSON.stringify(newData.area);
    let res = await postInvite(newData);
    if (res.status === 200) {
      this.onCancel();
      this.props.update();
    }
  }
  onShow = (workInfo) => {
    let data = { ...init };
    data.worker = workInfo.id;
    data.area = workInfo.areas.map(item => {
      return ({
        province: item.province,
        city: item.city,
        district: item.district,
      });
    })
    this.setState({
      visible: true,
      workInfo, data,
    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
      data: { ...init },
      provinces: ['', '', ''],
    });
  }
}
export default InviteSignModal;
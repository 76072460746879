import React, { Component } from 'react';
import { message, Table, Input, Radio, Image, Select, Modal, Button, Popconfirm, } from 'antd';
import { getFeeds, delFeeds } from '../../services/feed';
import FeedDrawer from './feedDrawer';


class Feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],

    };
    this.feedDrawer = React.createRef();
    this.colums = [
      {
        title: '名称',
        align: 'center',
        width: 600,
        dataIndex: 'name',
      },
      {
        title: '是否与金额相关',
        align: 'center',
        width: 600,
        dataIndex: 'cash',
      },
      {
        title: '操作',
        align: 'center',
        width: 600,
        dataIndex: 'operate',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div onClick={this.showDrawer.bind(this, record, 'mon')} className='margin-r20 underline cursor-pointer color3'>编辑</div>
              <div onClick={this.showDrawer.bind(this, record, 'son')} className='margin-r20 underline cursor-pointer color3'>子问题</div>
              <Popconfirm
                title='您确定要删除该分类吗?'
                onConfirm={this.delFeed.bind(this, record)}
                okText='确认'
                cancelText='取消'
              >
                <div className='underline cursor-pointer color2'>删除</div>
              </Popconfirm>
            </div>
          );
        }
      },

    ];
  }
  componentDidMount() {
    this.getList();
  }
  render() {
    const { data, } = this.state;
    return (
      <div>
        <div className='row-l bar'>
          <Button type='primary' onClick={this.getList} className='margin-r20'>刷新</Button>
          <Button type='primary' onClick={this.showDrawer.bind(this, {}, 'mon')}>新增</Button>
        </div>
        <div className='bar'>
          <Table
            columns={this.colums}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
        </div>
        <FeedDrawer ref={this.feedDrawer} update={this.getList} />
      </div>
    )
  }
  getList = async () => {
    let res = await getFeeds();
    if (res.status === 200) {
      this.setState({
        data: res.data,
      });
    }
  }
  showDrawer = (e, type) => {
    this.feedDrawer.current.onShow(e, type);
  }
  delFeed = async (e) => {
    let res = await delFeeds(e.id);
    if (res.status === 204) {
      this.getList();
    }
  }
}
export default Feed;
import React, { Component } from 'react';
import { Table, Modal, Input, message, Button, Radio, Switch, Checkbox, InputNumber, } from 'antd';
import { DeleteOutlined, EyeOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styles from '../tableList/index.module.css';
import { getFeedback, putFeedback } from '../../../services/feed';
import { getDepartment_Data } from '../../../config/storage';
import Link from '../../../resource/png/masterManagement-link.png';
import { getShopVideos, getVideoInfo, getVideoPlay, getVideoSub, } from '../../../services/newOder';
import moment from 'moment';

const initFeed = {
  id: 0,
  type: '',
  price_shop: 0,
  price_worker: 0,
  state: '',
  content: '',
  isAsync: false,
};
class FeedbackModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      feedInfo: {
        pic: [],
        vod: [],
        follows: [],
        checkers: [],
      },
      orderInfo: {
        price_app: 0,
        add_app: 0,
      },
      backAll: {
        ...initFeed,
        type: '总体',
        state: '待处理',
      },
      backSin: {
        ...initFeed,
        type: '个人',
        state: '待处理',
      },
    };
    this.baseUrl = getDepartment_Data().service;
    this.role = getDepartment_Data().roles[0];
    this.selfId = getDepartment_Data().id;
    this.columns1 = [
      {
        title: '房间',
        align: 'center',
        dataIndex: 'room',
        width: 100,
        className: 'padding0',
      },
      {
        title: '长度(米)',
        align: 'center',
        dataIndex: 'width',
        width: 100,
        className: 'padding0',
      },
      {
        title: '品类',
        align: 'center',
        dataIndex: 'prop1',
        width: 100,
        className: 'padding0',
      },
      {
        title: '层数',
        align: 'center',
        dataIndex: 'room',
        width: 100,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div>{record.prop4 !== '' && JSON.parse(record.prop4).name}</div>
          )
        }
      },
      {
        title: '个数',
        align: 'center',
        dataIndex: 'prop2',
        width: 100,
        className: 'padding0',
      },
      {
        title: '价格',
        align: 'center',
        dataIndex: 'prop10',
        width: 100,
        className: 'padding0',
      },
    ];
    this.colums2 = [
      {
        title: '客服',
        align: 'center',
        dataIndex: 'name',
        width: 100,
        className: 'padding0',
      },
      {
        title: '时间',
        align: 'center',
        dataIndex: 'time',
        width: 80,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div>{moment(record.time).format('YYYY-MM-DD HH:mm:ss')}</div>
          )
        }
      },
      {
        title: '操作',
        align: 'center',
        dataIndex: 'content',
        width: 220,
        className: 'padding0',
      },
    ];
  }
  render() {
    const { visible, feedInfo, orderInfo, backAll, backSin } = this.state;
    let permiss = [9, 11].includes(this.role) ? true : false;
    feedInfo.checkers.map(item => {
      if (item.checker === this.selfId) {
        permiss = true;
      }
    })
    return (
      <Modal
        title='反馈详情'
        visible={visible}
        width={1360}
        onCancel={this.onCancel}
        destroyOnClose={true}
        footer={
          <div className='row-r w100'>
            <Button onClick={this.onCancel}>关闭</Button>
          </div>
        }
      >
        <div className='col-t vertical-lt'>
          <div className='row-l vertical-lt'>
            <div className='col-t vertical-lt w640 padding-r10'>
              <div className='row-l margin-b10'>
                <div className='fz20'>问题反馈：</div>
                <div>{feedInfo.feed_str}</div>
              </div>
              <div className='row-l'>
                <div className='w80'>问题类型：</div>
                <div>{feedInfo.detail_str}</div>
              </div>
              {
                feedInfo.cash === '是' &&
                <div className='row-l'>
                  <div className='w80'>费用：</div>
                  <div>商户：{feedInfo.price_shop}</div>
                  <div className='margin-l20'>师傅：{feedInfo.price_worker}</div>
                </div>
              }

              <div className='row-l vertical-lt w600'>
                <div className='w80'>问题描述：</div>
                <div className='w500'>
                  {feedInfo.remark}
                  <img src={Link} onClick={this.toCopy.bind(this, feedInfo.remark)} className='cursor-pointer' />
                </div>
              </div>
              {
                feedInfo.pic.length > 0 &&
                <div className='row-l'>
                  <div className='w80'>问题图片：</div>
                  <div className='w500 row-w'>
                    {
                      feedInfo.pic.map((item, index) => {
                        return (
                          <div key={index} className={`${styles['content']}`}>
                            <img
                              alt='暂无图片'
                              className={`${styles['pic2']}`}
                              src={`${this.baseUrl}/upload/${item}`}
                            />
                            <div className={`row ${styles['content-div']}`}>
                              <EyeOutlined
                                className={`${styles['pic-icon-E']}`}
                                onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                                theme='outlined'
                                title='预览'
                              />
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              }
              {
                feedInfo.vod.length > 0 &&
                <div className='row-l vertical-lt'>
                  <div className='w80'>问题视频：</div>
                  <div className='w500 row-w'>
                    {
                      feedInfo.vod.map((item, index) => {
                        return (
                          <div className={`${styles['content-video']} margin-r10`} key={index}>
                            <video controls='controls' className={styles['content-image']} src={item.videoUrl} />
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              }
              <div className='row-l margin-t10'>
                <div className=' row-l'>
                  <div className='w80'>申请人：</div>
                  <div>{feedInfo.sub_name}</div>
                </div>
                <div className='margin-l20'>申请时间：{feedInfo.submit_time}</div>
              </div>
              <div className='row-l margin-t10'>
                <div>关联订单：</div>
                <div>{feedInfo.order}
                </div>
                <div className='bord2 w70 tc radius5 color3 margin-l20'>{orderInfo.service}</div>
              </div>
              <div className='row-l vertical-lt margin-t10'>
                <div>客户信息：</div>
                <div className='col-t vertical-lt'>
                  <div>
                    {orderInfo.name} - {orderInfo.phone}
                    <img src={Link} onClick={this.toCopy2.bind(this, orderInfo)} className='cursor-pointer' />
                  </div>
                  <div>{orderInfo.province}{orderInfo.city}{orderInfo.district}{orderInfo.address}</div>
                </div>
              </div>
              <div className='row-l margin-t10'>
                <div>订单费用：</div>
                <div>{orderInfo.price_app + orderInfo.add_app}</div>
              </div>
              {
                orderInfo.service === '安装' && orderInfo.category === '窗帘' &&
                <div className='w100'>
                  <Table
                    columns={this.columns1}
                    dataSource={orderInfo.serviceDatas}
                    size={'small'}
                    className='w100'
                    rowKey={record => record.id}
                    bordered
                    pagination={false}
                  />

                </div>
              }

            </div>
            <div className='col-t vertical-lt w640 padding-l10'>
              {
                feedInfo.state === '待处理' && permiss &&
                <div className='col-t vertical-lt w100 margin-tb10'>
                  <div className='bottomLine w100'></div>
                  <div className='fz20'>反馈总体处理：</div>
                  <div className='row-l'>
                    <div>状态：</div>
                    <Radio.Group value={backAll.state} onChange={this.changeAll.bind(this, 'state')}>
                      <Radio value={'待处理'}>待处理</Radio>
                      <Radio value={'已处理'}>已处理</Radio>
                    </Radio.Group>
                    <Checkbox checked={backAll.isAsync} onChange={this.changeAll.bind(this, 'isAsync')}
                      className='margin-l20'>
                      是否同步到订单备注
                    </Checkbox>
                  </div>
                  {
                    feedInfo.cash === '是' &&
                    <div className='row-l margin-t10'>
                      <div>金额：</div>
                      <InputNumber value={backAll.price_shop} onChange={this.changeAll.bind(this, 'price_shop')}
                        className='w200 margin-r10' addonBefore='商户金额' />
                      <InputNumber value={backAll.price_worker} onChange={this.changeAll.bind(this, 'price_worker')}
                        className='w200' addonBefore='师傅金额' />
                    </div>
                  }
                  <div className='row-l vertical-lt margin-t10'>
                    <div>备注：</div>
                    <Input.TextArea value={backAll.content} onChange={this.changeAll.bind(this, 'content')}
                      className='w400' rows={4} />
                  </div>
                  <div className='row-l vertical-lt margin-t10'>
                    <Button onClick={this.onOk} type='primary'>确认</Button>
                  </div>
                </div>
              }

              {
                feedInfo.state === '待处理' && permiss &&
                <div className='col-t vertical-lt w100 margin-tb10'>
                  <div className='bottomLine w100'></div>
                  <div className='fz20'>反馈个人处理：</div>
                  <div className='row-l'>
                    <div>状态：</div>
                    <Radio.Group value={backSin.state} onChange={this.changeSin.bind(this, 'state')}>
                      <Radio value={'待处理'}>待处理</Radio>
                      <Radio value={'已处理'}>已处理</Radio>
                    </Radio.Group>

                  </div>
                  <div className='row-l vertical-lt margin-t10'>
                    <div>备注：</div>
                    <Input.TextArea value={backSin.content} onChange={this.changeSin.bind(this, 'content')}
                      className='w400' rows={4} />
                  </div>
                  <div className='row-l vertical-lt margin-t10'>
                    <Button onClick={this.onOk2} type='primary'>确认</Button>
                  </div>
                </div>
              }
              <div className='margin-t5 w100'>
                <Table
                  columns={this.colums2}
                  dataSource={feedInfo.follows}
                  size={'small'}
                  className='w100'
                  rowKey={record => record.id}
                  bordered
                />
              </div>
            </div>
          </div>

        </div>
      </Modal>
    );
  }
  getInfo = async (id) => {
    let res = await getFeedback(id);
    if (res.status === 200) {
      let feedInfo = res.data.f;
      feedInfo.pic = JSON.parse(feedInfo.pic);
      feedInfo.vod = JSON.parse(feedInfo.vod);
      feedInfo.vod = res.data.f.vod.map(item => {
        return {
          videoId: item,
          videoUrl: ''
        };
      });
      let that = this;
      feedInfo.vod.map((item, index) => {
        (async () => {
          let local = index;
          let rsp = await getVideoPlay(item.videoId);
          if (rsp.status === 200) {
            feedInfo.vod[local].videoUrl = rsp.data.playURL;
            that.setState({
              feedInfo
            });
          }
        })()
      });
      let backAll = {
        ...initFeed,
        type: '总体',
        state: '待处理',
      };
      if (feedInfo.cash === '是') {
        backAll.price_shop = feedInfo.price_shop;
        backAll.price_worker = feedInfo.price_worker;
      }
      this.setState({
        feedInfo, backAll,
        orderInfo: res.data.o,
      });
    }
  }
  changeAll = (type, e) => {
    let backAll = { ...this.state.backAll };
    switch (type) {
      case 'state':
      case 'content':
        backAll[type] = e.target.value;
        break;
      case 'price_shop':
      case 'price_worker':
        backAll[type] = e ? e : 0;
        break;
      case 'isAsync':
        backAll[type] = e.target.checked;
        break;
    }
    this.setState({
      backAll
    });
  }
  changeSin = (type, e) => {
    let backSin = { ...this.state.backSin };
    switch (type) {
      case 'state':
      case 'content':
        backSin[type] = e.target.value;
        break;
      case 'price_shop':
      case 'price_worker':
        backSin[type] = e ? e : 0;
        break;
      case 'isAsync':
        backSin[type] = e.target.checked;
        break;
    }
    this.setState({
      backSin
    });
  }
  onOk = async () => {
    const { backAll, feedInfo } = this.state;
    backAll.id = feedInfo.id;
    let res = await putFeedback(backAll);
    if (res.status === 204) {
      this.onCancel();
      this.props.update();
    }
  }
  onOk2 = async () => {
    const { backSin, feedInfo } = this.state;
    backSin.id = feedInfo.id;
    let res = await putFeedback(backSin);
    if (res.status === 204) {
      this.onCancel();
    }
  }
  onShow = (e) => {
    this.setState({
      visible: true,
    })
    this.getInfo(e.id);
  }
  onCancel = () => {
    this.setState({
      visible: false,
      backAll: {
        ...initFeed,
        type: '总体',
        state: '待处理',
      },
      backSin: {
        ...initFeed,
        type: '个人',
        state: '待处理',
      },
      orderInfo: {
        price_app: 0,
        add_app: 0,
      },
    })
  }
  preview = (url) => {
    let features = 'height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no';
    window.open(url, 'newW', features);
  }
  toCopy = (e) => {
    var aux = document.createElement("textarea");
    aux.value = e;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }
  //复制
  toCopy2 = (data) => {
    var aux = document.createElement("textarea");
    aux.value = `${'商家:' + data.shop_name + '\n' + '姓名:' + data.name + '\n' + '手机号:' + data.phone + '\n' + '地址:' + data.province + data.city + data.district + data.address
      }`;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }
}
export default FeedbackModal;
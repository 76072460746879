import React, { Component } from 'react';
import { Input, Modal, Radio, message, InputNumber, Table, Button } from 'antd';
import styles from './modal.module.css';
import { getReduce, delReduce } from '../../services/sizeManagement';
import _ from 'lodash';

class SizeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      index: -1,
      currentData: {
      },
      reduce: null,
      reduceName: '',
      addRemark: '',
      finishSize: 0,
      sizeData: [],
    }
    this.columns = [
      {
        title: '名称',
        align: 'center',
        dataIndex: 'content',
        width: 250,
      },
      {
        title: '安装方式',
        align: 'center',
        dataIndex: 'method',
        width: 100,
        render: (text, record, index) => {
          return (
            <div>{record.product + record.methed}</div>
          );
        }
      },
      {
        title: '高度尺寸',
        align: 'center',
        dataIndex: 'height',
        width: 80,
      },
      {
        title: '备注',
        align: 'center',
        dataIndex: 'remark',
        width: 280,
      },
      {
        title: '选择',
        align: 'center',
        dataIndex: 'operate',
        width: 100,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <Button onClick={this.chooseSize.bind(this, record)} type='primary'>选择</Button>
          );
        }
      },
    ];
    this.columns2 = [
      {
        title: '名称',
        align: 'center',
        dataIndex: 'content',
        width: 250,
      },
      {
        title: '安装方式',
        align: 'center',
        dataIndex: 'method',
        width: 120,
        render: (text, record, index) => {
          return (
            <div>{record.product + record.methed}</div>
          );
        }
      },
      {
        title: '宽度尺寸',
        align: 'center',
        dataIndex: 'width',
        width: 100,
      },
      {
        title: '备注',
        align: 'center',
        dataIndex: 'remark',
        width: 200,
      },
      {
        title: '选择',
        align: 'center',
        dataIndex: 'operate',
        width: 100,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <Button onClick={this.chooseSize2.bind(this, record)} type='primary'>选择</Button>
          );
        }
      },
    ];
  }
  render() {
    const { visible, sizeData, currentData, reduce, reduceName, addRemark, finishSize } = this.state;

    return (
      <Modal
        title='成品尺寸'
        visible={visible}
        // style={{
        //   top: 100
        // }}
        centered
        onCancel={this.onCancel}
        onOk={this.onOk}
        width={1100}
        okText='确定'
        cancelText='取消'
      >
        <div className='col-t vertical-lt'>
          <div className='row-l margin-b10'>
            {
              currentData.prop1 && currentData.prop1 === '成品帘' ?
                <InputNumber disabled value={currentData.width} className='w300' addonBefore='宽度尺寸' addonAfter='米' />
                : <InputNumber disabled value={currentData.height} className='w300' addonBefore='高度尺寸' addonAfter='米' />
            }
            <div className='padding-lr5'>—</div>
            <InputNumber min={0} value={reduce} onChange={this.changeReduce} className='w200' addonBefore='扣减尺寸' addonAfter='米' />
            <div className='padding-lr5'>=</div>
            {/* {
              currentData.prop1 && currentData.prop1 === '成品帘' ?
                <InputNumber
                  value={reduce && Number(currentData.width - reduce) ? Number(currentData.width - reduce).toFixed(3) : currentData.width}
                  className='w300' addonBefore='成品尺寸' addonAfter='米' />
                :
                <InputNumber
                  value={reduce && Number(currentData.height - reduce) ? Number(currentData.height - reduce).toFixed(3) : currentData.height}
                  className='w300' addonBefore='成品尺寸' addonAfter='米' />
            } */}
          </div>
          <div className='row-l vertical-lt margin-b10'>
            <Input value={finishSize} onChange={this.changeFinish} className='w300' addonBefore='成品尺寸' addonAfter='米' />
          </div>
          <div className='row-l vertical-lt margin-b10'>
            <div>扣减名称：</div>
            <Input.TextArea disabled value={reduceName} className='w500' rows={2} />
            <Button onClick={this.emptyReduce} className='margin-l10' type='danger'>清除</Button>
          </div>
          <div className='row-l vertical-lt margin-b10'>
            <div>附加说明：</div>
            <Input.TextArea value={addRemark} onChange={this.changeReduceName} className='w500' rows={3} />
          </div>
          <Table
            columns={currentData.prop1 && currentData.prop1 === '成品帘' ? this.columns2 : this.columns}
            dataSource={sizeData}
            size={'small'}
            rowKey={record => record.id}
            bordered
            className='w100'
            pagination={false}

            scroll={{
              y: 430,
            }}
          />
        </div>
      </Modal>
    );
  };
  changeReduce = (e) => {
    const { currentData, } = this.state;
    let finishSize = this.state.finishSize;
    let finishbefore = currentData.prop1 === '成品帘' ? currentData.width : currentData.height;
    if (!isNaN(Number(finishbefore))) {
      finishSize = (Number(finishbefore) - e).toFixed(3);
    }
    this.setState({
      reduce: e,
      finishSize,
    });
  }
  changeFinish = (e) => {
    this.setState({
      finishSize: e.target.value,
    });
  }
  changeReduceName = (e) => {
    this.setState({
      addRemark: e.target.value
    });
  }
  emptyReduce = () => {
    this.setState({
      reduceName: ''
    });
  }
  chooseSize = (e) => {
    const { currentData, } = this.state;
    let finishSize = this.state.finishSize;
    let finishbefore = currentData.prop1 === '成品帘' ? currentData.width : currentData.height;
    if (!isNaN(Number(finishbefore))) {
      finishSize = (Number(finishbefore) - e.height).toFixed(3);
    }

    this.setState({
      reduce: e.height,
      reduceName: e.content,
      finishSize,
    });
  }
  chooseSize2 = (e) => {
    const { currentData, } = this.state;
    let finishSize = this.state.finishSize;
    let finishbefore = currentData.prop1 === '成品帘' ? currentData.width : currentData.height;
    if (!isNaN(Number(finishbefore))) {
      finishSize = (Number(finishbefore) - e.width).toFixed(3);
    }
    this.setState({
      reduce: e.width,
      reduceName: e.content,
      finishSize,
    });
  }
  getReduce = async (category, install) => {
    let res = await getReduce(category, install);
    if (res.status === 200) {
      this.setState({
        sizeData: res.data,
      });
    }
  }
  onShow = (index, data) => {
    let install = '';
    let reduce = null;
    let reduceName = '';
    let addRemark = '';
    let finishSize = '';
    if ((data.prop1 + data.prop2).includes('罗马杆侧装')) {
      install = data.prop2.split('|')[0];
    } else {
      install = data.prop2;
    }
    if (data.prop18) {
      finishSize = data.prop18;
      let finishbefore = data.prop1 === '成品帘' ? data.width : data.height;
      if (isNaN(Number(data.prop18)) || isNaN(Number(finishbefore))) {
        reduce = null;
      } else {
        reduce = Number(finishbefore - data.prop18).toFixed(3);
      }
    }
    if (data.prop8) {
      if (data.prop8.includes('|')) {
        reduceName = data.prop8.split('|')[0];
        addRemark = data.prop8.split('|')[1];
      } else {
        // addRemark = data.prop8;
        reduceName = data.prop8;
      }
    }
    this.getReduce(data.prop1, install);
    this.setState({
      visible: true,
      currentData: data,
      index,
      reduce, addRemark, reduceName, finishSize,
    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
      index: -1,
      reduce: null,
      reduceName: '',
      addRemark: '',
      finishSize: '',
    });
  }
  onOk = () => {
    // prop18 成品尺寸，prop8,说明
    const { reduce, reduceName, addRemark, index, finishSize } = this.state;
    let currentData = _.cloneDeep(this.state.currentData);
    // if (reduce === null) {
    //   currentData.prop18 = '';
    // } else {
    //   if (currentData.prop1 === '成品帘') {
    //     currentData.prop18 = currentData.width && !isNaN(currentData.prop18) ? Number(currentData.width - reduce).toFixed(3) : '';
    //   } else {
    //     currentData.prop18 = currentData.height && !isNaN(currentData.prop18) ? Number(currentData.height - reduce).toFixed(3) : '';
    //   }
    // }
    currentData.prop18 = finishSize;
    currentData.prop8 = reduceName + '|' + addRemark;
    if (!currentData.prop18) {
      currentData.prop18 = '';
    }
    if (currentData.prop8 === '|') {
      currentData.prop8 = ''
    }
    this.props.upload(index, currentData);
    this.onCancel();
  }
}
export default SizeModal;
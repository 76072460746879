import React, { Component } from 'react';
import TableList from '../tableList';
import { getOrderList, putUrgecontact, delReminder, putCheckorder } from '../../../services/orderCore';
import PageTurning from '../../../components/pageTurning';
import { Checkbox, Input, Button, message } from 'antd';
import styles from '../noAppointmentOrder/index.module.css';
import { jsToExcel2 } from '../../../utils/exportExcel';
import moment from 'moment';
import { connect } from 'dva';

const typeList = [
  {
    name: '6小时内未预约',
    value: '未预约',
  },
  {
    name: '加急',
    value: '加急',
  },
  {
    name: '催单',
    value: '催联系',
  },
  {
    name: '超时未验收',
    value: '超时',
  },
  {
    name: '审核',
    value: '待审核',
  },
  {
    name: '今日上门',
    value: '今日上门',
  },
  {
    name: '明日上门',
    value: '明日上门',
  },
  {
    name: '超时未上门',
    value: '超时未上门',
  },
  {
    name: '转上门',
    value: '转上门',
  },
];
const dateFormat = 'YYYY-MM-DD';
let startDate = moment(new Date()).subtract(1, 'months').format(dateFormat);
let endDate = moment(new Date()).add(1, 'days').format(dateFormat);
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInfo: {
        startDates: startDate,
        endDates: endDate,
        search: '',
        myCheckbox: true,
        page: typeList[0].value,
        skip: 0,
        take: 20,
      },
      loading: false,
      selectedRowKeys: [],
      data: [],
      download: false,
    }
  }
  componentDidMount() {
    const { dispatch } = this.props;
    if (this.props.type === '') {
      this.getDataList()
    } else if (['转上门'].includes(this.props.type)) {
      let searchInfo = { ...this.state.searchInfo };
      if (this.props.type === '催单中') {
        searchInfo.page = '催联系';
      } else if (this.props.type === '加急订单') {
        searchInfo.page = '加急';
      } else if (this.props.type === '今日上门订单') {
        searchInfo.page = '今日上门';
      } else if (this.props.type === '明日上门订单') {
        searchInfo.page = '明日上门';
      } else if (this.props.type === '超时未上门订单') {
        searchInfo.page = '超时未上门';
      } else {
        searchInfo.page = this.props.type;
      }
      dispatch({
        type: 'orderRenter/panelToSum',
        payload: '',
      });
      this.setState({
        searchInfo
      }, () => this.getDataList());
    }
  }
  render() {
    const { searchInfo, loading, data, selectedRowKeys, download } = this.state;
    let onType = '';
    let rowSelection = false;
    if (searchInfo.page === '未预约') {
      onType = '催单';
      rowSelection = true;
    } else if (searchInfo.page === '催联系') {
      onType = '催单处理';
      rowSelection = true;
    } else if (searchInfo.page === '待审核') {
      onType = '待审核';
      rowSelection = true;
    }
    return (
      <div className='w100'>
        <div className=' col-t vertical-lt back-color0 padding1020'>
          <div className='row-l'>
            <div className='fz18 tr'>订单类型：</div>
            <div className='row-l'>
              {
                typeList.map((item, index) => {
                  return (
                    <div className={`bar-item fz16 ${item.value === searchInfo.page ? 'bar-item-color' : ''}`}
                      key={index} onClick={this.changeInfo.bind(this, 'page', item.value)}
                    >
                      {item.name}
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div className='row-sb margin-t10 w100'>
            <div className='row-l'>
              <Input value={searchInfo.search} onChange={this.changeInfo.bind(this, 'search')} />
              <Button type='primary' onClick={this.getDataList}>搜索</Button>
              <div className='fz16 w200 tr'>我的区域：</div>
              <Checkbox checked={searchInfo.myCheckbox} onChange={this.changeInfo.bind(this, 'myCheckbox')} />
            </div>
            {
              searchInfo.page === '未预约' &&
              <Button loading={download} onClick={this.toExcel} type='primary'>表格导出</Button>
            }
          </div>
          <div className='row-l margin-t10'>
            {
              searchInfo.page === '未预约' &&
              <div className={styles['reminder_styles']} onClick={this.onReminder}>
                {`批量催单(${selectedRowKeys.length})`}
              </div>
            }
            {
              searchInfo.page === '催联系' &&
              <div className={styles['reminder_styles']} onClick={this.onReminder}>
                {`批量结束催单(${selectedRowKeys.length})`}
              </div>
            }
            {
              searchInfo.page === '待审核' &&
              <div className={styles['reminder_styles']} onClick={this.onReminder}>
                {`批量审核(${selectedRowKeys.length})`}
              </div>
            }
          </div>
        </div>
        <div className='w100 margin-t10 back-color0 '>
          <TableList setDisplay={this.setDisplay}
            data={data}
            onType={onType}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
            onSelectChange={this.onSelectChange}
            // updateTable={this.updateTables}
            // delLabelData={this.delLabelData}
            // updateTipData={this.updateTipData}
            onHoverSet={this.onHoverSet}
            getDataList={this.getDataList}
            loading={loading}
          />
          <PageTurning pageMaps={this.pageMaps} pageSize={20} />
        </div>
      </div>
    );
  }
  changeInfo = (type, e) => {
    let searchInfo = { ...this.state.searchInfo };
    let searchVisible = false;
    switch (type) {
      case 'page':
        searchInfo[type] = e;
        searchVisible = true;
        break;
      case 'search':
        searchInfo[type] = e.target.value;
        break;
      case 'myCheckbox':
        searchInfo[type] = e.target.checked;
        searchVisible = true;
        break;
    }
    this.setState({
      searchInfo,
    }, () => {
      if (searchVisible) {
        this.getDataList();
      }
    });
  }
  getDataList = async () => {
    let { searchInfo } = this.state;
    this.setState({
      loading: true,
    });
    let res = await getOrderList({
      search: searchInfo.page,
      addition: '全部',
      info: searchInfo.search,
      category: '全部',
      service: '全部',
      type: '全部',
      state: '全部',
      start: searchInfo.startDates,
      end: searchInfo.endDates,
      worker: 0,
      shop: 0,
      user: 0,
      mode: '下单时间',
      myarea: searchInfo.myCheckbox,
      trans: '全部',
      label: '全部',
      skip: searchInfo.skip,
      take: searchInfo.take,
    })
    if (res.status !== 200) {
      message.error(res.data);
      this.setState({
        loading: false,
      });
      return;
    }
    let data = res.data;
    data.forEach(item => {
      item.workerDisplay = false;
    })
    this.setState({
      data: data,
      loading: false,
    })
  }
  setDisplay = (index) => {
    let data = _.cloneDeep(this.state.data);
    data[index].workerDisplay = !data[index].workerDisplay;
    this.setState({
      data: data
    })
  }
  onHoverSet = (index, props, value) => {
    let data = [...this.state.data];
    data[index][props] = value;
    this.setState({
      data
    });
  }
  //换页
  pageMaps = ({ skip, take }) => {
    let { searchInfo } = this.state;
    searchInfo.skip = skip;
    this.setState({
      searchInfo,
    }, async () => this.getDataList())
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys: selectedRowKeys
    });
  }
  onReminder = async () => {
    const { selectedRowKeys, searchInfo } = this.state;
    let res
    if (searchInfo.page === '未预约') {
      res = await putUrgecontact(selectedRowKeys)
    } else if (searchInfo.page === '催联系') {
      let data = {
        ids: selectedRowKeys,
        type: '订单',
      };
      res = await delReminder(data)
    } else if (searchInfo.page === '待审核') {
      res = await putCheckorder(selectedRowKeys)
    }
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
    this.setState({
      selectedRowKeys: []
    })
    this.getDataList()
  }
  toExcel = async () => {
    let { searchInfo, page } = this.state;
    let down = true;
    let data = [];
    let dataSource = [];
    this.setState({
      download: true,
    })
    let skip = 0;
    let take = 100;
    while (down === true) {
      let res = await getOrderList({
        search: searchInfo.page,
        addition: '全部',
        info: searchInfo.search,
        category: '全部',
        service: '测量',
        type: '全部',
        state: '全部',
        start: searchInfo.startDates,
        end: searchInfo.endDates,
        worker: 0,
        shop: 0,
        user: 0,
        mode: '下单时间',
        myarea: searchInfo.myCheckbox,
        trans: '全部',
        skip: skip,
        take: take,
        isexport: false,
      })
      if (res.status === 200) {
        dataSource = dataSource.concat(res.data)
        down = res.data.length < take ? false : true;
        skip = skip + res.data.length;
      } else {
        down = false;
      }
    }
    let title = [
      {
        title: '订单信息',
        key: 'orderInfo',
      },
      {
        title: '客户信息',
        key: 'cumstomerInfo',
      },
      {
        title: '下单信息',
        key: 'shopInfo',
      },
      {
        title: '师傅信息',
        key: 'masterInfo',
      },
      {
        title: '预约时间',
        key: 'appoint',
      },
      {
        title: '商户费用',
        key: 'shopPrice',
      },
      {
        title: '师傅费用',
        key: 'masterPrice',
      },
    ];
    let title2 = [
      {
        title: '师傅名称',
        key: 'name',
      },
      {
        title: '师傅电话',
        key: 'phone',
      },
    ];
    data = dataSource.map(item => {
      let order2 = {
        orderInfo: `${item.id}\r${item.category}-${item.type}\r [${item.state}]\r [${item.order_way}]`,
        cumstomerInfo: `${item.name}${item.phone}\n${item.province}${item.city}${item.district}${item.address}`,
        shopInfo: ` ${item.shopName}-${item.shopRank} \r\n${item.create_time} ${item.operater} ${item.addition ? item.addition : ''}`,
        masterInfo: `${item.workerName ? item.workerName + '-' + item.workerRank : ''} ${item.workerPhone}`,
        appoint: item.appoint_time !== '0001-01-01T00:00:00' ? moment(item.appoint_time).format(dateFormat) : '',
        shopPrice: item.price_app + item.add_app,
        masterPrice: item.price_oms + item.add_oms,
      };
      return order2;
    });
    let data2 = dataSource.map(item => {
      let order2 = {
        name: item.workerName,
        phone: item.workerPhone,
      };
      return order2;
    });
    jsToExcel2(title2, data2, searchInfo.page + '列表', '');
    // jsToExcel2(title, data, searchInfo.page + '列表', '');
    this.setState({
      download: false,
    })
  }
}
// export default Index;
export default connect(({ orderRenter }) => (orderRenter))(Index);
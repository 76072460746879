import React, { useEffect, useState, Component } from 'react';
import styles from './index.module.css';
import Option from '../../components/option';
import InfoModal from './modal';
import WorkerModal from '../orderCore/allOrder/workerModal';
import PageTurning from '../../components/pageTurning';
import { message, Table, Input, Radio, Image, Button, Select } from 'antd';
import person from '../../resource/png/masterManagement-person.png';
import link from '../../resource/png/masterManagement-link.png';
import approve from '../../resource/png/masterManagement-approve.png';
import signed from '../../resource/png/masterManagement-signed.png';
import { getDepartment_Data } from '../../config/storage';
import { correctExcel } from '../../utils/exportExcel';
import { getShop, getShopCount, getShopFreeze } from '../../services/shopManagement';
import { getTemplate, } from '../../services/templateManagement';
import moment from 'moment';
const title = ['全部', '待认证', '已认证'];
const status = ['全部', '启用', '关闭'];
const sorts = [
  {
    key: '',
    label: '默认',
  },
  {
    key: '信用额度',
    label: '信用额度',
  }
];
const hidePhone = (e) => {
  if (e.length === 11) {
    return e.substring(0, 3) + '****' + e.substring(7);
  } else {
    return '';
  }
}
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicerList: [],
      servicers: [],
      manager: {
        id: 0,
        name: '全部',
      },
      info: '',
      check: '全部',
      enable: '全部',
      shopList: [],
      currentShop: {},
      modalType: '',
      skip: 0,
      take: 10,
      role: 0,
      balance: 0,
      sort: '',
      freezePrice: 0,
      download: false,
    };
    this.infoModal = React.createRef();
    this.workerModal = React.createRef();
    this.pageModal = React.createRef();
  }
  componentDidMount() {
    const { take } = this.state;
    let { roles, name, id } = getDepartment_Data();
    let manager = {
      id: 0,
      name: '全部',
    };
    if (roles[0] === 1) {
      manager.id = id,
        manager.name = name
    }
    this.setState({
      role: roles[0],
      manager,
    }, () =>
      this.getShopList(manager.id, '', '全部', '全部', '全部', 0, take)
    )
    this.getCount()
    this.getFreeze()
    // let manager=role===1?role:0;
    // let a = new Date().toLocaleDateString();
  }
  render() {
    const { servicerList, sort, manager, info, enable, shopList, role, balance,
      freezePrice, download } = this.state;
    const columns = [
      {
        title: '商家基本信息',
        align: 'center',
        dataIndex: 'info',
        width: 330,
        render: (text, record, index) => {
          return (
            <div className={`${styles['fixedwidth330']} col-l fz14`} key={index}>
              <div className='row-l w100'>
                <Image src={person} className={styles['img']} preview={false} />
                <div className='col-t vertical-lt w100'>
                  <div className='row-sb w100 margin-l5'>
                    <div>编号：{record.id}</div>
                    <Image onClick={this.onCopyToClip.bind(this, record)} src={link} className={`${styles['img']} cursor-pointer`} preview={false} />
                  </div>
                  <div className='row-sb w100 margin-l5'>
                    <div className='w198 oneline tl'>{record.name}</div>
                    <div className='w100px oneline tr'>{[9, 11].includes(role) ? record.phone : hidePhone(record.phone)}</div>
                  </div>
                </div>
              </div>
              <div className='oneline tl'>{record.province}{[9, 11].includes(role) ? record.city + record.district + record.address : ''}</div>
            </div>
          );
        }
      },
      {
        title: '认证状态',
        align: 'center',
        dataIndex: 'checked',
        width: 110,
        // className: styles['fixedwidth110'],
        render: (text, record, index) => {
          return (
            record.checked === '是' ? <Image src={approve} className={styles['img']} preview={false} />
              : <div className='color2 back-color6'>未认证</div>
          )
        }
      },
      {
        title: '启用状态',
        align: 'center',
        dataIndex: 'enable',
        width: 100,
        // className: styles['fixedwidth110'],
        render: (text, record, index) => {
          return (
            record.enable === '是' ? <div className='color6 back-color7'>已启用</div>
              : <div className='color2 back-color6'>已关闭</div>
          )
        }
      },
      {
        title: '销售单支持',
        align: 'center',
        dataIndex: 'sale',
        width: 80,
      },
      {
        title: '信用额度',
        align: 'center',
        dataIndex: 'debt',
        width: 100,
        // className: styles['fixedwidth110'],
      },
      {
        title: '商户余额',
        align: 'center',
        dataIndex: 'balance',
        width: 100,
        // className: styles['fixedwidth110'],
      },
      {
        title: '负责人',
        align: 'center',
        dataIndex: 'mname',
        width: 110,
        // className: styles['fixedwidth110'],

      },
      {
        title: '合作备注',
        align: 'center',
        dataIndex: 'remark',
        width: 220,
        // className: styles['fixedwidth220'],
        render: (text, record, index) => {
          return (<div className='twoline'>{record.remark}</div>)
        }
      },
      {
        title: '费用备注',
        align: 'center',
        dataIndex: 'price_remark',
        width: 220,
        // className: styles['fixedwidth220'],
        render: (text, record, index) => {
          return (<div className='twoline'>{record.price_remark}</div>)
        }
      },
      {
        title: '是否显示师傅电话',
        align: 'center',
        dataIndex: 'show_worker',
        width: 100,
        // className: styles['fixedwidth100'],
        render: (text, record, index) => {
          return (<div className='twoline'>{record.show_worker === '是' ? '是' : '否'}</div>)
        }
      },
      {
        title: '注册时间',
        align: 'center',
        dataIndex: 'join_time',
        width: 110,
        // className: styles['fixedwidth110'],
        render: (text, record, index) => {
          return (<div >{moment(record.join_time).format('YYYY-MM-DD')}</div>)
        }
      },
      {
        title: '操作',
        align: 'center',
        dataIndex: 'operation',
        width: 240,
        // className: styles['fixedwidth220'],
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div onClick={this.showEdition.bind(this, record, 'edit')} className={`${styles['btn']} back-color8 color0 margin-r5 cursor-pointer`}>编辑</div>
            </div>
          )
        }
      },
    ]
    return (
      <div className='col-t w100'>
        {/* <div className={`${styles['total-credit']} back-color9 color0 w100 tc fz24 margin-b10`}>商户信用额度总计：413500.00</div> */}
        <Option title={title} top={0} changeMyTop={this.changeSummary.bind(this, 'check')} className='margin-b10 '>
          <div className='col-t vertical-lt padding20 w100'>
            <div className='row-sb margin-t10 margin-b10 w100'>
              <div className='row-l'>
                <div>负责客服：</div>
                <Input disabled className='w126' value={manager.name} />
                {
                  role === 1 ? <div></div>
                    :
                    <Button type='primary' className='margin-l10' onClick={this.openModal.bind(this, '客服')}>选择</Button>
                }
                <div className='margin-l20'>启用状态：</div>
                <Radio.Group onChange={this.changeSummary.bind(this, 'enable')} value={enable} size='large'>
                  {
                    status.map((item, index) => {
                      return <Radio value={item} key={index} checked={enable === item ? true : false} className='fz16'>{item}</Radio>;
                    })
                  }
                </Radio.Group>
                <div className='margin-l20'>排序：</div>
                <Select value={sort} onChange={this.changeSort} className='w140'>
                  {
                    sorts.map((item, index) => {
                      return (
                        <Select.Option value={item.key} key={index}>{item.label}</Select.Option>
                      );
                    })
                  }
                </Select>
              </div>
              <div onClick={this.emptySeatch} className={`${styles['btn']} tc margin-l20 cursor-pointer`}>重置</div>
            </div>
            <div className='row-sb w100'>
              <div className='row-l'>
                <div>商户信息：</div>
                <Input onChange={this.changeSummary.bind(this, 'info')} value={info} placeholder='请输入姓名/手机号码' className={styles['input-service']} />
                <div onClick={this.toSearch} className={`${styles['btn']} tc margin-l20 back-color5 color0 cursor-pointer`}
                >搜索</div>
                <div onClick={this.showEdition.bind(this, {}, 'add')} className={`${styles['btn']} tc back-color5 color0 margin-l20 cursor-pointer`}
                >新增商户</div>
              </div>
              {/* <Button onClick={this.toExcel} loading={download} type='primary'>导出excel</Button> */}
            </div>
          </div>
        </Option >
        <Table
          columns={columns}
          dataSource={shopList}
          size={'small'}
          rowKey={record => record.id}
          bordered
          pagination={false}
        />
        <div className='back-color0 w100 row-sb'>
          {
            [9, 11].includes(role) ?
              <div className='row-l'>
                <div className='margin-lr20 fz18'>商户余额合计：{balance}</div>
                <Button type='primary' className='margin-l40' onClick={this.getCount}>更新</Button>
                <div className='margin-l40 margin-l20 fz18'>服务中的订单金额：{freezePrice}</div>
                <Button type='primary' className='margin-l40' onClick={this.getFreeze}>更新</Button>
              </div>
              :
              <div></div>
          }
          <PageTurning pageMaps={this.pageMaps} ref={this.pageModal} />
        </div>
        <InfoModal ref={this.infoModal} updateShop={this.update.bind(this)} />
        <WorkerModal
          ref={this.workerModal}
          updateTable={this.updateTable}
        />
      </div >
    );
  }
  changeSort = (e) => {
    this.setState({
      sort: e
    })
  }
  getShopList = async (manager, info, enable, rank, check, skip, take) => {
    const { sort } = this.state;
    let res = await getShop(manager, info, enable, rank, check, skip, take, sort);
    if (res.status === 200) {
      this.setState({
        shopList: res.data
      });
    }
  }
  getCount = async () => {
    let res = await getShopCount();
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      balance: res.data,
    });
  }
  getFreeze = async () => {
    let res = await getShopFreeze();
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      freezePrice: res.data,
    });

  }
  toSearch = () => {
    const { manager, info, enable, check, take } = this.state;
    let myEnable = enable === '全部' ? '全部' : enable === '启用' ? '是' : '否';
    let myCheck = check === '全部' ? '全部' : check === '已认证' ? '是' : '否';
    this.getShopList(manager.id, info, myEnable, '全部', myCheck, 0, take);
    this.pageModal.current.returnFirst();
    this.setState({
      skip: 0,
    })
  }
  search = () => {
    const { manager, info, enable, check, skip, take } = this.state;
    let myEnable = enable === '全部' ? '全部' : enable === '启用' ? '是' : '否';
    let myCheck = check === '全部' ? '全部' : check === '已认证' ? '是' : '否';

    this.getShopList(manager.id, info, myEnable, '全部', myCheck, skip, take);
  }
  emptySeatch = () => {
    const { role } = this.state;
    let manager = { ...this.state.manager };
    if (role !== 1) {
      manager = {
        id: 0,
        name: '全部',
      }
    }
    this.setState({
      manager,
      info: '',
      enable: '全部',
      sort: '',
    }, () => this.toSearch())
  }
  changeSummary = (type, e) => {
    switch (type) {
      case 'check':
        this.setState({
          check: title[e]
        }, () => {
          this.search();
        });
        break;
      case 'info':
        this.setState({
          info: e.target.value
        });
        break;
      case 'enable':
        this.setState({
          enable: e.target.value
        });
        break;

    }
  }
  updateTable = (row, type) => {
    if (row) {
      if (type === '客服') {
        this.setState({
          manager: row
        });
      }
    }
  }
  showEdition = (e, type) => {
    let data = {
      type: type,
      currentShop: e
    }
    this.infoModal.current.showDrawer(data);
    this.setState({
      modalType: type,
      currentShop: e,
    })
  }
  openModal = (e) => {
    this.workerModal.current.onShowModal(e);
  }
  onCopyToClip = (e) => {
    var aux = document.createElement("textarea");
    aux.value = `名称：${e.name + '\n'}手机号：${e.phone + '\n'}地址：${e.province + e.city + e.district + e.address}`;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }

  update = () => {
    this.search();
  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, () => this.search())
  }

  toExcel = async () => {
    let skip = 0;
    let take = 100;
    let dataSource = [];
    let down = true;
    this.setState({
      download: true,
    })
    let templateList = [];
    let rsp = await getTemplate('全部', 0, 200, '')
    if (rsp.status === 200) {
      templateList = rsp.data;
    }
    while (down === true) {
      let res = await getShop(0, '', '全部', '全部', '全部', skip, take, '');
      if (res.status === 200) {
        dataSource = dataSource.concat(res.data)
        down = res.data.length < take ? false : true;
        skip = skip + res.data.length;
      } else {
        down = false;
      }
    }

    // 
    let title = [
      {
        title: '编号',
        key: 'id',
        dataIndex: 'id',
      },
      {
        title: '商家名称',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: '电话',
        key: 'phone',
        dataIndex: 'phone',
      },
      {
        title: '地址',
        key: 'provinces',
        dataIndex: 'provinces',
      },
      {
        title: '信用额度',
        key: 'debt',
        dataIndex: 'debt',
      },
      {
        title: '余额',
        key: 'balance',
        dataIndex: 'balance',
      },


    ];
    let data = dataSource.map(item => {
      let exit = [];
      if (item.scripts[0]) {
        exit = templateList.filter(r => r.id === item.scripts[0].script);
      }
      return {
        id: item.id,
        name: item.name,
        phone: item.phone,
        provinces: item.province + item.city + item.district + item.address,
        debt: item.debt,
        balance: item.balance,

      };
    });
    correctExcel(title, data, '商户信息');
    this.setState({
      download: false,
    })
  }

}



export default Index;
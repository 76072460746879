import React, { Component } from 'react';
import { Input, Modal, Radio, InputNumber } from 'antd';

const floor = ['单层', '双层', '仅限单层', '建议单层', '建议双层'];//层数
const closed = ['单开', '双开', '建议单开', '建议双开', '特殊备注'];//开合方式
const window = ['普通窗户', '内开窗', '左L型窗', '右L型窗', 'U型窗', '异型窗', '落地窗'];
const window1 = ['普通窗户', '内开窗'];//窗户类型1
const window2 = ['左L型窗', '右L型窗', 'U型窗', '异型窗', '落地窗', '无'];//窗户类型2
const sizeGround = ['和客户沟通', '2-3公分', '持平'];
const material = ['混凝土', '普通瓷砖', '玻化砖', '金属', '保温墙', '石膏板', '木板'];//墙面材质
const judge = ['是', '否'];//是否超高
const curtain = ['有', '无'];
const finial = ['两边大头', '单侧平头', '双侧平头'];//装饰头
const rings = ['双层吊环', '单层吊环', '无吊环'];//吊环
class RemarksModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      index: 0,
      type: '',
      data: {},
      windowType1: '',
      windowType2: '',
      closeRemark: '',
    }
  }
  render() {
    const { visible, data, windowType1, windowType2, } = this.state;
    return (
      <Modal
        title='常用备注'
        visible={visible}
        onCancel={this.onCancel}
        onOk={this.onOk}
        width={670}
        okText='确定'
        cancelText='取消'
      >
        <div>
          {
            ['成品帘', '磁控百叶'].includes(data.prop1) ? '' :
              <div>
                {this.getRemarksColumns('层数', 'prop5', floor, data)}
                {this.getRemarksColumns('开合方式', 'prop6', closed, data)}
                {/* {this.getRemarksColumns('窗户类型', 'prop7', window, data)} */}
                <div className='row-l padding-b5 margin-b5 bottomLine'>
                  <div className='margin-r5 w80'>窗户类型1：</div>
                  <Radio.Group value={windowType1} className='row-w w360'>
                    {
                      window1.map((item, indexs) => {
                        return (
                          <Radio value={item} key={indexs} onChange={this.onRemarksType.bind(this, '1')}>{item}</Radio>
                        )
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='row-l padding-b5 margin-b5 bottomLine'>
                  <div className='margin-r5 w80'>窗户类型2：</div>
                  <Radio.Group value={windowType2} className='row-w w360'>
                    {
                      window2.map((item, indexs) => {
                        return (
                          <Radio value={item} key={indexs} onChange={this.onRemarksType.bind(this, '2')}>{item}</Radio>
                        )
                      })
                    }
                  </Radio.Group>
                </div>
                {/* {this.getRemarksColumns('离地尺寸', 'prop8', sizeGround, data)} */}
              </div>
          }
          {this.getRemarksColumns('墙面材质', 'prop9', material, data)}
          {
            ['成品帘', '磁控百叶'].includes(data.prop1) ? '' :
              this.getRemarksColumns('是否超高', 'prop10', judge, data)
          }
          {
            ['成品帘', '磁控百叶'].includes(data.prop1) ? '' :
              this.getRemarksColumns('是否拆旧', 'prop11', judge, data)
          }
          {
            data.prop1 === '罗马杆' && data.prop2 === '已安装' &&
            this.getRemarksColumns('吊环', 'prop13', rings, data)
          }
          {
            data.prop1 === '罗马杆' && (data.prop2 === '顶装' || data.prop2.includes('侧装')) &&
            this.getRemarksColumns('装饰头', 'prop13', finial, data)
          }
          {/* 离地要求：离地（）公分 */}
          <div className='row-l padding-b5 margin-b5 bottomLine'>
            <div className='margin-r5 w80'>离地要求：</div>
            <InputNumber value={data.prop19 === '无' ? '' : data.prop19} className='w110' placeholder='' addonAfter='公分' addonBefore='离地'
              onChange={this.onNumChange.bind(this, 'prop19')} />
          </div>
        </div>
      </Modal>
    );
  }
  getRemarksColumns = (type, prop, mapType, data) => {
    const { closeRemark } = this.state;
    if (JSON.stringify(data) === '{}') {
      return;
    }
    return (
      <div className='row-l padding-b5 margin-b5 bottomLine'>
        <div className='margin-r5 w80'>{type}：</div>
        <Radio.Group value={data[prop]} className='row-w w360'>
          {
            mapType.map((item, indexs) => {
              return (
                <Radio value={item} key={indexs} onChange={this.onRemarksChange.bind(this, prop)}>{item}</Radio>
              )
            })
          }
        </Radio.Group>
        {
          type === '开合方式' && data[prop] === '特殊备注' &&
          <Input value={closeRemark} className='w220' placeholder='特殊备注' onChange={this.changeClose} />
        }
      </div>
    )
  }
  onRemarksType = (type, e) => {
    let windowType1 = this.state.windowType1;
    let windowType2 = this.state.windowType2;
    if (type === '1') {
      windowType1 = e.target.value;
    } else {
      windowType2 = e.target.value;
    }
    this.setState({
      windowType1, windowType2
    })
  }
  onRemarksChange = (prop, e) => {
    let data = _.cloneDeep(this.state.data);
    data[prop] = e.target.value;
    this.setState({
      data: data
    })
  }
  onNumChange = (prop, e) => {
    let data = _.cloneDeep(this.state.data);
    data[prop] = e === null ? '' : e;
    this.setState({
      data: data
    })
  }
  getInputColumns = (type, prop, data) => {
    if (JSON.stringify(data) === '{}') {
      return;
    }
    return (
      <div className='row-w'>
        <div className='margin-r5'>{type}:</div>
        <Input value={data[prop]} onChange={this.onInputChange.bind(this, prop)} />
      </div>
    )
  }
  onInputChange = (prop, e) => {
    let data = _.cloneDeep(this.state.data);
    data[prop] = e.target.value;
    this.setState({
      data: data
    })
  }
  changeClose = (e) => {
    this.setState({
      closeRemark: e.target.value,
    });
  }
  onShowModal = (index, data, type) => {
    let windowType1 = data.prop7.split('|')[0];
    let windowType2 = data.prop7.split('|')[1] ? data.prop7.split('|')[1] : '无';
    let closeRemark = '';
    if (!closed.includes(data.prop6)) {
      closeRemark = data.prop6;
      data.prop6 = '特殊备注';
    }
    this.setState({
      visible: true,
      index: index,
      type: type,
      data: data,
      windowType1,
      windowType2,
      closeRemark,
    })
  }
  onOk = () => {
    const { index, data, windowType1, windowType2, closeRemark } = this.state;
    let newdata = _.cloneDeep(data);
    newdata.prop7 = windowType1 + '|' + windowType2;
    newdata.prop6 = newdata.prop6 === '特殊备注' ? closeRemark : newdata.prop6;
    newdata.prop19 = newdata.prop19.toString();
    // newdata.prop19 = newdata.prop19 || newdata.prop19 === 0 ? newdata.prop19 : '无';
    this.props.updateTable(newdata, index);
    this.onCancel()
  }
  onCancel = () => {
    this.setState({
      visible: false,
      closeRemark: '',
    })
  }
}

export default RemarksModal;
import React, { Component } from 'react';
import { message, Table, Input, Select, Modal, Button, DatePicker } from 'antd';
import { getSettleOrder, getServicerList, } from '../../services/analysisCenter';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

const dateForm = 'YYYY-MM-DD';
const monthAgo = moment(new Date()).subtract(31, 'days').format(dateForm);
const today = moment(new Date()).format(dateForm);

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicerList: [],
      info: {
        service: 0,
        start: monthAgo,
        end: today,
      },
      data: [],
      count: 0,
    };
    this.columns = [
      {
        title: '客服名称',
        dataIndex: 'name',
        align: 'center',
        width: 100,
      },
      {
        title: '整理数量',
        dataIndex: 'collect',
        align: 'center',
        width: 100,
      },
    ];
  }
  componentDidMount() {
    this.getServicer()
    this.getList()
  }
  render() {
    const { servicerList, info, data, count } = this.state;
    return (
      <div>
        <div className='bar row-l'>
          <div>选择客服：</div>
          <Select value={info.service} className='w180'
            onChange={this.changeInfo.bind(this, 'service')}>
            {
              servicerList.map((item, index) => {
                return (
                  <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                );
              })
            }
          </Select>
          <div className='margin-l20'>时间范围：</div>
          <DatePicker.RangePicker value={[moment(info.start), moment(info.end)]}
            onChange={this.changeInfo.bind(this, 'times')} locale={locale} allowClear={false} />
          <Button onClick={this.getList} type='primary'>搜索</Button>
          <div className='margin-l20 fz16 bold'>合计：{count}</div>
        </div>
        <div className='bar'>
          <Table
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={{ defaultPageSize: 20 }}
          />
        </div>
      </div>
    );
  }
  getServicer = async () => {
    let res = await getServicerList()
    if (res.status === 200) {
      let servicerList = res.data;
      servicerList.unshift({
        id: 0,
        name: '全部',
      })
      this.setState({
        servicerList: res.data
      });
    }
  }
  changeInfo = (type, e) => {
    let info = { ...this.state.info };
    switch (type) {
      case 'service':
        info[type] = e;
        break;
      case 'times':
        info.start = moment(e[0]).format(dateForm);
        info.end = moment(e[1]).format(dateForm);
        break;
    }
    this.setState({
      info,
    })
  }
  getList = async () => {
    const { info } = this.state;
    let res = await getSettleOrder(info.service, info.start, info.end);
    let count = 0;
    if (res.status === 200) {
      let data = [];
      res.data.map(item => {
        let local = data.findIndex(r => r.name === item.name);
        if (local < 0) {
          data.push(item);
        } else {
          data[local].collect += item.collect;
          data[local].day = item.day;
        }
      });
      let data2 = [];
      let keys = {
        '数据一号': 0,
        '数据二号': 1,
        '数据三号': 2,
        '数据四号': 3,
        '数据五号': 4,
        '数据六号': 5,
        '数据七号': 6,
        '数据八号': 7,
        '数据九号': 8,
        '数据十号': 9,
        '数据十一': 10,
        '数据十二': 11,
        '数据十三': 12,
        '数据十四': 13,
        '数据十五': 14,
      };
      data = data.filter(r => r.name.includes('数据'));
      data.map(item => {
        if (keys[item.name.slice(0, 4)] > -1) {
          if (data2[keys[item.name.slice(0, 4)]]) {
            let exit = new Date(data2[keys[item.name.slice(0, 4)]].day).getTime();
            let current = new Date(item.day).getTime();
            let deleteCol = data2[keys[item.name.slice(0, 4)]].collect;
            if (exit < current) {
              count = count + item.collect - deleteCol;
              data2[keys[item.name.slice(0, 4)]] = item;
            }
          } else {
            data2[keys[item.name.slice(0, 4)]] = item;
            count += item.collect;
          }
        }
      });
      this.setState({
        data: data2, count
      });
    }
  }
}
export default Index;

import React, { Component } from 'react';
import { message, Table, Button, DatePicker, Drawer } from 'antd';
import { getSingleOrder, getSingleFlow } from '../../services/analysisCenter';
import { getDepartment_Data } from '../../config/storage';
import { jsToExcel2 } from '../../utils/exportExcel';
import PageTurning from '../../components/pageTurning';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD'
const dateFormat2 = 'YYYY/MM/DD-HH:mm:ss';
const today = moment(new Date());
class SingleOrdeer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: today,
      end: today,
      dataSource: [],
      skip: 0,
      take: 100,
      billVisible: false,
      billSource: [],
      billtype: '',
      downLoad: false,
      shopPrice: 0,
      workPrice: 0,
    };
    this.role = getDepartment_Data().roles[0];
    this.columns = [
      {
        title: '订单信息',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='col'>
              <div>{record.id}-{record.service}</div>
              <div className='row-l'>
                {record.category + '-'}
                <span className='fw600 color4'>{record.type}</span>
              </div>
            </div>
          );
        }
      },
      {
        title: '客户信息',
        align: 'center',
        width: 80,
        render: (text, record, index) => {
          return (
            <div className='col-t'>
              <div>{record.name} {record.phone}</div>
              <div>{record.province + record.city + record.district + record.address}</div>
            </div>
          );
        }
      },
      {
        title: '结算信息',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='col-t'>
              <div>{record.shop_name}</div>
              <div>{moment(record.check_time).format(dateFormat2)}</div>
            </div>
          );
        }
      },
      {
        title: '师傅信息',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div>{record.worker_name}</div>
          );
        }
      },
      {
        title: '商户费用',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='color3 cursor-pointer underline' onClick={this.onShowBill.bind(this, record.sFinances, '商户')}>{record.price_app + record.add_app}</div>
          );
        }
      },
      {
        title: '师傅费用',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='color3 cursor-pointer underline' onClick={this.onShowBill.bind(this, record.wFinances, '师傅')}>{record.price_oms + record.add_oms}</div>
          );
        }
      },

    ];
    this.pageModal = React.createRef();
  }
  componentDidMount() {
    this.onSearch()
  }
  render() {
    const { start, end, dataSource, take, billVisible, billSource, billtype, downLoad,
      shopPrice, workPrice } = this.state;
    let columsBill = [];
    if (billtype === '师傅') {
      columsBill = [
        {
          title: '流水号',
          align: 'center',
          width: 110,
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '订单编号',
          align: 'center',
          width: 110,
          dataIndex: 'order',
          key: 'order',
        },
        {
          title: '订单类型',
          align: 'center',
          width: 110,
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: '费用类型',
          align: 'center',
          width: 110,
          dataIndex: 'service_type',
          key: 'service_type',
        },
        {
          title: '发生金额',
          align: 'center',
          width: 110,
          dataIndex: 'price',
          key: 'price',
        },
        {
          title: '账户金额',
          align: 'center',
          width: 110,
          dataIndex: 'balance',
          key: 'balance',
        },
        {
          title: '结算时间',
          align: 'center',
          width: 150,
          dataIndex: 'check_time',
          key: 'check_time',
          render: (text, record, index) => {
            return (<div >{moment(record.check_time).format('YYYY-MM-DD HH:mm')}</div>)
          }
        },
        {
          title: '客户信息',
          align: 'center',
          width: 220,
          dataIndex: 'order_info',
          key: 'order_info',
        },
        {
          title: '备注信息',
          align: 'center',
          width: 220,
          dataIndex: 'remark',
          key: 'remark',
        },
      ];
    } else {
      columsBill = [
        {
          title: '流水号',
          align: 'center',
          width: 110,
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '订单编号',
          align: 'center',
          width: 110,
          dataIndex: 'order',
          key: 'order',
        },
        {
          title: '订单类型',
          align: 'center',
          width: 110,
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: '费用类型',
          align: 'center',
          width: 110,
          dataIndex: 'service_type',
          key: 'service_type',
        },
        {
          title: '发生金额',
          align: 'center',
          width: 110,
          dataIndex: 'price',
          key: 'price',
        },
        {
          title: '账户金额',
          align: 'center',
          width: 110,
          dataIndex: 'balance',
          key: 'balance',
        },
        {
          title: '结算时间',
          align: 'center',
          width: 150,
          dataIndex: 'check_time',
          key: 'check_time',
          render: (text, record, index) => {
            return (<div >{moment(record.check_time).format('YYYY-MM-DD HH:mm')}</div>)
          }
        },
        {
          title: '客户信息',
          align: 'center',
          width: 220,
          dataIndex: 'order_info',
          key: 'order_info',
        },
        {
          title: '备注信息',
          align: 'center',
          width: 220,
          dataIndex: 'content',
          key: 'content',
        },
      ];
    }
    return (
      <div className='col-t w100'>
        <div className='row-sb padding20 back-color0 w100'>
          <div className='row-l'>
            <div>时间范围：</div>
            <DatePicker onChange={this.startTime} value={start} locale={locale} format={dateFormat} allowClear={false} />
            <div className='margin-lr5'>至</div>
            <DatePicker onChange={this.endTime} value={end} locale={locale} format={dateFormat} allowClear={false} />
            <Button onClick={this.onSearch} type='primary' className='margin-l20 margin-r10'>搜索</Button>
          </div>
          <div className='row'>
            <div className='margin-r100'>商户费用合计：{shopPrice}；师傅费用合计：{workPrice}；总合计：{Number(shopPrice + workPrice).toFixed(1)}</div>

            {
              [9, 11].includes(this.role) &&
              <div>
                <Button onClick={this.toExcel} loading={downLoad} type='primary' className='margin-r20'>导出</Button>
                <Button onClick={this.toExcel2.bind(this, '商户')} loading={downLoad} type='primary' className='margin-r10'>商户结算流水</Button>
                <Button onClick={this.toExcel2.bind(this, '师傅')} loading={downLoad} type='primary'>师傅结算流水</Button>

              </div>
            }

          </div>
        </div>
        <div className='back-color0 w100 margin-t20 padding20'>
          <Table
            columns={this.columns}
            dataSource={dataSource}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <div className='back-color0 w100 row-r'>
            <PageTurning pageMaps={this.pageMaps} ref={this.pageModal} pageSize={take} />
          </div>
        </div>
        <Drawer
          title={`${billtype}流水`}
          visible={billVisible}
          onClose={this.hideBill}
          width={1200}
          footer={
            <div className='w100 row-r'>
              <Button type='primary' onClick={this.hideBill}>关闭</Button>
            </div>
          }
        >
          <div>
            <Table
              columns={columsBill}
              dataSource={billSource}
              rowKey={record => record.id}
              size={'small'}
            />
          </div>
        </Drawer>
      </div>
    );
  }
  startTime = (e) => {
    this.setState({
      start: e,
    })
  }
  endTime = (e) => {
    this.setState({
      end: e,
    })
  }
  onSearch = async () => {
    const { start, end, skip, take } = this.state;
    let start2 = moment(start).format(dateFormat)
    let end2 = moment(end).format(dateFormat)
    // let res = await getSingleOrder(start2, skip, take);
    let res = await getSingleOrder(start2, end2, skip, take);
    if (res.status === 200) {
      this.setState({
        // dataSource: res.data,
        dataSource: res.data.list,
        shopPrice: res.data.sprice,
        workPrice: res.data.wprice,
      })
    }
  }
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, () => this.onSearch())
  }
  onShowBill = (e, type) => {
    this.setState({
      billVisible: true,
      billSource: e,
      billtype: type,
    })
  }
  hideBill = () => {
    this.setState({
      billVisible: false,
      billSource: [],
    })
  }
  toExcel = async () => {
    const { start, end, } = this.state;
    this.setState({
      downLoad: true,
    });
    let start2 = moment(start).format(dateFormat)
    let end2 = moment(end).format(dateFormat)
    let down = true;
    let dataSource = [];
    let skip = 0;
    let take = 2000;
    let excelData = [];
    let title = [
      {
        title: '订单信息',
        key: 'info',
      },
      {
        title: '服务类型',
        key: 'type',
      },
      {
        title: '客户名称',
        key: 'name',
      },
      {
        title: '联系方式',
        key: 'phone',
      },
      {
        title: '地址',
        key: 'provinces',
      },
      {
        title: '下单商户',
        key: 'shop',
      },
      {
        title: '结算时间',
        key: 'time',
      },
      {
        title: '师傅信息',
        key: 'worker',
      },
      {
        title: '商户费用',
        key: 'price_app',
      },
      {
        title: '师傅费用',
        key: 'price_oms',
      },

    ];
    while (down === true) {
      let res = await getSingleOrder(start2, end2, skip, take);
      if (res.status === 200) {
        dataSource = dataSource.concat(res.data.list)
        down = res.data.list.length < take ? false : true;
        skip = skip + res.data.list.length;
      } else {
        down = false;
      }
    }
    excelData = dataSource.map(item => {
      return {
        info: `${item.id}-${item.service}`,
        type: `${item.category}-${item.type}`,
        name: item.name,
        phone: item.phone,
        provinces: `${item.province}${item.city}${item.district}${item.address}`,
        shop: item.shop_name,
        time: moment(item.check_time).format(dateFormat2),
        worker: item.worker_name,
        price_app: item.price_app + item.add_app,
        price_oms: item.price_oms + item.add_oms,
      };
    })
    jsToExcel2(title, excelData, '订单结算');
    this.setState({
      downLoad: false
    });
  }
  toExcel2 = async (type) => {
    const { start, end, } = this.state;
    this.setState({
      downLoad: true,
    });
    let start2 = moment(start).format(dateFormat)
    let end2 = moment(end).format(dateFormat)
    let dataSource = [];
    let title = [
      {
        title: type,
        key: 'name',
      },
      {
        title: '服务类型',
        key: 'service_type',
      },
      {
        title: '订单编号',
        key: 'order',
      },
      {
        title: '订单信息',
        key: 'order_info',
      },
      {
        title: '金额',
        key: 'price',
      },
      {
        title: '结算时间',
        key: 'check_time',
      },
      {
        title: '类型',
        key: 'type',
      },

    ];
    let res = await getSingleFlow(type, start2, end2);
    if (res.status === 200) {
      dataSource = res.data.map(item => {
        return {
          name: item.name,
          service_type: item.service_type,
          order: item.order ? item.order : '',
          order_info: item.order_info + ' ',
          price: item.price,
          check_time: moment(item.check_time).format(dateFormat2),
          type: item.type,

        };
      });
    }
    jsToExcel2(title, dataSource, type + '结算流水');
    this.setState({
      downLoad: false
    });
  }
}
export default SingleOrdeer;
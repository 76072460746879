import React, { Component } from 'react';
import { Button, Modal, Table, Input, Select, InputNumber, Popover, Radio, Popconfirm, message, Dropdown, Menu, Upload, Tooltip, Checkbox } from 'antd';
import { putDealdata } from '../../services/measureData';
import styles from './index.module.css';
import { DeleteOutlined, PlusOutlined, EyeOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { getOrder2, putBackOrder } from '../../services/orderCore';
import { getVideoInfo, getVideoPlay, getVideoSub, } from '../../services/newOder';
import _ from 'lodash';
import convert from '../../utils/convert';
import { getOssBaseAuth, getDepartment_Data } from '../../config/storage';
import RemarksModal from './remarksModal';
import RelateModal from './relateModal';
import MasterModal from './masterModal';
import SizeModal from './sizeModal';
import { ossUpload, } from '../../utils/ossUpload.js';
import Pic from '../../resource/img/404pic.jpg';

const roomType = ['主卧', '次卧', '客厅', '厨房', '客餐厅', '阳台', '书房', '卫生间', '儿童房', '餐厅', '保姆房'];
const productType = ['罗马杆', '直轨', '弯轨', '成品帘'];
const { Option } = Select;
const newData = {
  id: 0,
  order: 0,
  room: '主卧',
  width: '0',
  width_remark: '满墙',
  height: '0',
  height_remark: '石膏线下到地面',
  prop1: '罗马杆',//产品类型（罗马杆）
  prop2: '侧装',//安装方式（顶装）
  prop3: '无',///窗帘盒/
  prop4: '/',//窗帘盒尺寸（0.2*0.3）
  prop5: '单层',//层数 //成品帘拉绳
  prop6: '单开',//开合方式
  prop7: '普通窗户|无',//窗户类型
  prop8: '',//离地尺寸(旧)，尺寸说明（新）
  prop9: '混凝土',//安装墙面材质
  prop10: '否',//是否超高
  prop11: '否',//是否拆旧
  prop12: '/',//窗框深度//框定安装面
  prop13: '两边大头',//装饰头/吊环
  prop14: '无',//石膏线
  prop15: '/',//房顶/石膏线到窗户上沿距离
  prop16: '/',//窗幔尺寸
  prop17: '无电源',//电源方向
  prop18: '',//建议尺寸
  prop19: '',//离地尺寸
  pic: [],
  vod: [],
  videos: [],
  state: '',
  remark: '无',
  plasterLine: '无',
}


class IndexModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleRoom: false,
      id: 0,
      orderInfo: {},
      confirmLoading: false,
      dataSource: [],
      workInfo: {},
      modalIndex: 0,
      modalValue: '',
      firstLine: '石膏线',
      firstChoose: ['有', '无'],
      rings: ['双层吊环', '单层吊环', '无吊环'], //罗马杆已安装
      finial: ['两边大头', '单侧平头', '双侧平头'],
      widthExplain: [],
      heightExplain: [],
      modalTitle: '房间名称',
      prop3Title: '窗帘盒',
      workRemark: '',
      allPics: [],
      allVideos: [
        // {
        //   id: '0012a462ac6871ee80055017f1f80102',
        //   url: 'https://vod.lijuyun.cn/sv/222af43f-18cddc14e90/222af43f-18cddc14e90.mp4'
        // }
      ],
    }
    // this.baseUrl = getDepartment_Data().resource;
    // this.baseUrl = getDepartment_Data().temp;
    this.baseUrl = getDepartment_Data().service;
    this.remarksModal = React.createRef();
    this.relateModal = React.createRef();
    this.masterModal = React.createRef();
    this.sizeModal = React.createRef();
  }
  render() {
    const { orderInfo, visible, confirmLoading, dataSource, workInfo, visibleRoom, modalValue, modalTitle,
      widthExplain, heightExplain, prop3Title, workRemark, allPics, allVideos } = this.state;
    let columns = [
      {
        title: '操作',
        align: 'center',
        width: 70,
        key: 'operate',
        render: (text, record, index) => {
          return (
            <div>
              <Tooltip title="删除">
                <Popconfirm
                  title="你确定要删除此条数据吗?"
                  onConfirm={this.onConfirm.bind(this, index)}
                  okText="删除"
                  cancelText="返回"
                >
                  <DeleteOutlined className={`${styles['icon_style']} margin-r5`} />
                </Popconfirm >
              </Tooltip>
              <Tooltip title="向上插入">
                <PlusSquareOutlined className={`${styles['icon_style']} ${styles['icon_style1']} margin-r5`} onClick={this.onInsert.bind(this, index)} />
              </Tooltip>
            </div>
          )
        }
      },
      {
        title: '房间',
        align: 'center',
        width: 80,
        key: 'room',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div className={`${styles['room_div_style']} oneline`}
                onClick={this.getModal.bind(this, index, record.room, '房间名称')}>{record.room}</div>
            </div>
          )
        }
      },
      {
        title: '特殊备注',
        align: 'center',
        width: 90,
        key: 'remark',
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <Input.TextArea placeholder='请输入特殊备注' value={record.remark}
                onChange={this.onRemarksChange.bind(this, index, 'remark')} className='padding0 h90 lh15' />
            </div>
          )
        }
      },
      {
        title: '建议产品',
        align: 'center',
        width: 80,
        key: 'product',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div className={`${styles['room_div_style']} oneline`}
                onClick={this.onOpenRelate.bind(this, record, index, 'prop1',)}>{record.prop1}</div>
            </div>
          )
        }
      },
      {
        title: '安装方式',
        align: 'center',
        width: 100,
        key: 'install',
        className: 'padding0',
        render: (text, record, index) => {
          let list = [];
          if (record.prop1 === '成品帘') {
            list = ['内顶装', '外顶装', '内两头装', '外两头装', '外侧装', '内侧装', '内嵌式安装', '免打孔'];
          } else if (record.prop1 === '磁控百叶') {
            list = ['内嵌式安装', '玻璃内框尺寸'];
          } else if (record.prop1 === '罗马杆') {
            list = ['顶装', '侧装', '两头装', '已安装'];
          } else if (record.prop1 === '隐形轨道') {
            list = ['顶装', '已安装'];
          } else {
            list = ['顶装', '侧装', '已安装'];
          }
          let currentProp2 = '';
          let holder = '';
          if ((record.prop1 + record.prop2).includes('罗马杆侧装')) {
            let prop2res = record.prop2.split('|');
            currentProp2 = prop2res[0];
            holder = prop2res[1] ? prop2res[1] : '';
          } else {
            currentProp2 = record.prop2;
          }
          return (
            <div className='col-t'>
              <Select value={currentProp2} onChange={this.onSelectType.bind(this, index)} className='w100'>
                {
                  list.map((item, index) => {
                    return <Option value={item} key={index}>{item}</Option>
                  })
                }
              </Select>
              {
                holder === '是' &&
                <div className='margin-t5 color2'>旋转支架：{holder}</div>
              }
            </div>
          )
        }
      },
      {
        title: '宽度尺寸',
        align: 'center',
        width: 75,
        key: 'width',
        render: (text, record, index) => {
          return (
            <Input.TextArea value={record.width}
              onChange={this.onInputNumChange.bind(this, index, 'width')} className='padding0 h90 lh15' />
          )
        }
      },
      {
        title: '宽度说明',
        align: 'center',
        width: 100,
        key: 'width_content',
        className: 'padding5',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <Popover content={record.width_remark} title='宽度说明'>
                <div
                  className={`${styles['room_instr']}`}
                  onClick={this.getModal.bind(this, index, record.width_remark, '宽度说明')}
                >
                  {record.width_remark}
                </div>
              </Popover>
            </div>
          )
        }
      },
      {
        title: '高度尺寸',
        align: 'center',
        width: 75,
        key: 'height',
        render: (text, record, index) => {
          return (
            <Input.TextArea value={record.height} onChange={this.onInputNumChange.bind(this, index, 'height')} className='padding0 h90 lh15' />
          )
        }
      },
      {
        title: '高度说明',
        align: 'center',
        width: 100,
        key: 'height_content',
        className: 'padding5',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <Popover content={record.height_remark} title='高度说明'>
                <div className={`${styles['room_instr']}`} onClick={this.getModal.bind(this, index, record.height_remark, '高度说明')}>{record.height_remark}</div>
              </Popover>
            </div>
          )
        }
      },
      {
        title: '窗帘盒(宽*高)',
        align: 'center',
        width: 95,
        key: 'box_size',
        render: (text, record, index) => {
          return (
            <div className='col'>
              <Radio.Group onChange={this.onRadioChange.bind(this, index, 'prop3')} value={record.prop3} className='row'>
                <Radio value={'有'}>有</Radio>
                <Radio value={'无'}>无</Radio>
              </Radio.Group>
              <Input value={record.prop4} onChange={this.onInputChange.bind(this, index, 'prop4')}
                placeholder='请输入宽高尺寸' className='margin-t10' />
            </div>
          )
        }
      },
      {
        title: '房顶/石膏线到窗户上沿距离',
        align: 'center',
        width: 100,
        key: 'stone_locate',
        render: (text, record, index) => {
          return (
            <div className='col'>
              <Radio.Group onChange={this.onRadioChange.bind(this, index, 'prop14')} value={record.prop14} className='row'>
                <Radio value={'有'}>有</Radio>
                <Radio value={'无'}>无</Radio>
              </Radio.Group>
              <Input value={record.prop15} onChange={this.onInputNumChange.bind(this, index, 'prop15')}
                placeholder='请输入距离' className='margin-t10' />
            </div>
          )
        }
      },
      {
        title: '窗框深度/框顶安装面',
        align: 'center',
        width: 95,
        key: 'window',
        render: (text, record, index) => {
          return (
            <Input value={record.prop12} onChange={this.onInputChange.bind(this, index, 'prop12')} />
          )
        },
      },
      {
        title: '电源方向',
        align: 'center',
        width: 100,
        key: 'window',
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className='col-t vertical-lt'>
              <Checkbox checked={record.prop17 === '左电源'} onChange={this.onCheckChange.bind(this, index, 'prop17', '左电源')} className='margin-l0'>左电源</Checkbox>
              <Checkbox checked={record.prop17 === '右电源'} onChange={this.onCheckChange.bind(this, index, 'prop17', '右电源')} className='margin-l0'>右电源</Checkbox>
              <Checkbox checked={record.prop17 === '左右电源'} onChange={this.onCheckChange.bind(this, index, 'prop17', '左右电源')} className='margin-l0'>左右电源</Checkbox>
              <Checkbox checked={record.prop17 === '无电源'} onChange={this.onCheckChange.bind(this, index, 'prop17', '无电源')} className='margin-l0'>无电源</Checkbox>
            </div>
          )
        },
      },
      {
        title: '窗幔尺寸',
        align: 'center',
        width: 100,
        key: 'window',
        render: (text, record, index) => {
          return (
            <Input value={record.prop16} onChange={this.onInputChange.bind(this, index, 'prop16')} />
          )
        },
      },
      {
        title: '常用备注1',
        align: 'center',
        width: 145,
        key: 'remark2',
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div>
              {
                !['成品帘', '磁控百叶'].includes(record.prop1) &&
                <div className={styles['remarks']} onClick={this.onRemarksModal.bind(this, index, record, '备注1')}>
                  <div className='row-l'>
                    <div>层数:</div>
                    <div className={newData.prop5 === record.prop5 ? '' : 'color2'}>{record.prop5}</div>
                  </div>
                  <div className='row-l'>
                    <div>开合方式:</div>
                    <div className={newData.prop6 === record.prop6 ? '' : 'color2'}>{record.prop6}</div>
                  </div>
                  <div className='row-l'>
                    <div>窗户类型:</div>
                    <div className={newData.prop7 === record.prop7 ? '' : 'color2'}>{record.prop7}</div>
                  </div>
                  {/* <div className='row-l'>
                    <div>离地尺寸:</div>
                    <div className={newData.prop8 === record.prop8 ? '' : 'color2'}>{record.prop8}</div>
                  </div> */}
                  <div className='row-l'>
                    <div>离地要求:</div>
                    <div>{(record.prop19 || record.prop19 === '0') && record.prop19 !== '无' ? '离地 ' + record.prop19 + ' 公分' : '无'}</div>
                  </div>
                </div >
              }
              {
                record.prop1 === '成品帘' &&
                <div className='row-l'>
                  <div>拉绳:</div>
                  <div className={record.prop5 === '右' ? '' : 'color2'}>{record.prop5}</div>
                </div>
              }
              {
                record.prop1 === '磁控百叶' && <div>/</div>
              }

            </div>
          )
        }
      },
      {
        title: '常用备注2',
        align: 'center',
        width: 120,
        key: 'remark3',
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className={styles['remarks']} onClick={this.onRemarksModal.bind(this, index, record, '备注2')}>
              <div className='row-l'>
                <div>墙面材质:</div>
                <div className={newData.prop9 === record.prop9 ? '' : 'color2'}>{record.prop9}</div>
              </div>
              {
                ['成品帘', '磁控百叶'].includes(record.prop1) ? '' :
                  <div className='row-l'>
                    <div>是否超高:</div>
                    <div className={newData.prop10 === record.prop10 ? '' : 'color2'}>{record.prop10}</div>
                  </div>
              }
              {
                ['成品帘', '磁控百叶'].includes(record.prop1) ? '' :
                  <div className='row-l'>
                    <div>是否拆旧:</div>
                    <div className={newData.prop11 === record.prop11 ? '' : 'color2'}>{record.prop11}</div>
                  </div>
              }
              {
                record.prop1 === '罗马杆' && record.prop2 !== '两头装' ?
                  <div className='row-l'>
                    <div>{record.prop2 === '已安装' ? '吊环' : '装饰头'}:</div>
                    <div className={record.prop13 === '两边大头' || record.prop13 === '无吊环' ? '' : 'color2'}>{record.prop13}</div>
                  </div>
                  : ''
              }
            </div>
          )
        }
      },
      {
        title: '成品尺寸',
        align: 'center',
        width: 160,
        key: 'remark3',
        className: 'padding0',
        render: (text, record, index) => {
          if (record.prop18 || record.prop8) {
            return (
              <div className={`col-t vertical-lt ${styles['remarks']} tl`} onClick={this.changeSize.bind(this, index, record)}>
                {
                  record.prop18 &&
                  <div>尺寸:{record.prop18}（米）</div>
                }
                <div>说明:{record.prop8}</div>
              </div>
            );
          } else {
            return (
              <div className={`${styles['remarks']} padding-tb5`} onClick={this.changeSize.bind(this, index, record)}>&nbsp;暂不提供&nbsp;</div>
            );
          }
        }
      }
    ];
    return (
      <Modal
        title={this.getTitle(workInfo, orderInfo)}
        // `数据整理   （客户：${workInfo.customer},客户电话：${workInfo.customerTel}）（师傅：${workInfo.name}，联系电话：${workInfo.phone}）`
        visible={visible}
        onCancel={this.decide}
        width={2000}
        bodyStyle={{ height: 800 }}
        style={{ top: 0 }}
        confirmLoading={confirmLoading}
        footer={
          <div>
            <Button onClick={this.backOrder} type='danger' className=''>驳回</Button>
            <Button onClick={this.decide} key='cancel' loading={confirmLoading}>取消</Button>
            <Button onClick={this.onOkData.bind(this, '暂存')} type='dashed' key='save' loading={confirmLoading}>暂存</Button>
            {/* <Button onClick={this.onOkData.bind(this, '同步')} type='primary' key='synchronous' loading={confirmLoading}>确定并同步投诉</Button> */}
            <Button onClick={this.onOkData.bind(this, '确定')} type='primary' key='sure' loading={confirmLoading}>确定</Button>
          </div>
        }
      >
        <Table
          columns={columns}
          dataSource={dataSource}
          size={'small'}
          rowKey={record => record.id}
          bordered
          pagination={false}
          scroll={{ y: 340, x: 1740 }}
        />
        <div className='row-sb margin-t10 w100'>
          <div className='row-l'>
            <div className='w126 tc'>师傅备注：</div>
            < Input value={workRemark} onChange={this.onRemarkChange} className='w850' />
          </div>
          <Button type='primary' className='margin-r10' onClick={this.onAddClick}>新增</Button>
        </div>
        <div className='row-sb margin-t10 vertical-lt w100'>
          <div className='row-l vertical-lt'>
            <div className='col'>
              <Upload
                type='file'
                beforeUpload={this.upLoad}
                customRequest={() => {
                }}
                fileList={[]}
                accept='.jpg,.png,.jpeg,.icon,.svg,.gif'
                // accept='image/*'
                multiple={true}
              // onChange={this.onAddPic.bind(this,)}
              >
                <div className={`row cursor-pointer ${styles['pic-div']} h50`}>
                  <div className="row h100">
                    <PlusOutlined />
                    <span>
                      新增图片
                    </span>
                  </div>
                </div>
              </Upload>
              <Upload
                type='file'
                beforeUpload={this.uploadVideo}
                customRequest={() => { }}
                fileList={[]}
                multiple={false}
                maxCount={1}
              >
                <div className={`row cursor-pointer ${styles['pic-div']} h50`}>
                  <div className="row h100">
                    <PlusOutlined />
                    <span>
                      新增视频
                    </span>
                  </div>
                </div>
              </Upload>
            </div>
            <div className='col-t vettical-lt w100'>
              <div className='row-w w100'>
                {
                  allPics.map((item, index) => {
                    return (
                      <div key={index} className={`${styles['content']}`}>
                        <Dropdown
                          overlay={
                            <Menu>
                              {
                                dataSource.map((ditem, dindex) => {
                                  return (
                                    <Menu.Item onClick={this.onSwitchPicAll.bind(this, index)} key={dindex}>{ditem.room}</Menu.Item>
                                  );
                                })
                              }
                            </Menu>
                          }
                        >
                          <div className={`row ${styles['pic-icon']}`}>
                            全部图片
                            <Popconfirm
                              title="你确定要删除这张图片吗?"
                              onConfirm={this.deletePicAll.bind(this, index)}
                              okText="删除"
                              cancelText="取消"
                            >
                              <DeleteOutlined className='margin-l5' />
                            </Popconfirm>
                          </div>
                        </Dropdown>
                        <img
                          alt="暂无图片"
                          className={`${styles['pic']}`}
                          src={`${this.baseUrl}/upload/${item}`}
                        />
                        <div className={`row ${styles['content-div']}`}>
                          <EyeOutlined
                            className={`${styles['pic-icon-E']}`}
                            onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                            theme="outlined"
                            title="预览"
                          />
                        </div>
                      </div>
                    );
                  })
                }
              </div>
              <div className='row-w margin-t10 w100'>
                {
                  allVideos.map((item, index) => {
                    return (
                      <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                        <div className={`${styles['content-video']}`}>
                          <video controls='controls' className={styles['content-image']}
                            // src={"https://vod.lijuyun.cn/sv/447ef52a-18cdd736632/447ef52a-18cdd736632.mp4"}
                            src={item.url}
                          />
                        </div>
                        <div className='row'>
                          <Dropdown
                            overlay={
                              <Menu className={styles['select-over']}>
                                <Menu.Item onClick={this.onSwitchVideoAll.bind(this, index)} key={dataSource.length}>全部视频</Menu.Item>
                                {
                                  dataSource.map((ditem, dindex) => {
                                    return (
                                      <Menu.Item onClick={this.onSwitchVideoAll.bind(this, index)} key={dindex}>{ditem.room}</Menu.Item>
                                    );
                                  })
                                }
                              </Menu>
                            }
                          >
                            <Button type='primary' className='w90 margin-t5'>切换</Button>
                          </Dropdown>
                          <Popconfirm
                            title="您确定要删除该视频吗?"
                            onConfirm={this.delVideoAll.bind(this, index)}
                            okText="确认"
                            cancelText="取消"
                          >
                            <Button type='danger' className='w90 margin-t5'>删除视频</Button>
                          </Popconfirm>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
          {
            dataSource.length === 0 &&
            <Button type='primary' className='margin-r10 margin-t10' onClick={this.onMeasure}>一键整理</Button>
          }
        </div>
        <div className={`${styles['box_style']} row-w vertical-lt margin-t10 bord1`}>
          {
            dataSource.map((item, index) => {
              return (
                <div className={`${styles['pic_box']} col-t vertical-lt margin-r20`} key={index}>
                  <div>{item.room}:</div>
                  <div className='row-sb vertical-lt w100'>
                    <div className='row-w w400'>
                      {
                        item.pic.map((pitem, pindex) => {
                          return (
                            <div key={pindex} className={`${styles['content']}`}>
                              <Dropdown
                                overlay={
                                  <Menu className={styles['select-over']}>
                                    <Menu.Item onClick={this.onSwitchPic.bind(this, pindex, index)} key={dataSource.length}>全部图片</Menu.Item>
                                    {
                                      dataSource.map((ditem, dindex) => {
                                        return (
                                          <Menu.Item onClick={this.onSwitchPic.bind(this, pindex, index)} key={dindex}>{ditem.room}</Menu.Item>
                                        );
                                      })
                                    }
                                  </Menu>
                                }
                              >
                                <div className={`row ${styles['pic-icon']}`}>
                                  {item.room}
                                  <Popconfirm
                                    title="你确定要删除这张图片吗?"
                                    onConfirm={this.deletePic.bind(this, pindex, index)}
                                    okText="删除"
                                    cancelText="取消"
                                  >
                                    <DeleteOutlined className='margin-l5' />
                                  </Popconfirm>
                                </div>
                              </Dropdown>
                              <img
                                draggable="false"
                                alt="暂无图片"
                                className={`${styles['pic']}`}
                                src={`${this.baseUrl}/upload/${pitem}`}
                              />
                              <div className={`row ${styles['content-div']}`}>
                                <EyeOutlined
                                  className={`${styles['pic-icon-E']}`}
                                  onClick={this.preview.bind(this, `${this.baseUrl}/upload/${pitem}`)}
                                  theme="outlined"
                                  title="预览"
                                />
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                    <div>
                      {
                        item.videos.map((vitem, vindex) => {
                          return (
                            <div className={`${styles['video-box']} col-t vertical-lt`} key={vindex}>
                              <div className={`${styles['content-video']}`}>
                                <video controls='controls' className={styles['content-image']}
                                  // src={"https://vod.lijuyun.cn/sv/447ef52a-18cdd736632/447ef52a-18cdd736632.mp4"}
                                  src={vitem.url}
                                />
                              </div>
                              <div className='row'>
                                <Dropdown
                                  overlay={
                                    <Menu className={styles['select-over']}>
                                      <Menu.Item onClick={this.onSwitchVideo.bind(this, vindex, index)} key={dataSource.length}>全部视频</Menu.Item>
                                      {
                                        dataSource.map((ditem, dindex) => {
                                          return (
                                            <Menu.Item onClick={this.onSwitchVideo.bind(this, vindex, index)} key={dindex}>{ditem.room}</Menu.Item>
                                          );
                                        })
                                      }
                                    </Menu>
                                  }
                                >
                                  <Button type='primary' className='w90 margin-t5'>切换</Button>
                                </Dropdown>
                                <Popconfirm
                                  title="您确定要删除该视频吗?"
                                  onConfirm={this.delVideo.bind(this, index, vindex)}
                                  okText="确认"
                                  cancelText="取消"
                                >
                                  <Button type='danger' className='w90 margin-t5'>删除视频</Button>
                                </Popconfirm>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
        <RemarksModal
          ref={this.remarksModal}
          updateTable={this.updateTable}
        />
        <RelateModal
          ref={this.relateModal}
          updateTable={this.updateRoom}
        />
        <MasterModal
          ref={this.masterModal}
          upload={this.onOk}
        />
        <SizeModal
          ref={this.sizeModal}
          upload={this.updateSize}
        />
        <Modal
          title={modalTitle}
          visible={visibleRoom}
          width={700}
          onCancel={this.onCancelRoom}
          onOk={this.onOkRoom}
          okText='确定'
          cancelText='取消'
        >
          <div>
            <Input className='margin-b10' value={modalValue} onChange={this.onModalValueChange} />
            <div className='row-w'>
              {
                modalTitle === '房间名称' && roomType.map((item, index) => {
                  return (
                    <div key={index} className={styles['room_type_div']} onClick={this.onRoomModalValue.bind(this, item)}>{item}</div>
                  )
                })
              }
              {
                modalTitle === '宽度说明' && widthExplain.map((item, index) => {
                  return (
                    <div key={index} className={styles['room_type_div']} onClick={this.onRoomModalValue.bind(this, item)}>{item}</div>
                  )
                })
              }
              {
                modalTitle === '高度说明' && heightExplain.map((item, index) => {
                  return (
                    <div key={index} className={styles['room_type_div']} onClick={this.onRoomModalValue.bind(this, item)}>{item}</div>
                  )
                })
              }
            </div>
          </div>
        </Modal>

      </Modal>
    );
  }
  getTitle = (workInfo, orderInfo) => {
    return (
      <div className='row-l'>
        <div className='margin-r20'>数据整理</div>
        <div className='col-t vertical-lt'>
          <div>（师傅：{workInfo.name}，联系电话：{workInfo.phone}）</div>
          <div>（客户：{workInfo.customer},客户电话：{workInfo.customerTel},地址：{workInfo.province + workInfo.city + workInfo.district + workInfo.address}）</div>
          <div>（商户：{orderInfo.shop}，备注：{workInfo.shop_remark}）</div>
        </div>

      </div>
    );
  }
  onInsert = (index) => {
    let dataSource = _.cloneDeep(this.state.dataSource);
    let newRoomData = _.cloneDeep(newData);
    newRoomData.id = -dataSource.length - 1;
    dataSource.splice(index, 0, newRoomData);
    dataSource.forEach((item, index) => {
      item.id = index;
    })
    this.setState({
      dataSource: dataSource,
    })
  }

  updateTable = (data, index) => {
    let dataSource = _.cloneDeep(this.state.dataSource);
    dataSource[index] = data;
    this.setState({
      dataSource: dataSource
    })
  }
  onRemarksModal = (index, data, type) => {
    this.remarksModal.current.onShowModal(index, data, type);
  }

  onConfirm = (index) => {
    let dataSource = _.cloneDeep(this.state.dataSource);
    if (dataSource.length === 1) {
      message.warn('至少需要一条数据');
      return;
    }
    dataSource.splice(index, 1);
    this.setState({
      dataSource: dataSource,
    })
  }
  onAddClick = () => {
    let dataSource = _.cloneDeep(this.state.dataSource);
    let newRoomData = _.cloneDeep(newData);
    newRoomData.id = -dataSource.length - 1;
    newRoomData.room = '房间' + (dataSource.length + 1);
    dataSource.push(newRoomData);
    this.setState({
      dataSource: dataSource,
    })
  }
  onInputChange = (index, prop, e) => {
    let dataSource = _.cloneDeep(this.state.dataSource);
    dataSource[index][prop] = e.target.value;
    this.setState({
      dataSource: dataSource,
    })
  }
  onRadioChange = (index, prop, e) => {
    let dataSource = _.cloneDeep(this.state.dataSource);
    dataSource[index][prop] = e.target.value;
    this.setState({
      dataSource: dataSource
    })
  }
  onCheckChange = (index, prop, value, e) => {
    let dataSource = _.cloneDeep(this.state.dataSource);
    dataSource[index][prop] = e.target.checked ? value : '';
    this.setState({
      dataSource: dataSource
    })
  }
  onRemarkChange = (e) => {
    this.setState({
      workRemark: e.target.value
    })
  }
  //宽度、高度说明
  sizeExplain = (index, type) => {
    let widthExplain = [];
    let heightExplain = [];
    let data = _.cloneDeep(this.state.dataSource);
    let holder = '';
    if ((data[index].prop1 + data[index].prop2).includes('罗马杆侧装')) {
      let prop2s = data[index].prop2.split('|');
      data[index].prop2 = prop2s[0];
      holder = prop2s[1] ? prop2s[1] : '';
    }
    if (data[index].prop1 + data[index].prop2 === '罗马杆顶装') {
      widthExplain = ['满墙', '窗框两边已各加30公分', '罗马杆净杆', '自定义']
      heightExplain = ['盒顶到地面', '盒顶到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '打孔成品尺寸', '挂钩成品尺寸', '自定义']
    } else if (['罗马杆侧装',].includes(data[index].prop1 + data[index].prop2)) {
      widthExplain = ['满墙', '窗框两边已各加30公分', '罗马杆净杆', '自定义']
      heightExplain = ['石膏线下到地面', '石膏线下到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '打孔成品尺寸', '挂钩成品尺寸', '自定义']
    } else if (['罗马杆两头装'].includes(data[index].prop1 + data[index].prop2)) {
      widthExplain = ['满墙', '罗马杆含头', '罗马杆净杆', '自定义']
      heightExplain = ['石膏线下到地面', '石膏线下到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '打孔成品尺寸', '挂钩成品尺寸', '自定义']
    } else if (data[index].prop1 + data[index].prop2 === '罗马杆已安装') {
      widthExplain = ['罗马杆含头', '罗马杆净杆', '自定义']
      heightExplain = ['罗马杆杆上沿到地面', '罗马杆杆上沿到台面', '罗马杆挂环下沿到地面', '罗马杆挂环下沿到台面', '打孔成品尺寸', '挂钩成品尺寸', '自定义']
    } else if (['直轨顶装', '电动直轨顶装', '隐形轨道顶装'].includes(data[index].prop1 + data[index].prop2)) {
      widthExplain = ['满墙', '窗帘盒长', '飘窗满墙宽', '内框宽度', '自定义']
      heightExplain = ['盒顶到地面', '盒顶到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '窗框顶到地面', '窗框顶到台面', '飘窗顶到飘窗台面', '挂钩成品尺寸', '自定义']
    } else if (data[index].prop1 + data[index].prop2 === '直轨侧装' || data[index].prop1 + data[index].prop2 === '电动直轨侧装') {
      widthExplain = ['满墙', '客户指定宽度', '直轨含头尺寸', '自定义']
      heightExplain = ['石膏线下到地面', '石膏线下到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '挂钩成品尺寸', '自定义']
    } else if (['直轨已安装', '电动直轨已安装', '隐形轨道已安装'].includes(data[index].prop1 + data[index].prop2)) {
      widthExplain = ['直轨含头尺寸', '自定义']
      heightExplain = ['盒顶到地面', '盒顶到台面', '挂钩下沿到地面', '挂钩下沿到台面', '挂钩下沿到客户指定位置', '挂钩成品尺寸', '自定义']
    } else if (data[index].prop1 + data[index].prop2 === '弯轨顶装' || data[index].prop1 + data[index].prop2 === '电动弯轨顶装') {
      widthExplain = ['窗帘盒长', 'L型飘窗总长', 'U型飘窗总长', '异型窗总长', 'L型窗帘盒总长', 'U型窗帘盒总长', '异型窗帘盒总长', '弯轨含头尺寸', '自定义']
      heightExplain = ['盒顶到地面', '盒顶到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '窗框顶到地面', '窗框顶到台面', '飘窗顶到飘窗台面', '挂钩成品尺寸', '自定义']
    } else if (data[index].prop1 + data[index].prop2 === '弯轨侧装' || data[index].prop1 + data[index].prop2 === '电动弯轨侧装') {
      widthExplain = ['L型飘窗总长', 'U型飘窗总长', '弧形窗户总长', '不规则窗帘总长', '弯轨含头尺寸', '自定义']
      heightExplain = ['石膏线下到地面', '石膏线下到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '窗框顶到地面', '窗框顶到台面', '挂钩成品尺寸', '自定义']
    } else if (data[index].prop1 + data[index].prop2 === '弯轨已安装' || data[index].prop1 + data[index].prop2 === '电动弯轨已安装') {
      widthExplain = ['弯轨含头尺寸', '自定义']
      heightExplain = ['盒顶到地面', '盒顶到台面', '挂钩下沿到地面', '挂钩下沿到台面', '挂钩下沿到客户指定位置', '挂钩成品尺寸', '自定义']
    } else if (data[index].prop1 + data[index].prop2 === '手动梦幻帘顶装' || data[index].prop1 + data[index].prop2 === '电动梦幻帘顶装') {
      widthExplain = ['窗帘盒长', '满墙', '飘窗满墙宽', '内框宽度', '自定义']
      heightExplain = ['盒顶到地面', '盒顶到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '窗框顶到地面', '窗框顶到台面', '飘窗顶到飘窗台面', '挂珠成品尺寸', '自定义']
    } else if (data[index].prop1 + data[index].prop2 === '手动梦幻帘侧装' || data[index].prop1 + data[index].prop2 === '电动梦幻帘侧装') {
      widthExplain = ['满墙', '客户指定宽度', '直轨含头尺寸', '自定义']
      heightExplain = ['石膏线下到地面', '石膏线下到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '挂珠成品尺寸', '自定义']
    } else if (data[index].prop1 + data[index].prop2 === '手动梦幻帘已安装' || data[index].prop1 + data[index].prop2 === '电动梦幻帘已安装') {
      widthExplain = ['直轨含头尺寸', '自定义']
      heightExplain = ['挂珠下沿到地面', '挂珠下沿到台面', '挂珠下沿到客户指定位置', '挂珠成品尺寸', '自定义']
    } else if (data[index].prop1 === '成品帘') {
      widthExplain = ['内框宽度', '成品尺寸', '自定义']
      heightExplain = ['内框高度', '成品尺寸', '自定义']
    } else if (data[index].prop1 === '磁控百叶') {
      widthExplain = ['玻璃内框宽度', '玻璃宽度', '成品尺寸', '自定义']
      heightExplain = ['玻璃内框高度', '玻璃高度', '成品尺寸', '自定义']
    }
    if (type) {
      if (['直轨顶装', '电动直轨顶装', '隐形轨道顶装'].includes(data[index].prop1 + data[index].prop2)) {
        data[index].width_remark = widthExplain[1];
        data[index].height_remark = heightExplain[0];
      } else {
        data[index].width_remark = widthExplain[0];
        data[index].height_remark = heightExplain[0];

      }
    }
    if (holder) {
      data[index].prop2 = data[index].prop2 + '|' + holder;
    }
    this.setState({
      widthExplain: widthExplain,
      heightExplain: heightExplain,
      dataSource: data,
    })
  }
  onInputNumChange = (index, type, e) => {
    let dataSource = _.cloneDeep(this.state.dataSource);
    dataSource[index][type] = e.target.value + '';
    this.setState({
      dataSource: dataSource,
    });
  }
  onRemarksChange = (index, prop, e) => {
    let dataSource = _.cloneDeep(this.state.dataSource);
    dataSource[index][prop] = e.target.value;
    this.setState({
      dataSource: dataSource
    })
  }
  onSelectType = (index, e) => {
    let dataSource = _.cloneDeep(this.state.dataSource);
    dataSource[index].prop2 = e;
    if (dataSource[index].prop1 === '罗马杆') {
      if (e === '已安装') {
        dataSource[index].prop13 = '无吊环'
      } else if (e === '两头装') {
        dataSource[index].prop13 = ''
      } else {
        dataSource[index].prop13 = '两边大头'
      }
    }
    this.setState({
      dataSource: dataSource,
    }, async () => this.sizeExplain(index, true));
  }
  getModal = (index, name, title) => {
    if (title !== '房间名称') {
      this.sizeExplain(index);
    }
    this.setState({
      visibleRoom: true,
      modalIndex: index,
      modalValue: name,
      modalTitle: title
    })
  }
  onShowModal = (e) => {
    this.setState({
      visible: true,
      id: e.id,
      orderInfo: e,
    })
    this.getOrderData(e.id);
  }
  getOrderData = async (id) => {
    // let dataSource = [{ ...newData }];
    let dataSource = [];
    let res = await getOrder2(id);
    if (res.status !== 200) {
      message.error(res.data);
      this.onCancel();
      return;
    }
    dataSource = res.data.serviceDatas;
    dataSource.forEach((item, index) => {
      item.pic = item.pic !== '' ? JSON.parse(item.pic) : [];
      item.vod = item.vod && item.vod.includes('[') && item.vod.includes(']') ? JSON.parse(item.vod) : [];
      item.videos = [];
    })
    this.getAllVideo(dataSource);
    let workInfo = {
      name: res.data.workerName,
      phone: res.data.workerPhone,
      customer: res.data.name,
      customerTel: res.data.phone,
      province: res.data.province,
      city: res.data.city,
      district: res.data.district,
      address: res.data.address,
      shop_remark: res.data.shop_remark
    };
    let remarks = res.data.worker_remark.split('✱');
    let workRemark = remarks[0] ? remarks[0] : '';
    this.setState({
      dataSource: dataSource,
      workInfo: workInfo,
      workRemark: workRemark,
    })
  }
  getAllVideo = async (dataSource) => {
    dataSource.map(async (item, index) => {
      item.videos = [];
      item.vod.map(async (vitem, vindex) => {
        let local = vindex;
        let res = await getVideoPlay(vitem);
        if (res.status === 200) {
          item.videos[local] = {
            id: vitem,
            url: res.data.playURL
          };
        }
        this.setState({
          dataSource
        })
      })
    })
  }
  onOkData = (type) => {
    if (type === '暂存') {
      this.setState({
        confirmLoading: true
      });
      this.onOk(false, type, false, '', '', '');
    } else {
      this.masterModal.current.showDrawer();
    }
  }
  onOk = async (isSelf, type, isasync, appraise, dataNUm, dataRemark) => {
    this.setState({
      confirmLoading: true
    });
    let dataSource = _.cloneDeep(this.state.dataSource);
    dataSource.forEach((item, index) => {
      item.pic = JSON.stringify(item.pic)
      item.vod = item.videos.map(r => r.id);
      item.vod = JSON.stringify(item.vod)
      if (item.prop5 === '') {
        item.prop5 = item.prop1 === '成品帘' ? '左' : '和客户沟通';
      }
      if (!item.prop18 && !item.prop8) {
        item.prop8 = '暂不提供';
      }
      item.prop19 = item.prop19 || item.prop19 === '0' ? item.prop19.toString() : '无';
    })
    let { id, workRemark, allVideos } = this.state;
    let putType = type === '同步' ? '确定' : type;
    let remark = workRemark + '✱' + dataNUm + '✱' + dataRemark;
    // 师傅备注+数量+数据备注
    let data = {
      orderId: id,
      isasync: isasync,
      isSelf: isSelf,
      type: putType,
      // remark: workRemark,✱
      remark: remark,
      appraise: appraise,
      datas: dataSource,
      // worker_remark+飘窗垫+数据备注
    };
    let res = await putDealdata(data);
    if (res.status !== 204) {
      message.error(res.data);
      this.setState({
        confirmLoading: false
      });
      return;
    }
    // this.setState({
    //   confirmLoading: false
    // });
    if (type === '确定' || type === '同步') {
      this.props.updateTable();
    }
    this.onCancel()
  }
  decide = () => {
    let res = confirm('是否确认关闭');
    if (res) {
      this.onCancel();
    }
  }
  onCancel = () => {
    this.setState({
      visible: false,
      confirmLoading: false,
      allPics: [],
      allVideos: [],
      dataSource: [],
    });
    this.closeModal()
  }
  //关闭弹框
  closeModal = async () => {
    let { id, } = this.state;
    let data = {
      orderId: id,
      isasync: false,
      isSelf: false,
      type: '取消',
      remark: '',
      appraise: '',
      datas: [],
    };
    let res = await putDealdata(data);
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
  }
  onRoomModalValue = (e) => {
    this.setState({
      modalValue: e
    })
  }
  onModalValueChange = (e) => {
    this.setState({
      modalValue: e.target.value
    })
  }
  onOkRoom = () => {
    let { modalIndex, modalValue, modalTitle } = this.state;
    let dataSource = _.cloneDeep(this.state.dataSource);
    if (modalTitle === '房间名称') {
      dataSource[modalIndex].room = modalValue;
    } else if (modalTitle === '宽度说明') {
      dataSource[modalIndex].width_remark = modalValue;
    } else if (modalTitle === '高度说明') {
      dataSource[modalIndex].height_remark = modalValue;
    }
    this.setState({
      dataSource: dataSource,
    })
    this.onCancelRoom();
  }
  onCancelRoom = () => {
    this.setState({
      visibleRoom: false,
    });
  }
  //成品尺寸
  changeSize = (index, record) => {
    this.sizeModal.current.onShow(index, record);
  }
  updateSize = (index, data) => {
    let dataSource = _.cloneDeep(this.state.dataSource);
    dataSource[index] = data;
    this.setState({
      dataSource: dataSource,
    })
  }

  //图片
  preview = (url) => {
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
  upLoad = async (file, fileList) => {
    let allPics = _.cloneDeep(this.state.allPics);
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = allPics.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      allPics[leng + i] = name;
    }
    this.setState({
      allPics,
    })
  }
  deletePic = (pindex, index) => {
    let dataSource = [...this.state.dataSource];
    dataSource[index].pic.splice(pindex, 1)
    this.setState({
      dataSource
    });
  }
  deletePicAll = (index) => {
    let allPics = [...this.state.allPics];
    allPics.splice(index, 1)
    this.setState({
      allPics
    });
  }
  onSwitchPic = (pindex, index, e) => {
    let dataSource = [...this.state.dataSource];
    let allPics = [...this.state.allPics];
    if (dataSource[e.key]) {
      let newPic = dataSource[index].pic.splice(pindex, 1)[0];
      dataSource[e.key].pic.push(newPic);
    } else {
      let newPic = dataSource[index].pic.splice(pindex, 1)[0];
      allPics.push(newPic);
    }
    this.setState({
      dataSource, allPics
    });
  }
  onSwitchPicAll = (index, e) => {
    let dataSource = [...this.state.dataSource];
    let allPics = [...this.state.allPics];
    let newPic = allPics.splice(index, 1)[0];
    dataSource[e.key].pic.push(newPic);
    this.setState({
      dataSource, allPics
    });
  }

  //打开建议产品关联弹框
  onOpenRelate = (data, index, props) => {
    this.relateModal.current.onShow(data, index, props);
  }
  updateRoom = (data, index) => {
    let dataSource = [...this.state.dataSource];
    // if (dataSource[index].prop1 + dataSource[index].prop2 !== data.prop1 + data.prop2) {
    //   data.prop13 = data.prop1 === '罗马杆' ? data.prop2 === '已安装' ? '无吊环' : '两边大头' : '';
    // }
    dataSource[index] = data;
    this.setState({
      dataSource,
    })
  }
  //一键整理
  onMeasure = () => {
    let dataSource = [];
    let allPics = [...this.state.allPics];
    allPics.map((item, index) => {
      let newRoomData = _.cloneDeep(newData);
      newRoomData.id = -index - 1;
      newRoomData.room = `房间${index + 1}`;
      newRoomData.pic.push(item);
      dataSource.push(newRoomData);
    })
    this.setState({
      dataSource,
      allPics: [],
    });
  }
  backOrder = async () => {
    let { id } = this.state;
    let res = await putBackOrder(id);
    if (res.status === 204) {
      this.props.updateTable();
      this.onCancel()
    }
  }
  //视频
  uploadVideo = async (file, fileList) => {
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    if (fileList.length > 1) {
      message.warn('只能上传单个视频');
      return;
    }
    let decimal = (new Date()).valueOf();
    let name = `${convert.toString36(decimal)}`.replace(/:/g, '-');
    let res = await getVideoInfo(name, file.name);
    let uploadAddress = JSON.parse(decodeURI(atob(res.data.uploadAddress)));
    let uploadInfos = {
      video: res.data.videoId,
      Endpoint: uploadAddress.Endpoint,
      Bucket: uploadAddress.Bucket,
      object: null,
    };
    let that = this;
    this.uploader = new AliyunUpload.Vod({
      userId: '112',
      region: 'cn-shanghai',
      partSize: 1048576,
      parallel: 5,
      retryCount: 3,
      retryDuration: 2,
      'onUploadstarted': async (uploadInfo) => {
        this.uploader.setUploadAuthAndAddress(uploadInfo, res.data.uploadAuth, res.data.uploadAddress, res.data.videoId);
      },
      'onUploadEnd': async function (uploadInfo) {
        let rsp = await getVideoSub(res.data.videoId);
        if (rsp.status === 204) {
          that.getVideoUrl(res.data.videoId);
        } else {
          message.error(rsp.data);
        }
      }
    });
    this.uploader.addFile(file, uploadInfos.Endpoint, uploadInfos.Bucket, null, '{"Vod":{}}');
    this.uploader.startUpload();
  }
  getVideoUrl = async (e) => {
    let res = await getVideoPlay(e);
    if (res.status === 200) {
      let allVideos = _.cloneDeep(this.state.allVideos);
      allVideos.push({
        id: e,
        url: res.data.playURL
      });
      this.setState({
        allVideos
      })
    }
  }
  onSwitchVideo = (vindex, index, e) => {
    let dataSource = [...this.state.dataSource];
    let allVideos = [...this.state.allVideos];
    if (dataSource[e.key]) {
      let newVideo = dataSource[index].videos.splice(vindex, 1)[0];
      dataSource[e.key].videos.push(newVideo);
    } else {
      let newVideo = dataSource[index].videos.splice(vindex, 1)[0];
      allVideos.push(newVideo);
    }
    this.setState({
      dataSource, allVideos
    });
  }
  delVideo = (index, vindex) => {
    let dataSource = _.cloneDeep(this.state.dataSource);
    dataSource[index].videos.splice(vindex, 1);
    this.setState({
      dataSource
    })
  }
  onSwitchVideoAll = (index, e) => {
    let dataSource = [...this.state.dataSource];
    let allVideos = [...this.state.allVideos];
    let newPic = allVideos.splice(index, 1)[0];
    dataSource[e.key].videos.push(newPic);
    this.setState({
      dataSource, allVideos
    });
  }
  delVideoAll = (index) => {
    let allVideos = [...this.state.allVideos];
    allVideos.splice(index, 1)
    this.setState({
      allVideos
    });
  }
}

export default IndexModal;
import React, { Component } from 'react';
import styles from './index.module.css';
import { message, Table, Input, Popover, Drawer, Select, Modal, Button, DatePicker, Checkbox } from 'antd';
import * as echarts from 'echarts';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import { KeyOutlined, } from '@ant-design/icons';
import { getDepartment_Data } from '../../config/storage';
import { jsToExcel2 } from '../../utils/exportExcel';
import { getMasterSummary, getMasterOrder, getMasterBonus } from '../../services/analysisCenter';

moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD';
const selectProp = ['订单汇总', '自助上传', '收入汇总', '好评汇总', '提现汇总', '变更汇总', '理赔汇总', '售后汇总'];


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: '',
      end: '',
      summary: {
        aftersaleNum: 0,
        aftersale: 0,
        after_num: 0,
        change: 0,
        income: 0,
        orderNum: 0,
        praise: 0,
        rebate: 0,
        rebateRate: 0,
        withdraw: 0,
        sameRate: 0,
        selfUpRate: 0,
        appoint3Rate: 0,
        insignRate: 0,
        dataReturn3hRate: 0,
        icomplete24hRate: 0,
        mVisit12hRate: 0,
        mVisit24hRate: 0,
        inVisit48Rate: 0,
      },
      currentMaster: -1,
      currentMasterName: '',
      dataSource: [],
      currentProp: '订单汇总',
      dataSourceOrder: [],
      orderStart: '',
      orderEnd: '',
      count: {
        '订单汇总': 0,
        '订单测量': 0,
        '订单安装': 0,
        '自助上传': 0,
        '收入汇总': 0,
        '返利汇总': 0,
        '好评汇总': 0,
        '提现汇总': 0,
        '变更汇总': 0,
        '理赔汇总': 0,
        '售后汇总': 0,
      },
      quickBtn: 0,
      billVisible: false,
      billData: [],
      billType: '',
    };
    this.role = getDepartment_Data().roles[0];
    this.columsBill = [
      {
        title: '订单编号',
        align: 'center',
        width: 110,
        dataIndex: 'order',
        key: 'order',
      },
      {
        title: '客户信息',
        align: 'center',
        width: 110,
        dataIndex: 'order_info',
        key: 'order_info',
      },
      {
        title: '师傅信息',
        align: 'center',
        width: 110,
        dataIndex: 'worker_info',
        key: 'worker_info',
      },
      {
        title: '金额',
        align: 'center',
        width: 110,
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: '通过时间',
        align: 'center',
        width: 110,
        dataIndex: 'check_time',
        key: 'check_time',
      },
    ];
  }
  componentDidMount() {
    let start = moment(new Date()).subtract(31, 'days');
    let end = moment(new Date());
    this.setState({
      start, end,
      orderStart: start,
      orderEnd: end,
    }, () => this.getMasterSum());
  }
  render() {
    const { start, end, summary, dataSource, currentMaster, currentMasterName, currentProp, dataSourceOrder,
      quickBtn, count, billVisible, billData, billType, } = this.state;
    const MasterColums = [
      {
        title: '师傅名称',
        align: 'center',
        width: 200,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        className: 'padding5',
        render: (text, record, index) => {
          return (
            <div className='row-sb w100'>
              <div className='w110 oneline'>{record.name}</div>
              <div className={`${styles['btn-look']} ${styles[currentMaster === record.id ? 'btn-look-select2' : 'btn-look-select1']}`}
                onClick={this.selectMaster.bind(this, record)}
              >查看</div>
            </div>
          );
        },
        filterResetToDefaultFilteredValue: true,
        ...this.getColumnSearchProps('name'),
      },
      {
        title: '订单汇总（单）',
        align: 'center',
        width: 200,
        dataIndex: 'orderNum',
        key: 'orderNum',
        className: 'padding5',
        render: (text, record, index) => {
          return (
            <div className='cursor-pointer'>{record.orderNum}(测量:{record.measure_num},安装:{record.install_num})</div>
          );
        },
        sorter: {
          compare: (a, b) => a.orderNum - b.orderNum
        },
        defaultSortOrder: 'orderNum'
      },
      {
        title: '自助上传（单）',
        align: 'center',
        width: 140,
        dataIndex: 'self_num',
        key: 'self_num',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.self_num - b.self_num
        },
      },
      {
        title: '3小时预约（单）',
        align: 'center',
        width: 140,
        dataIndex: 'hour3_num',
        key: 'hour3_num',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.hour3_num - b.hour3_num
        },
      },
      {
        title: '收入汇总（元）',
        align: 'center',
        width: 140,
        dataIndex: 'income',
        key: 'income',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.income - b.income
        }
      },
      // {
      //   title: '返利汇总（元）',
      //   align: 'center',
      //   width: 140,
      //   dataIndex: 'rebate',
      //   key: 'rebate',
      //   className: 'padding5',
      //   sorter: {
      //     compare: (a, b) => a.rebate - b.rebate
      //   }
      // },
      {
        title: '好评汇总（元）',
        align: 'center',
        width: 140,
        dataIndex: 'praise',
        key: 'praise',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.praise - b.praise
        }
      },
      {
        title: '提现汇总（元）',
        align: 'center',
        width: 140,
        dataIndex: 'withdraw',
        key: 'withdraw',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.withdraw - b.withdraw
        }
      },
      {
        title: '变更汇总（元）',
        align: 'center',
        width: 140,
        dataIndex: 'change',
        key: 'change',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.change - b.change
        }
      },
      {
        title: '理赔汇总（元）',
        align: 'center',
        width: 140,
        dataIndex: 'aftersale',
        key: 'aftersale',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.aftersale - b.aftersale
        }
      },
      {
        title: '售后汇总（单）',
        align: 'center',
        width: 140,
        dataIndex: 'after_num',
        key: 'after_num',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.after_num - b.after_num
        }
      },
      {
        title: '投诉汇总（单）',
        align: 'center',
        width: 140,
        dataIndex: 'aftersaleNum',
        key: 'aftersaleNum',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.aftersaleNum - b.aftersaleNum
        }
      },
      {
        title: '利润（率）',
        align: 'center',
        width: 140,
        dataIndex: 'rebateRate',
        key: 'rebateRate',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.rebateRate - b.rebateRate
        },
        render: (text, record, index) => {
          return (<div>{record.rebateRate > 0 ? Number(record.rebateRate * 100).toFixed(1) + '%' : 0}</div>);
        }
      },
      {
        title: '自助上传（率）',
        align: 'center',
        width: 140,
        dataIndex: 'selfUpRate',
        key: 'selfUpRate',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.selfUpRate - b.selfUpRate
        },
        render: (text, record, index) => {
          return (<div>{record.selfUpRate > 0 ? Number(record.selfUpRate * 100).toFixed(1) + '%' : 0}</div>);
        }
      },
      {
        title: '投诉（率）',
        align: 'center',
        width: 140,
        dataIndex: 'aftersaleNumRate',
        key: 'aftersaleNumRate',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.aftersaleNumRate - b.aftersaleNumRate
        },
        render: (text, record, index) => {
          return (<div>{record.aftersaleNumRate > 0 ? Number(record.aftersaleNumRate * 100).toFixed(1) + '%' : 0}</div>);
        }
      },
      {
        title: '3小时预约（率）',
        align: 'center',
        width: 140,
        dataIndex: 'appoint3Rate',
        key: 'appoint3Rate',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.appoint3Rate - b.appoint3Rate
        },
        render: (text, record, index) => {
          return (<div>{record.appoint3Rate > 0 ? Number(record.appoint3Rate * 100).toFixed(1) + '%' : 0}</div>);
        }
      },
      {
        title: '准时签到（率）',
        align: 'center',
        width: 140,
        dataIndex: 'insignRate',
        key: 'insignRate',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.insignRate - b.insignRate
        },
        render: (text, record, index) => {
          return (<div>{record.insignRate > 0 ? Number(record.insignRate * 100).toFixed(1) + '%' : 0}</div>);
        }
      },
      {
        title: '3小时数据回传（率）',
        align: 'center',
        width: 140,
        dataIndex: 'dataReturn3hRate',
        key: 'dataReturn3hRate',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.dataReturn3hRate - b.dataReturn3hRate
        },
        render: (text, record, index) => {
          return (<div>{record.dataReturn3hRate > 0 ? Number(record.dataReturn3hRate * 100).toFixed(1) + '%' : 0}</div>);
        }
      },
      {
        title: '24小时安装订单完成（率）',
        align: 'center',
        width: 140,
        dataIndex: 'icomplete24hRate',
        key: 'icomplete24hRate',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.icomplete24hRate - b.icomplete24hRate
        },
        render: (text, record, index) => {
          return (<div>{record.icomplete24hRate > 0 ? Number(record.icomplete24hRate * 100).toFixed(1) + '%' : 0}</div>);
        }
      },
      {
        title: '测量订单12上门时效（率）',
        align: 'center',
        width: 140,
        dataIndex: 'mVisit12hRate',
        key: 'mVisit12hRate',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.mVisit12hRate - b.mVisit12hRate
        },
        render: (text, record, index) => {
          return (<div>{record.mVisit12hRate > 0 ? Number(record.mVisit12hRate * 100).toFixed(1) + '%' : 0}</div>);
        }
      },
      {
        title: '24小时上门时效（率）',
        align: 'center',
        width: 140,
        dataIndex: 'mVisit24hRate',
        key: 'mVisit24hRate',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.mVisit24hRate - b.mVisit24hRate
        },
        render: (text, record, index) => {
          return (<div>{record.mVisit24hRate > 0 ? Number(record.mVisit24hRate * 100).toFixed(1) + '%' : 0}</div>);
        }
      },
      {
        title: '安装订单48小时上门时效（率）',
        align: 'center',
        width: 140,
        dataIndex: 'inVisit48Rate',
        key: 'inVisit48Rate',
        className: 'padding5',
        sorter: {
          compare: (a, b) => a.inVisit48Rate - b.inVisit48Rate
        },
        render: (text, record, index) => {
          return (<div>{record.inVisit48Rate > 0 ? Number(record.inVisit48Rate * 100).toFixed(1) + '%' : 0}</div>);
        }
      },
    ];
    let colums = this.getPropColumns(currentProp)
    return (
      <div className='col-t w100'>
        <div className='row-sb padding20 back-color0 w100'>
          <div className='row-l'>
            <div className={`${styles['tit']} tr`}>下单时间：</div>
            <DatePicker onChange={this.changeTime.bind(this, 'start')} value={start} locale={locale} format={dateFormat} allowClear={false} />
            <div className='margin-l10 margin-r10'>至</div>
            <DatePicker onChange={this.changeTime.bind(this, 'end')} value={end} locale={locale} format={dateFormat} allowClear={false} />
            <Button onClick={this.getMasterSum} type='primary' className='margin-l20 margin-r10'>搜索</Button>
          </div>
          <Button onClick={this.reset} type='primary' className={`back-color0 color1 ${styles['reset']}`}>重置</Button>
        </div>
        <div className='w100 col margin-t10 margin-b10'>
          <div className={`${styles['title']} tl w100 bold fz16`}>统计汇总</div>
          <div className='row-sa w100 back-color0'>
            <table>
              <tbody>
                <tr>
                  <Popover
                    content={
                      <div className='col-t vertical-lt'>
                        <div>测量订单:{summary.measureNum}</div>
                        <div>安装订单:{summary.installNum}</div>
                      </div>
                    }
                  >
                    <td className='bord1 w160 h90 tc cursor-pointer'>
                      <div>{summary.orderNum}</div>
                      <div>订单（单）</div>
                    </td>
                  </Popover>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.selfNum}</div>
                    <div>自助上传(单)</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.hour3_num}</div>
                    <div>3小时预约(单)</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{Number(summary.income).toFixed(1)}</div>
                    <div>收入（元）</div>
                  </td>
                  {/* <td className='bord1 w160 h90 tc'>
                    <div>{summary.rebate}</div>
                    <div>返利（元）</div>
                  </td> */}
                  <td className='bord1 w160 h90 tc cursor-pointer color3' onClick={this.onShowInfo.bind(this, '好评')}>
                    <div>{Number(summary.praise).toFixed(1)}</div>
                    <div>好评（元）</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{Number(summary.withdraw).toFixed(1)}</div>
                    <div>提现（元）</div>
                  </td>
                  <td className='bord1 w160 h90 tc cursor-pointer color3' onClick={this.onShowInfo.bind(this, '调整')}>
                    <div>{Number(summary.change).toFixed(1)}</div>
                    <div>变更（元）</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{Number(summary.aftersale).toFixed(1)}</div>
                    <div>理赔（元）</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.after_num}</div>
                    <div>售后（单）</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.aftersaleNum}</div>
                    <div>投诉（单）</div>
                  </td>
                </tr>
                <tr>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.rebateRate}</div>
                    <div>利润率</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.selfUpRate}</div>
                    <div>自助上传率</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.appoint3Rate}</div>
                    <div>3小时预约率</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.insignRate}</div>
                    <div>准时签到率</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.dataReturn3hRate}</div>
                    <div>3小时数据回传率</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.icomplete24hRate}</div>
                    <div>24小时安装订单完成率</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.mVisit12hRate}</div>
                    <div>测量订单12上门时效</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.mVisit24hRate}</div>
                    <div>24小时上门时效</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.inVisit48Rate}</div>
                    <div>安装订单48小时上门时效</div>
                  </td>

                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.aftersaleNumRate}</div>
                    <div>投诉率</div>
                  </td>
                  <td className='bord1 w160 h90 tc'>
                    <div>{summary.sameRate}</div>
                    <div>转化率</div>
                  </td>
                </tr>
              </tbody>
            </table>


          </div>
        </div>
        <div className='w100 back-color0 margin-b10'>
          <Table
            columns={MasterColums}
            dataSource={dataSource}
            size={'small'}
            rowKey={record => record.id}
            bordered
            scroll={{ x: 1500 }}
          />
        </div>
        <div className='row-sb vertical-lt w100'>
          <div className='w250'>
            <Select value={currentProp}
              onChange={this.changeProp}
              className='w250 margin-b5'
            >
              {selectProp.map((item, index) => {
                return <Select.Option value={item} key={index}>{item}</Select.Option>;
              })}
            </Select>
            <Table
              columns={colums}
              dataSource={dataSourceOrder}
              size={'small'}
              rowKey={record => record.id}
              bordered
            />
          </div>
          <div className='back-color0 padding20'>
            <div className='row-sb padding-tb10'>
              <div className='row-l'>
                <div className='fz18 bold'>{currentMasterName ? currentMasterName + '-每日' + currentProp : ''}</div>
                <div className={`${styles['selectDate']} color7`}>快速选择：</div>
                <div onClick={this.quickSelect.bind(this, 7)} className={quickBtn === 7 ? styles['quickly-select'] : styles['quickly']}>近7天</div>
                <div onClick={this.quickSelect.bind(this, 15)} className={quickBtn === 15 ? styles['quickly-select'] : styles['quickly']}>近15天</div>
                <div onClick={this.quickSelect.bind(this, 31)} className={quickBtn === 31 ? styles['quickly-select'] : styles['quickly']}>近31天</div>
                <div onClick={this.quickSelect.bind(this, 0)} className={quickBtn === 0 ? styles['quickly-select'] : styles['quickly']}>重置</div>
              </div>
              <div className='row-r'>
                <div className='color7'>汇总：</div>
                <div className={`${styles['summary']} row`}>
                  <div className='bold'>{count[currentProp]}</div>
                  <div className='color5'>（ {currentProp.slice(0, 2)}）</div>
                </div>
              </div>
            </div>
            <div className='padding-b20'>
              <div className='h340 w1300' id='main'></div>
            </div>
          </div>
        </div>
        <Drawer
          title={`${billType}流水`}
          visible={billVisible}
          onClose={this.hideBill}
          width={1200}
          footer={
            <div className='w100 row-r'>
              <Button type='primary' onClick={this.hideBill}>关闭</Button>
            </div>
          }
        >
          {
            [9, 11].includes(this.role) &&
            <Button onClick={this.toExcel} type='primary'>导出</Button>
          }
          <Table
            columns={this.columsBill}
            dataSource={billData}
            rowKey={record => record.order}
            size={'small'}
          />
        </Drawer>
      </div >
    );
  }
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={'搜索模型'}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div className={'row-sb'}>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            搜索
          </Button>
          <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            返回
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <KeyOutlined style={{ color: filtered ? '#1890ff' : undefined }} className='fz20 padding-lr5' />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm()
  };
  create = () => {
    const { dataSourceOrder, currentProp } = this.state;
    let currentValue = '';
    let max = 0;
    switch (currentProp) {
      case '订单汇总':
        currentValue = 'order_num';
        max = 50;
        break;
      case '自助上传':
        currentValue = 'self_num';
        max = 20;
        break;
      case '收入汇总':
        currentValue = 'income';
        max = 5000;
        break;
      case '返利汇总':
        currentValue = 'rebate';
        max = 1000;
        break;
      case '好评汇总':
        currentValue = 'praise';
        max = 1000;
        break;
      case '提现汇总':
        currentValue = 'withdraw';
        max = 2000;
        break;
      case '变更汇总':
        currentValue = 'change';
        max = 1000;
        break;
      case '理赔汇总':
        currentValue = 'aftersale';
        max = 1000;
        break;
      case '售后汇总':
        currentValue = 'afterSale_num';
        max = 50;
        break;
    }

    let option = {
      color: ['#07C160'],
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'line'
        }
      },
      grid: {
        left: '1%',
        right: '1%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: dataSourceOrder.map(item => {
          return moment(item.day).format('MM-DD');
        }),
        axisTick: {
          alignWithLabel: true,
        },
      },
      yAxis: {
        type: 'value',
        max: max
        // max: count
      },
      series: [
        {
          name: '师傅每日' + currentProp,
          type: 'bar',
          data: dataSourceOrder.map(item => {
            return item[currentValue];
          }),
          barWidth: '30%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#07C160',
                  fontsize: 15
                }
              }
            }
          }
        }
      ],
      dataZoom: [{
        type: 'slider',//给x轴设置滚动条
        xAxisIndex: 0,
        bottom: 0,
        height: 7,
        showDetail: false,
        startValue: 0,//滚动条的起始位置
        endValue: 25 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
      },]
    };
    option.color = currentProp === '订单汇总' ? ['#07C160', '#f3a75e', '#1890FF'] : ['#07C160'];
    option.series = currentProp === '订单汇总' ?
      [
        {
          name: '师傅每日订单汇总',
          type: 'bar',
          data: dataSourceOrder.map(item => {
            return item[currentValue];
          }),
          barWidth: '20%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#07C160',
                  fontsize: 15
                }
              }
            }
          }
        },
        {
          name: '师傅每日测量单',
          type: 'bar',
          data: dataSourceOrder.map(item => {
            return item.measure_num;
          }),
          barWidth: '20%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#f3a75e',
                  fontsize: 15
                }
              }
            }
          }
        },
        {
          name: '师傅每日安装单',
          type: 'bar',
          data: dataSourceOrder.map(item => {
            return item.install_num;
          }),
          barWidth: '20%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#1890FF',
                  fontsize: 15
                }
              }
            }
          }
        },
      ]
      : [
        {
          name: '师傅每日' + currentProp,
          type: 'bar',
          data: dataSourceOrder.map(item => {
            return item[currentValue];
          }),
          barWidth: '30%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#07C160',
                  fontsize: 15
                }
              }
            }
          }
        }
      ]

    let chartDom = document.getElementById('main');
    let myChart = echarts.init(chartDom);
    myChart.setOption(option)

  }
  changeTime = (type, e) => {
    if (type === 'start') {
      this.setState({
        start: e,
        orderStart: e,
      })
    } else {
      this.setState({
        end: e,
        orderEnd: e,
      })
    }
  }
  getMasterSum = async () => {
    const { start, end } = this.state;
    let starts = moment(start).format(dateFormat)
    let ends = moment(end).format(dateFormat)
    let currentMaster = -1;
    let currentMasterName = '';
    let res = await getMasterSummary(starts, ends);
    if (res.status === 200) {
      let summary = {
        aftersaleNum: 0,
        aftersaleNumRate: 0,
        aftersale: 0,
        after_num: 0,
        change: 0,
        income: 0,
        orderNum: 0,
        measureNum: 0,
        installNum: 0,
        selfNum: 0,
        praise: 0,
        rebate: 0,
        withdraw: 0,
        hour3_num: 0,
        hour12_num: 0,
        hour24_num: 0,
        sameRate: 0,

        sales: 0,
        rebateRate: 0,

        self_num: 0,
        fins_num: 0,
        fms_num: 0,
        onsign_num: 0,
        selfUpRate: 0,//自助上传率
        appoint3Rate: 0,//3小时预约率
        insignRate: 0,//准时签到率
        dataReturn3hRate: 0,//3小时数据回传率
        rp3Hour_num: 0,
        icomplete24hRate: 0,//24小时安装订单完成率
        ms12Hour_num: 0,
        mVisit12hRate: 0,//测量订单12上门时效
        ms24Hour_num: 0,
        mVisit24hRate: 0,//24小时上门时效
        inVisit48Rate: 0,//安装订单48小时上门时效
        ins48_num: 0,

      }
      res.data.list.map(item => {
        summary.aftersaleNum += item.aftersaleNum;
        summary.aftersale += item.aftersale;
        summary.after_num += item.after_num;
        summary.change += item.change;
        summary.income += item.income;
        summary.orderNum += item.orderNum;
        summary.measureNum += item.measure_num;
        summary.installNum += item.install_num;
        summary.selfNum += item.self_num;
        summary.praise += item.praise;
        summary.rebate += item.rebate;
        summary.withdraw += item.withdraw;
        summary.hour3_num += item.hour3_num;
        summary.hour12_num += item.hour12_num;
        summary.hour24_num += item.hour24_num;
        summary.self_num += item.self_num;
        summary.fins_num += item.fins_num;
        summary.fms_num += item.fms_num;
        summary.onsign_num += item.onsign_num;
        summary.rp3Hour_num += item.rp3Hour_num;
        summary.ms12Hour_num += item.ms12Hour_num;
        summary.ms24Hour_num += item.ms24Hour_num;
        summary.ins48_num += item.ins48_num;
        summary.sales += item.sales;
        if (item.fins_num + item.fms_num > 0) {
          let insms_num = item.fins_num + item.fms_num;
          item.selfUpRate = item.self_num / insms_num;
          item.appoint3Rate = item.hour3_num / insms_num;
          item.insignRate = item.onsign_num / insms_num;
        } else {
          item.selfUpRate = 0;
          item.appoint3Rate = 0;
          item.insignRate = 0;
        }
        if (item.fms_num > 0) {
          item.sameRate = item.self_num / item.fms_num;
          item.dataReturn3hRate = item.rp3Hour_num / item.fms_num;
          item.mVisit12hRate = item.ms12Hour_num / item.fms_num;
          item.mVisit24hRate = item.ms12Hour_num / item.fms_num;
        } else {
          item.sameRate = 0;
          item.dataReturn3hRate = 0;
          item.mVisit12hRate = 0;
          item.mVisit24hRate = 0;
        }
        if (item.fins_num > 0) {
          item.icomplete24hRate = item.hour24_num / item.fins_num;
          item.inVisit48Rate = item.ins48_num / item.fins_num;
        } else {
          item.icomplete24hRate = 0;
          item.inVisit48Rate = 0;
        }
        if (item.sales) {
          item.rebateRate = (item.sales - item.income) / item.sales;
        } else {
          item.rebateRate = 0;
        }
        if (item.orderNum) {
          item.aftersaleNumRate = item.aftersaleNum / item.orderNum;
        } else {
          item.aftersaleNumRate = 0;
        }
      })
      // let sales = Math.abs(summary.sales);
      if (summary.sales) {
        summary.rebateRate = Number((summary.sales - summary.income) / summary.sales * 100).toFixed(1) + '%';
      } else {
        summary.rebateRate = 0;
      }
      if (summary.orderNum) {
        summary.aftersaleNumRate = Number(summary.aftersaleNum / summary.orderNum * 100).toFixed(1) + '%';
      } else {
        summary.aftersaleNumRate = 0;
      }
      if (summary.fins_num + summary.fms_num > 0) {
        let insms_num = summary.fins_num + summary.fms_num;
        summary.selfUpRate = Number(summary.selfNum / insms_num * 100).toFixed(1) + '%';
        summary.appoint3Rate = Number(summary.hour3_num / insms_num * 100).toFixed(1) + '%';
        summary.insignRate = Number(summary.onsign_num / insms_num * 100).toFixed(1) + '%';
      } else {
        summary.sameRate = 0;
      }
      if (summary.fms_num > 0) {
        summary.sameRate = Number(summary.self_num / summary.fms_num * 100).toFixed(1) + '%';
        summary.dataReturn3hRate = Number(summary.rp3Hour_num / summary.fms_num * 100).toFixed(1) + '%';
        summary.mVisit12hRate = Number(summary.ms12Hour_num / summary.fms_num * 100).toFixed(1) + '%';
        summary.mVisit24hRate = Number(summary.ms12Hour_num / summary.fms_num * 100).toFixed(1) + '%';
      }
      if (summary.fins_num > 0) {
        summary.icomplete24hRate = Number(summary.hour24_num / summary.fins_num * 100).toFixed(1) + '%';
        summary.inVisit48Rate = Number(summary.ins48_num / summary.fins_num * 100).toFixed(1) + '%';
      }
      // 准时签到率onsign_num/(fins_num+fms_num)
      if (res.data.list.length > 0) {
        currentMaster = res.data.list[0].id;
        currentMasterName = res.data.list[0].name;
      }
      this.setState({
        dataSource: res.data.list,
        summary,
        currentMaster,
        currentMasterName,
      }, () => this.getCurrentMaster())
    }
  }
  selectMaster = (e) => {
    this.setState({
      currentMaster: e.id,
      currentMasterName: e.name,
    }, () => this.getCurrentMaster())
  }
  changeProp = (e) => {
    this.setState({
      currentProp: e,
    }, () => this.create())
  }
  quickSelect = (e) => {
    let newStart = '';
    let newEnd = '';
    if (e === 0) {
      newStart = this.state.start;
      newEnd = this.state.end;
    } else {
      newStart = moment(new Date()).subtract(e, 'days');
      newEnd = moment(new Date());
    }
    this.setState({
      orderStart: newStart,
      orderEnd: newEnd,
      quickBtn: e,
    }, () => this.getCurrentMaster());

  }
  getCurrentMaster = async () => {
    const { orderStart, orderEnd, currentMaster } = this.state;
    let starts = moment(orderStart).format(dateFormat)
    let ends = moment(orderEnd).format(dateFormat)
    let res = await getMasterOrder(currentMaster, starts, ends);
    if (res.status !== 200) {
      message.error(res, data);
      return;
    }
    let count = {
      '订单汇总': 0,
      '订单测量': 0,
      '订单安装': 0,
      '自助上传': 0,
      '收入汇总': 0,
      '返利汇总': 0,
      '好评汇总': 0,
      '提现汇总': 0,
      '变更汇总': 0,
      '理赔汇总': 0,
      '售后汇总': 0,
    };
    res.data.list.map(item => {
      count['订单汇总'] += item.order_num;
      count['订单测量'] += item.measure_num;
      count['订单安装'] += item.install_num;
      count['自助上传'] += item.self_num;
      count['收入汇总'] += item.income;
      count['返利汇总'] += item.rebate;
      count['好评汇总'] += item.praise;
      count['提现汇总'] += item.withdraw;
      count['变更汇总'] += item.change;
      count['理赔汇总'] += item.aftersale;
      count['售后汇总'] += item.afterSale_num;
    })
    this.setState({
      dataSourceOrder: res.data.list,
      count,
    }, () => this.create());
  }
  getPropColumns = (e) => {
    if (e === '订单汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '订单汇总（单）',
          align: 'center',
          width: 150,
          dataIndex: 'order_num',
          key: 'order_num',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{record.order_num} (测量:{record.measure_num},安装:{record.install_num})</div>;
          }
        },
      ];
    } else if (e === '自助上传') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '自助上传（单）)',
          align: 'center',
          width: 150,
          dataIndex: 'self_num',
          key: 'self_num',
          className: 'padding5',
        },
      ];
    } else if (e === '收入汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '收入汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'income',
          key: 'income',
          className: 'padding5',
        },
      ];
    } else if (e === '返利汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '返利汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'pay',
          key: 'pay',
          className: 'padding5',
        },
      ];
    } else if (e === '好评汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '好评汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'praise',
          key: 'praise',
          className: 'padding5',
        },
      ];
    } else if (e === '提现汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '提现汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'withdraw',
          key: 'withdraw',
          className: 'padding5',
        },
      ];
    } else if (e === '变更汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '变更汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'change',
          key: 'change',
          className: 'padding5',
        },
      ];
    } else if (e === '理赔汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '理赔汇总（元）)',
          align: 'center',
          width: 150,
          dataIndex: 'aftersale',
          key: 'aftersale',
          className: 'padding5',
        },
      ];
    } else if (e === '售后汇总') {
      return [
        {
          title: '下单时间',
          align: 'center',
          width: 150,
          dataIndex: 'date',
          key: 'date',
          className: 'padding5',
          render: (text, record, index) => {
            return <div>{moment(record.day).format(dateFormat)}</div>;
          }
        },
        {
          title: '售后汇总（单）)',
          align: 'center',
          width: 150,
          dataIndex: 'afterSale_num',
          key: 'afterSale_num',
          className: 'padding5',
        },
      ];
    }
  }
  reset = () => {
    let start = moment(new Date()).subtract(31, 'days');
    let end = moment(new Date());
    this.setState({
      start, end,
      orderStart: start,
      orderEnd: end,
    }, () => this.getMasterSum());
  }
  //弹窗
  getBonusInfo = async (type) => {
    const { start, end } = this.state;
    let starts = moment(start).format(dateFormat)
    let ends = moment(end).format(dateFormat)
    let res = await getMasterBonus(type, starts, ends);
    if (res.status === 200) {
      this.setState({
        billData: res.data
      });
    }
  }
  onShowInfo = (type) => {
    this.getBonusInfo(type);
    this.setState({
      billVisible: true,
      billType: type,
    })
  }
  hideBill = () => {
    this.setState({
      billVisible: false,
    })
  }
  toExcel = () => {
    const { billData, billType, start, end } = this.state;
    let starts = moment(start).format(dateFormat)
    let ends = moment(end).format(dateFormat)
    let type = billType === '调整' ? '变更' : billType;
    jsToExcel2(this.columsBill, billData, `${starts}~${ends}${type}流水汇总`);
  }
}

export default Index;
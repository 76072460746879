import React, { Component } from 'react';
import TableList from '../tableList';
import styles from './index.module.css';
import { Button, Input, Select, DatePicker, Checkbox, message, Spin, Radio } from 'antd';
import { getLabels, getOrderList, getMasterManager, getCustomManager, getOrderCount } from '../../../services/orderCore';
import { getLabel } from '../../../services/labelManagement';
import { getDepartment_Data } from '../../../config/storage';
import PageTurning from '../../../components/pageTurning';
import WorkerModal from './workerModal';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
moment.locale('zh-cn');
import _ from 'lodash';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import TaskModal from '../../task/index';
import { getTaskbase } from '../../../services/task';
import { jsToExcel2, correctExcel } from '../../../utils/exportExcel';
import { connect } from 'dva';

const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
// let startDate = new Date(new Date() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');
// let endDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');
let startDate = moment(new Date()).subtract(3, 'months').format(dateFormat);
let endDate = moment(new Date()).add(1, 'days').format(dateFormat);
let appointDate = moment(new Date()).add(1, 'days').format(dateFormat);
const quickLabel = ['不限', '临时加急', '投诉订单', '异常订单', '同步任务', '费用申请', '特别关注', '工程单', '开发订单', '待回访订单', '待审核订单'];
const orderState = ['全部', '待开发', '待分配', '待预约', '预约失败', '待上门', '已签到', '待收款', '已完成', '已终止'];
const newData = {
  id: 0,
  name: '全部'
}
const additions = ['全部', '电动轨道', '窗帘盒', '梦幻帘', '磁控百叶', '工程单'];
const tranList = ['全部', '已转化', '未转化'];
const reasons = ['全部', '分机号过期', '电话未接', '未装修好', '卫生未完成', '未到货', '到货等通知', '拒接电话', '时间暂不确定'];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerValue: '',
      startDates: startDate,
      endDates: endDate,
      appointDate: appointDate,
      serviceList: ['全部', '测量', '安装'],
      data: [],
      total: 30,
      types: '全部',
      serviceType: '全部',
      orderType: '全部',
      searchType: '全部',
      orderStateType: '全部',
      addition: '全部',
      trans: '全部',
      masterLabels: [
        {
          id: 0,
          name: '全部',
        }
      ],
      masterlabel: '全部',
      transFee: '全部',
      reason: '全部',
      masterlabelId: 0,
      skip: 0,
      take: 10,
      labelData: [],
      labelDataType: 0,
      // myCheckbox: true,
      myCheckbox: true,
      twiceReason: '',
      workerData: { ...newData },
      merchantData: { ...newData },
      kefuData: { ...newData },
      pageIndex: 1,
      taskbase: {},
      timeType: 'order',
      isLoading: false,
      orderCount: {
        '待开发': 0,
        '待分配': 0,
        '待接单': 0,
        '待预约': 0,
        '待上门': 0,
        '已签到': 0,
      },
      taskVisible: false,
      download: false,
    }
    this.role = getDepartment_Data().roles[0];
    this.pageSize = 20;
    this.workerModal = React.createRef();
    this.taskModal = React.createRef();
  }
  componentDidMount() {
    if (this.props.terms !== '') {
      const { terms } = this.props
      this.setState({
        searchType: terms.searchType,
        addition: terms.addition,
        customerValue: terms.customerValue,
        types: terms.types,
        serviceType: terms.serviceType,
        orderType: terms.orderType,
        orderStateType: terms.orderStateType,
        timeType: terms.timeType,
        startDates: terms.startDates,
        appointDate: terms.appointDate,
        endDates: terms.endDates,
        workerData: terms.workerData,
        merchantData: terms.merchantData,
        kefuData: terms.kefuData,
        myCheckbox: terms.myCheckbox,
        pageIndex: terms.pageIndex,
        masterlabel: terms.masterlabel,
        masterlabelId: terms.masterlabelId,
      });
      this.pageSize = terms.pageSize;
    }
    if (this.props.state === '') {
      this.getDataList()
      this.getLabelsList();
    } else if (this.props.state == '待分配' || this.props.state == '待接单' || this.props.state == '待预约') {
      this.setState({
        orderStateType: this.props.state
      }, async () => this.getDataList())
      this.getLabelsList();
    } else {
      this.getDataList()
      this.getLabelsList2(this.props.state);
    }
    this.onTaskbase();
    this.getMasterLabel();
  }
  componentWillUnmount() {
    const { dispatch, state } = this.props;
    let {
      startDates, endDates, appointDate, timeType,
      customerValue, types, addition,
      serviceType, orderType,
      searchType, orderStateType,
      workerData,
      merchantData, myCheckbox,
      kefuData, pageIndex, masterlabel, masterlabelId
    } = this.state;
    dispatch({
      type: 'orderRenter/panelToAllOrder',
      payload: '',
    });
    dispatch({
      type: 'orderRenter/setTerms',
      payload: {
        searchType: searchType,
        addition: addition,
        customerValue: customerValue,
        types: types,
        serviceType: serviceType,
        orderType: orderType,
        orderStateType: orderStateType,
        timeType: timeType,
        startDates: startDates,
        appointDate: appointDate,
        endDates: endDates,
        workerData: workerData,
        merchantData: merchantData,
        kefuData: kefuData,
        myCheckbox: myCheckbox,
        pageIndex: pageIndex,
        pageSize: this.pageSize,
        masterlabel: masterlabel,
        masterlabelId: masterlabelId,
      },
    })
  }
  render() {
    const {
      startDates, endDates, appointDate, timeType, trans, transFee, reason,
      customerValue, serviceList,
      types, serviceType, addition, twiceReason,
      orderType, searchType, masterlabel, masterLabels, masterlabelId,
      orderStateType, labelData,
      labelDataType, workerData,
      merchantData, myCheckbox,
      kefuData, data, taskbase, isLoading, orderCount,
      taskVisible, download,
    } = this.state;
    return (
      <Spin tip='数据加载中' size='large' spinning={isLoading} className='loading'>
        <div id='test'>
          <div className='col back-color0 vertical-lt padding20 margin-b20'>
            <div className='w100 row-sb'>
              <div className='row-l'>
                <span className={styles['title_style']}>客户信息:</span>
                <Input value={customerValue} className='w486' placeholder='请客户相关信息' onChange={this.onCustomerValue} />
                <Button type='primary' className='margin-l20 margin-r20' onClick={this.toSearch}>搜索</Button>
                <Button onClick={this.onResetClick}>重置</Button>
                {/* <Button type='primary' className='margin-l20' onClick={this.onResetClick}>更新数据</Button> */}
              </div>
              {
                [9, 11].includes(this.role) &&
                <Button loading={download} onClick={this.testExcel} type='primary' className='vertical-rb'>表格导出</Button>
              }
            </div>
            <div className='row-l margin-t10'>
              <span className={styles['title_style']}>选择品类:</span>
              <Select value={types} className='w198' placeholder='请选择品类' onChange={this.onTypesChang}>
                <Option value='全部'>全部</Option>
                <Option value='窗帘'>窗帘</Option>
                <Option value='墙布墙纸'>墙布墙纸</Option>
                <Option value='晾衣架'>晾衣架</Option>
                <Option value='地板'>地板</Option>
                <Option value='其他'>其他</Option>
              </Select>
              <div className='margin-l20'>
                <span className={styles['title_style']}>服务类型:</span>
                <Select value={serviceType} className='w198' placeholder='请选择服务类型' onChange={this.onServiceTypeChange}>
                  {
                    serviceList.map((item, index) => {
                      return <Option key={index} value={item}>{item}</Option>
                    })
                  }
                </Select>
              </div>
              <span className={`${styles['title_style']} margin-l20`}>订单类型:</span>
              <Select value={orderType} className='w198' placeholder='请选择订单类型' onChange={this.onOrderTypeChange}>
                <Option value='全部'>全部</Option>
                <Option value='二次上门'>二次上门</Option>
                <Option value='售后订单'>售后订单</Option>
                <Option value='正常订单'>正常订单</Option>
              </Select>
              {
                orderType === '二次上门' &&
                <div className='margin-l20'>
                  <span className='w110'>二次上门原因：</span>
                  <Select value={twiceReason} className='w198' placeholder='请选择订单类型' onChange={this.onTwiceSearch}>
                    <Option value=''>全部</Option>
                    <Option value='货不齐'>货不齐</Option>
                    <Option value='缺少配件'>缺少配件</Option>
                    <Option value='师傅空跑'>师傅空跑</Option>
                    <Option value='测量复尺'>测量复尺</Option>
                    <Option value='安装窗帘'>安装窗帘</Option>
                  </Select>
                </div>
              }
              <span className={`${styles['title_style']} margin-l20`}>转化状态:</span>
              <Select value={trans} className='w198' onChange={this.onTransChange}>
                <Option value='全部'>全部</Option>
                <Option value='已转化'>已转化</Option>
                <Option value='未转化'>未转化</Option>
              </Select>
              <span className={`${styles['title_style']} w80 margin-l20`}>安装转化单:</span>
              <Select value={transFee} className='w150' onChange={this.onTransFreeChange}>
                <Option value='全部'>全部</Option>
                <Option value='有'>有</Option>
                <Option value='无'>无</Option>
              </Select>
            </div>
            <div className='row-l margin-t10'>
              <span className={styles['title_style']}>搜索类型:</span>
              <Select value={searchType} className='w198' placeholder='请选择类型' onChange={this.onSearchTypeChange}>
                <Option value='全部'>全部</Option>
                <Option value='未预约'>未预约</Option>
                <Option value='加急'>加急</Option>
                <Option value='催联系'>催联系</Option>
                <Option value='好评'>好评</Option>
                <Option value='待审核'>待审核</Option>
              </Select>
              <div className='margin-l20'>
                <span className={styles['title_style']}>商家客服:</span>
                <Input disabled={true} className='w198' value={kefuData.name} />
                <Button type='primary' className='margin-l10' onClick={this.openModal.bind(this, '客服')}>选择</Button>
              </div>
              <div className='margin-l20'>
                <span className={styles['title_style']}>我的区域:</span>
                <Checkbox checked={myCheckbox} onChange={this.onCheckboxChange} />
              </div>
              {
                // timeType === 'order' ?
                <div className='row-l margin-l20'>
                  <span className={styles['title_style']}>{timeType === 'order' ? '下单时间' : '预约时间'}:</span>
                  <DatePicker key='start' placeholder='开始时间' className='w198' locale={locale} allowClear={false}
                    value={moment(startDates, dateFormat)} onChange={this.onChangeDate.bind(this, 'start')} />
                  <div>至</div>
                  <DatePicker key='end' placeholder='结束时间' className='w198' locale={locale} allowClear={false}
                    value={moment(endDates, dateFormat)} onChange={this.onChangeDate.bind(this, 'end')} />
                </div>
                // :
                // <div className='row-l margin-l20'>
                //   <span className={styles['title_style']}>预约时间:</span>
                //   <DatePicker key='appoint' placeholder='预约时间' className='w198' value={moment(appointDate)} onChange={this.onChangeDate.bind(this, 'appoint')} />
                // </div>
              }
              <Button type='primary' className='margin-l20' onClick={this.onTimeTypeChange.bind(this, timeType)}>切换为{timeType === 'order' ? '预约时间' : '下单时间'}</Button>

            </div>
            <div className='row-l margin-t10'>
              <span className={styles['title_style']}>师傅信息:</span>
              <Input disabled={true} className='w198' value={workerData.name} />
              <Button type='primary' className='margin-l10' onClick={this.openModal.bind(this, '师傅')}>选择</Button>
              <div className='margin-l20'>
                <span className={styles['title_style']}>师傅标签:</span>
                <Select value={masterlabelId} onChange={this.onChangeMasterLabel} className='w166'>
                  {
                    masterLabels.length > 0 &&
                    masterLabels.map((item, index) => {
                      return (
                        <Option value={item.id} key={index}>{item.name}</Option>
                      );
                    })
                  }
                </Select>
              </div>
              <div className='margin-l20'>
                <span className={styles['title_style']}>商户信息:</span>
                <Input disabled={true} className='w198' value={merchantData.name} />
                <Button type='primary' className='margin-l10' onClick={this.openModal.bind(this, '商户')}>选择</Button>
              </div>
              <div className='margin-l20'>
                <span className={styles['title_style']}>服务筛选:</span>
                <Select value={addition} className='w198' onChange={this.additionChange}>
                  {
                    additions.map((item, index) => {
                      return <Option key={index} value={item}>{item}</Option>
                    })
                  }
                </Select>
              </div>
            </div>
            <div className='row-l margin-t10'>
              <div className='row-w'>
                <span className={styles['title_style']}>订单状态:</span>
                {
                  orderState.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`cursor-pointer tc fz15 ${styles['menu']} ${styles[item === orderStateType ? 'menu-select2' : 'menu-select1']}`}
                        onClick={this.onOrderStateTypeChange.bind(this, item)}
                      >
                        {item}{isNaN(orderCount[item]) ? '' : '（' + orderCount[item] + ')'}
                      </div>
                    );
                  })
                }
              </div>
            </div>
            {
              orderStateType === '预约失败' &&
              <div className='row-l margin-t10'>
                <span className={styles['title_style']}>失败原因:</span>
                <div className='row-w'>
                  {
                    reasons.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`cursor-pointer tc fz15 ${styles['menu']} ${styles[item === reason ? 'menu-select2' : 'menu-select1']}`}
                          onClick={this.onOrderReasonChange.bind(this, item)}
                        >
                          {item}
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            }
            <div className={styles['splitLine']}></div>
            <div className='row-w'>
              <span className={`${styles['title_style']} margin-b5`}>我的任务:</span>
              {
                labelData.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className={`${styles['quickLabel_div']} row ${this.getTaskNum(taskbase, item.id) === 0 && taskVisible ? 'hidden' : ''}`}
                      onClick={this.onLabelDataClick.bind(this, item.id, item.name)}
                    >
                      <div>{item.name}</div>
                      <div className='color2 bold'>{`（${this.getTaskNum(taskbase, item.id)}）`}</div>
                    </div>
                  )
                })
              }
              <div onClick={this.hiddenEmptyTask} className={`${styles['menu']} ${styles['menu-select2']} ${taskVisible ? '' : 'hidden'} margin-b5 tc cursor-pointer`}>展开</div>
              <div onClick={this.showAllTask} className={`${styles['menu']} ${styles['menu-select2']} ${taskVisible ? 'hidden' : ''} margin-b5 tc cursor-pointer`}>收起</div>
            </div>
          </div>

          <div className='w100 margin-t10 back-color0 '>
            <TableList data={data}
              updateTable={this.updateTables}
              getDataList={this.getDataList}
              delLabelData={this.delLabelData}
              updateTipData={this.updateTipData}
              setDisplay={this.setDisplay}
              onHoverSet={this.onHoverSet}
            />
            <div className={'row-r padding10'}>
              <div className={`${styles['pageStyle']} margin-r10`} onClick={this.changePageIndex.bind(this, '-')} ><LeftOutlined />上一页</div>
              <div className={`${styles['pageStyle']} `} onClick={this.changePageIndex.bind(this, '+')} >下一页<RightOutlined /></div>
              <div className='row-l margin-l10 fz18'>
                向第
                {
                  this.state.pageEdit ?
                    <input className={`${styles['width-50']} margin-r5 margin-l5`}
                      onFocus={this.inputOnFocus}
                      onBlur={this.inputOnBlur}
                      onChange={this.onChange}
                      value={this.state.pageIndex}
                    />
                    :
                    <div className={`${styles['width-50']} ${styles['inline']} margin-r5 margin-l5`} onClick={this.onChangeDiv}>{this.state.pageIndex}</div>
                }
                页跳转
              </div>
            </div>
          </div>
          <TaskModal
            ref={this.taskModal}
            updateTable={this.updateTaskTable}
          />
          <WorkerModal
            ref={this.workerModal}
            updateTable={this.updateTable}
          />
        </div>
      </Spin>
    );
  }
  getMasterLabel = async () => {
    let res = await getLabel('全部', '师傅', 0, 200);
    if (res.status === 200) {
      // let masterLabels = [{
      //   id: 0,
      //   name: '全部',
      // },
      // ...res.data,]
      let masterLabels = res.data.filter(e => e.enable === '是');
      masterLabels.unshift({ id: 0, name: '全部', })
      this.setState({
        masterLabels,
      })
    }
  }
  //获取任务数量
  getTaskNum = (taskbase, id) => {
    let num = 0;
    if (JSON.stringify(taskbase) === '{}') {
      return num;
    } else {
      if (taskbase[id]) {
        num = taskbase[id]
      }
    }
    return num
  }
  //任务总览
  onTaskbase = async () => {
    let res = await getTaskbase();
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      taskbase: res.data,
    })
  }
  updateTaskTable = () => {
    this.onTaskbase();
  }
  updateTipData = () => {
    this.getDataList();
    this.onTaskbase()
  }
  setDisplay = (index) => {
    let data = _.cloneDeep(this.state.data);
    data[index].workerDisplay = !data[index].workerDisplay;
    this.setState({
      data: data
    })
  }
  delLabelData = (index, indexs) => {
    let data = _.cloneDeep(this.state.data);
    data[index].serviceLabels.splice(indexs, 1);
    this.setState({
      data: data
    })
  }
  onTransChange = (e) => {
    this.setState({
      trans: e
    })
  }
  onTransFreeChange = (e) => {
    this.setState({
      transFee: e
    })
  }
  //修改订单
  updateTables = (row) => {
    this.getDataList();
  }
  //师傅标签
  onChangeMasterLabel = (e) => {
    const { masterLabels } = this.state;
    let masterlabel = masterLabels.filter(r => r.id === e)[0].name;
    this.setState({
      masterlabelId: e,
      masterlabel
    });
  }
  //我的区域
  onCheckboxChange = (e) => {
    this.setState({
      myCheckbox: e.target.checked
    });
  }
  //快捷标签
  onLabelDataClick = (id, name) => {
    this.taskModal.current.onShowModal(id, name);
    // this.setState({
    //   labelDataType: e
    // }, async () => this.getDataList())
  }
  //订单状态
  onOrderStateTypeChange = (e) => {
    this.setState({
      orderStateType: e
    })
  }
  // 预约失败原因
  onOrderReasonChange = (e) => {
    this.setState({
      reason: e
    })
  }
  //搜索类型
  onSearchTypeChange = (e) => {
    this.setState({
      searchType: e
    })
  }
  //订单类型
  onOrderTypeChange = (e) => {
    this.setState({
      orderType: e,
      twiceReason: ''
    })
  }
  onTwiceSearch = (e) => {
    this.setState({
      customerValue: e,
      twiceReason: e,
    }, async () => this.getDataList())
  }
  //服务类型
  onServiceTypeChange = (e) => {
    this.setState({
      serviceType: e
    })
  }
  //时间类型
  onTimeTypeChange = (e) => {
    let timeType = e === 'order' ? 'appoint' : 'order';
    this.setState({
      timeType
    });
  }
  //选择品类
  onTypesChang = (e) => {
    let serviceType = '';
    let serviceList = [];
    if (e === '晾衣架') {
      serviceType = '安装'
      serviceList = ['安装'];
    } else if (e === '其他') {
      serviceType = '全部'
      serviceList = ['全部', '测量', '安装'];
    } else {
      serviceType = '全部'
      serviceList = ['全部', '测量', '安装'];
    }
    this.setState({
      types: e,
      serviceType: serviceType,
      serviceList: serviceList
    })
  }
  //订单中存在电动轨道，窗帘盒，梦幻帘的筛选
  additionChange = (e) => {
    this.setState({
      addition: e
    })
  }
  updateTable = (row, type) => {
    if (row) {
      if (type === '师傅') {
        this.setState({
          workerData: row
        })
      } else if (type === '商户') {
        this.setState({
          merchantData: row
        })
      } else {
        this.setState({
          kefuData: row
        })
      }
    }

  }
  //打开modal
  openModal = (e) => {
    this.workerModal.current.onShowModal(e);
  }
  //获取标签
  getLabelsList = async () => {
    let res = await getLabels();
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let labelData = res.data;
    this.setState({
      labelData: labelData,
    })
  }
  //工作台跳转订单中心
  getLabelsList2 = async (e) => {
    let res = await getLabels();
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let labelData = res.data;
    this.setState({
      labelData: labelData,
    }, () => {
      let task = res.data.filter(item => item.name === e)[0];
      this.onLabelDataClick(task.id, task.name);
    })
  }
  //重置
  onResetClick = () => {
    this.setState({
      customerValue: '',
      types: '全部',
      serviceType: '全部',
      orderType: '全部',
      searchType: '全部',
      orderStateType: '全部',
      transFee: '全部',
      reason: '全部',
      workerData: { ...newData },
      merchantData: { ...newData },
      kefuData: { ...newData },
      myCheckbox: true,
      pageIndex: 1
    }, async () => this.getDataList())
  }
  //客户信息
  onCustomerValue = (e) => {
    this.setState({
      customerValue: e.target.value,
      pageIndex: 1
    })
  }
  //订单数量
  // 
  getCount = async () => {
    const { myCheckbox } = this.state;
    let orderCount = { ...this.state.orderCount };
    let res = await getOrderCount(myCheckbox);
    if (res.status !== 200) {
      message.error(res.status);
      return;
    }
    orderCount['待开发'] = res.data['待开发'] ? res.data['待开发'] : 0;
    orderCount['待分配'] = res.data['待分配'] ? res.data['待分配'] : 0;
    orderCount['待接单'] = res.data['待接单'] ? res.data['待接单'] : 0;
    orderCount['待预约'] = res.data['待预约'] ? res.data['待预约'] : 0;
    orderCount['预约失败'] = res.data['预约失败'] ? res.data['预约失败'] : 0;
    orderCount['待上门'] = res.data['待上门'] ? res.data['待上门'] : 0;
    orderCount['已签到'] = res.data['已签到'] ? res.data['已签到'] : 0;
    this.setState({
      orderCount,
    });
  }
  //获取数据
  //_.throttle节流
  //_.debounce防抖
  toSearch = () => {
    // this.setState({
    //   pageIndex: 1,
    // }, () => this.getDataList());
    this.getDataList()
  }
  getDataList = _.throttle(async (e) => {
    await this.setState({
      isLoading: true,
    });
    let {
      startDates, endDates, appointDate, timeType,
      customerValue, types, addition, trans, transFee, reason,
      serviceType, orderType,
      searchType, orderStateType,
      workerData,
      merchantData, myCheckbox,
      kefuData, pageIndex, masterlabel,
    } = this.state;
    let start = timeType === 'order' ? startDates : appointDate;
    let mode = timeType === 'order' ? '下单时间' : '预约时间';
    let res = await getOrderList({
      search: searchType,
      addition: addition,
      info: customerValue,
      category: types,
      service: serviceType,
      type: orderType,
      state: orderStateType,
      start: startDates,
      // start: start,
      end: endDates,
      worker: workerData.id,
      shop: merchantData.id,
      user: kefuData.id,
      mode: mode,
      myarea: myCheckbox,
      label: masterlabel,
      trans: trans,
      transFee: transFee,
      reason: reason,
      ...this.pageMap(),
    })
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let data = res.data;
    data.forEach(item => {
      item.workerDisplay = false;
    })
    this.getCount()
    this.setState({
      data: data,
      isLoading: false
    })
  }, 1000);
  //时间
  onChangeDate = (type, dates, dateStrings) => {
    if (type === 'start') {
      this.setState({
        startDates: dateStrings
      })
    } else if (type === 'appoint') {
      this.setState({
        appointDate: dateStrings
      })
    } else {
      this.setState({
        endDates: dateStrings
      })
    }
  }

  onHoverSet = (index, props, value) => {
    let data = [...this.state.data];
    data[index][props] = value;
    this.setState({
      data
    });
  }

  //换页
  onChangeDiv = () => {
    this.setState({
      pageEdit: true,
    });
  }

  pageMap = () => {
    return ({
      skip: (this.state.pageIndex - 1) * this.pageSize,
      take: this.pageSize
    });
  }

  onChange = (e) => {
    let value = e.target.value;
    if (value === '') {
      this.setState({
        pageIndex: ''
      });
      return;
    }
    if (isNaN(value)) {
      return;
    } else {
      let number = Number(value);
      if (number < 1 || number % 1 !== 0) {
        return;
      }
    }
    this.setState({
      pageIndex: Number(e.target.value)
    });
  }

  inputOnBlur = (e) => {
    this.setState({
      pageEdit: false,
    });
    if (this.state.pageIndex === '') {
      this.setState({
        pageIndex: this.tempPageIndex,
      });
      return;
    }
    this.getDataList();
  }

  inputOnFocus = (e) => {
    this.tempPageIndex = e.target.value;
  }

  changePageIndex = async (type) => {
    let pageIndex = _.cloneDeep(this.state.pageIndex);
    if (type === '-' && pageIndex > 1) {
      pageIndex--;
      await this.setState({
        pageIndex: pageIndex
      })
    } else if (type === '+') {
      pageIndex++;
      await this.setState({
        pageIndex: pageIndex
      });
    } else {
      message.warn('当前为第一页');
    }
    this.getDataList();
  }
  //展开全部任务
  showAllTask = () => {
    this.setState({
      taskVisible: true,
    });
  }
  hiddenEmptyTask = () => {
    this.setState({
      taskVisible: false,
    });
  }
  testExcel = async () => {
    let down = true;
    let dataSource = [];
    this.setState({
      download: true,
    })
    let {
      startDates, endDates, appointDate, timeType,
      customerValue, types, addition, trans,
      serviceType, orderType,
      searchType, orderStateType,
      workerData,
      merchantData, myCheckbox,
      kefuData, pageIndex, masterlabel,
    } = this.state;
    let skip = 0;
    // let take = 3000;
    let take = 100;
    let data = [];
    while (down === true) {
      let start = timeType === 'order' ? startDates : appointDate;
      let mode = timeType === 'order' ? '下单时间' : '预约时间';
      let res = await getOrderList({
        search: searchType,
        addition: addition,
        info: customerValue,
        category: types,
        service: serviceType,
        type: orderType,
        state: orderStateType,
        start: startDates,
        // start: start,
        end: endDates,
        worker: workerData.id,
        shop: merchantData.id,
        user: kefuData.id,
        mode: mode,
        myarea: myCheckbox,
        label: masterlabel,
        trans: trans,
        skip: skip,
        take: take,
      })
      if (res.status === 200) {
        dataSource = dataSource.concat(res.data)
        down = res.data.length < take ? false : true;
        skip = skip + res.data.length;
      } else {
        down = false;
      }
    }
    let title = [
      {
        title: '订单信息',
        key: 'orderInfo',
        dataIndex: 'orderInfo',
      },
      {
        title: '客户姓名',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: '客户电话',
        key: 'phone',
        dataIndex: 'phone',
      },
      {
        title: '客户地址',
        key: 'address',
        dataIndex: 'address',
      },
      {
        title: '下单信息',
        key: 'shopInfo',
        dataIndex: 'shopInfo',
      },
      {
        title: '师傅信息',
        key: 'masterInfo',
        dataIndex: 'masterInfo',
      },
      {
        title: '预约时间',
        key: 'appoint',
        dataIndex: 'appoint',
      },
      {
        title: '预约失败原因',
        key: 'appointFalse',
        dataIndex: 'appointFalse',
      },
      {
        title: '商户费用',
        key: 'shopPrice',
        dataIndex: 'shopPrice',
      },
      {
        title: '师傅费用',
        key: 'masterPrice',
        dataIndex: 'masterPrice',
      },
      {
        title: '完成时间',
        key: 'compete_time',
        dataIndex: 'compete_time',
      },
      {
        title: '最后回访时间',
        key: 'serverLastTime',
        dataIndex: 'serverLastTime',
      },
    ];
    data = dataSource.map(item => {
      let appoints = item.serviceFollows.filter(r => r.content.includes('预约失败'));
      let servers = item.serviceFollows.filter(r => r.ascription === '客服');

      let order2 = {
        orderInfo: `${item.id}\r${item.category}-${item.service}-${item.type}\r [${item.state}]\r [${item.order_way}] ${item.order && item.order > 0 ? '已转化:' + item.order : '未转化'}`,
        name: `${item.name}`,
        phone: `${item.phone}`,
        address: `${item.province}${item.city}${item.district}${item.address}`,
        shopInfo: ` ${item.shopName}-${item.shopRank} \r\n${item.create_time} ${item.operater} ${item.addition ? item.addition : ''}`,
        masterInfo: `${item.workerName ? item.workerName + '-' + item.workerRank : ''} ${item.workerPhone}`,
        appoint: item.appoint_time !== '0001-01-01T00:00:00' ? moment(item.appoint_time).format('YYYY/MM/DD-HH:mm') : '',
        appointFalse: appoints.length > 0 ? appoints[appoints.length - 1].content : '',
        shopPrice: item.price_app + item.add_app,
        masterPrice: item.price_oms + item.add_oms,
        compete_time: item.compete_time !== '0001-01-01T00:00:00' ? moment(item.compete_time).format('YYYY/MM/DD-HH:mm') : '',
        serverLastTime: servers.length > 0 ? moment(servers[servers.length - 1].submit_time).format('YYYY/MM/DD-HH:mm') : '',
      };
      return order2;
    });
    let name = '订单列表-' + moment(new Date()).format(dateFormat);
    correctExcel(title, data, name);
    // jsToExcel2(title, data, name);
    this.setState({
      download: false,
    })
  }
}

// export default Index;
export default connect(({ orderRenter }) => (orderRenter))(Index);